<template>
  <div class="emb-aboutus-wrap">
    <div class="page-title-bar page-title-bar2" style="background-image: url('/static/images/about/aboutBanner.png')">
        <div class="container">
        <v-row align="center" justify="">
            <v-col cols="7" md="5" sm="5">
                <h2 class="mb-4 text-left font-weight-bold">About Us</h2>
                <p class=" font-weight-regular text-left">India's largest Reselling platform with lowest commission rates</p>
            </v-col>
        </v-row>
    </div>
  </div>

    <div class="emb-about-content">
      <div class="about-page section-gap">
        <v-container grid-list-xl>
            <v-row>
                <v-col class="text-center" cols="6" md="3" v-for="(item,key) in this.features" :key="key" >
                    <img :src="item.image" class="img-fluid" width="60%" />
                    <h5>{{item.name}}</h5>
                </v-col>
            </v-row>
          <v-layout row wrap align-center v-if="false">
            <v-flex xs12 sm12 md12 lg6 xl6 pr-5>
              <div class="sec-title">
                  <h2 class="v-layout justify-start align-left">
                    About Us
                  </h2>
              <p class="mb-6">
                Established in 2013, we are based out of Mumbai, the commercial
                capital of India. With the almond cracking, cleaning, sorting,
                grading capacity of over 25000-30000 kgs a day, we are one of
                the largest importers in India of quality almonds from
                California (USA) & Australia.
              </p>
              <p class="mb-6">
                As of today, our share in the Indian almond market is 5 %
                of the total imports. We wish to grow this to 10% in the coming
                3 years. We also deal in other nuts like Pistachios, Walnuts &
                Cashews.
              </p>
              </div>
            </v-flex>
            <v-flex xs12 md12 lg6 xl6 text-lg-left text-center>
              <img
                alt="About us Figure"
                src="/static/images/about-us.png"
                width="700"
                height="700"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <div class="about-info bg-grey">
          <div class="container-fluid">
              <v-layout row wrap align-center mb-md-0 mb-6>
            <v-flex xl6 sm12 xs12 md5 lg5 text-md-left text-center>
              <img
                src="/static/images/about/whatinbox.png"
                alt="mission"
                style="width:100%;"
              />
            </v-flex>
            <v-flex xl6 xs12 md7 lg7 class="pl-12">
              <div class="sec-title">
                  <h2 class="mt-4 mt-md-0 mb-3 v-layout justify-start align-left text-left">What is in the box?</h2>
                  <p>Doesn’t matter. We are too busy thinking far beyond the restraints of the human mind.</p>
                  <p>It is normal human tendency to applaud the orchestra.</p>
                  <p>But do we cheer the director who has his back to the crowd? Ever wondered what he looks like or what goes on at the back of his mind? We don’t. We cannot even see his face.</p>
                  <p>We plan to make the director the star of our show. The large, but inconspicuous B2B Agri-industry. The backstage to the entire food industry in India.</p>
                  <p>That’s where the chaos is.</p>
               </div>
            </v-flex>
          </v-layout>
          </div>
        <v-container grid-list-xl py-0 v-if="false">
          <v-layout row wrap align-center mb-md-0 mb-6>
            <v-flex xl6 sm12 xs12 md6 lg6 text-md-left text-center>
              <img
                src="/static/images/our-mission.png"
                alt="mission"
                width="900"
                height="700"
              />
            </v-flex>
            <v-flex xl6 xs12 md6 lg6 class="pl-12">
              <div class="sec-title">
                  <h2 class="v-layout justify-start align-left">Our Mission</h2>
              <p>
                <ol>
					<li>Providing quality above anything else </li>
					<li> Delivering good health & nutrition to everyone </li>
					<li> Inclusive growth of employees, vendors, channel partners & customers </li>
					<li> Following our motto throughout, which is: Swasth India, Mast India</li>
				</ol>
              </p>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap align-center reverse>
            <v-flex xl6 xs12 md6 lg6>
              <img
                src="/static/images/our-vision.png"
                alt="vission"
                width="900"
                height="700"
              />
            </v-flex>
            <v-flex xl6 xs12 md6 lg6 class="pr-12">
              <div class="sec-title">
                  <h2 class="v-layout justify-start align-left">
              Our Vision</h2>
              <p>
                To bring nutrition, taste & good health from across the globe to the homes of Indian consumers. We strive to provide the finest quality of nuts & seeds to the ever changing, ever growing Indian snack market. Our vision is to be the biggest & the most trusted player in the highly competitive Indian dry fruit segment in the next 5 years.
              </p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <div class="about-page section-gap">
        <v-container grid-list-xl>
            <v-row justify="center" >
                <v-col class="text-center" cols="12" md="4" >
                    <img src="/static/images/about/mission.png" class="img-fluid" width="120px" />
                    <h5>Our mission is to transform the Agri-industry by introducing technology in different stages of the Agri-food value chain</h5>
                </v-col>
                <v-col class="text-center" cols="12" md="4" >
                    <img src="/static/images/about/vision.png" class="img-fluid" width="120px" />
                    <h5>Our vision is to be India’s largest Agri-marketplace by organizing and streamlining this chaos.</h5>
                </v-col>
            </v-row>
        </v-container>
      </div>



      <!-- <emb-testimonial
        secTitle="Our Clients Say"
        :data="TestimonialData"
      ></emb-testimonial> -->
      <div class="team-section text-center section-gap blue-grey lighten-5">
        <v-container grid-list-xl text-xl-center>
          <div class="sec-title">
            <h2>Meet Our Team</h2>
          </div>
          <v-layout row wrap align-center justify-center>
            <v-flex
              xs12
              sm12
              md4
              lg4
              xl4
              text-center
              mb-sm-0
              mb-3
              v-for="(teamMembers, key) in teamListing"
              :key="key"
            >
              <div class="team-card mb-6">
                <img
                  class="br-1"
                  :src="'/static/images/team/'+teamMembers.image"
                  width="200"
                  height="200"
                  :alt="teamMembers.name"
                />
                  <a :href="teamMembers.linkedin" target="_blank" class="linkedin" v-if="teamMembers.linkedin">
                    <i class="fa fa-linkedin"></i>
                  </a>
              </div>
              <div class="team-content">
                <h4 class="mb-0">{{ teamMembers.name }}</h4>
                <p class="mb-0">{{ teamMembers.designation }}</p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Testimonial from "Components/Widgets/Testimonial";
export default {
  computed: {
    ...mapGetters(["rtlLayout"]),
  },
  components: {
    // embTestimonial: Testimonial,
  },
  data() {
    return {
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
      //   TestimonialData,
      /**
       * Slick Options
       **/
      slickOptions: {
        autoplay: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        swipe: true,
        seed: 100,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 390,
          },
        ],
      },
      teamListing: [
        {
          image: "sameer.jpg",
          name: "Sameer Bhanushali",
          designation: "Co-Founder and CEO",
          linkedin:"https://www.linkedin.com/in/bhanushali-sameer/"
        },
        {
          image: "bilal.jpg",
          name: "Bilal Khimji",
          designation: "Co-Founder",
          linkedin:"https://www.linkedin.com/in/bilal-khimji"
        },
        {
          image: "shriwat.jpg",
          name: "Shriwat Kadam",
          designation: "Brand Manager",
          linkedin:"https://www.linkedin.com/in/shriwat-kadam-a16029119/"
        },
        {
          image: "shivani-karia.jpg",
          name: "Shivani Karia",
          designation: "Finance Manager",
          linkedin:null
        },
        {
          image: "akshay.jpeg",
          name: "Akshay Shinde",
          designation: "Business Operations Executive",
          linkedin:"https://www.linkedin.com/in/mrakshayshinde/"
        },
        {
          image: "haresh.jpeg",
          name: "Haresh Patil",
          designation: "Business Associate",
          linkedin:null
        },
        {
          image: "avani.jpeg",
          name: "Avani Pandya",
          designation: "Business Operations Executive",
          linkedin:"https://www.linkedin.com/in/avani-pandya-8b85761a2/"
        },
      ],
      features: [
        {
          image: "/static/images/about/DrivenbyInsight.png",
          name: "Driven by Insight",
        },
        {
          image: "/static/images/about/InspiredbyTechnology.png",
          name: "Inspired by Technology",
        },
        {
          image: "/static/images/about/DesignedwithPurpose.png",
          name: "Designed with purpose",
        },
        {
          image: "/static/images/about/DeliveredtoPerfection.png",
          name: "Delivered to perfection",
        },
      ],
    };
  },
  methods: {
    saveDetails() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.page-title-bar2 {
  padding: 11rem 0;
  background-image: url("/static/images/about/aboutBanner.png");
  background-size: cover;
  background-position: center;
}
.page-title-bar:before {
  display: none;
}
@media (max-width: 600px) {
  .page-title-bar2 {
    background-position: 45% !important;
  }
}
.team-card {
    position: relative;
}
.linkedin{
    background-color: #0077b5;
    color: white;
    position: absolute;
    bottom: -19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    left: 155px;
    padding: 4px;
    border: 3px solid white;
}
</style>
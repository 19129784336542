export default {
    "home": "मुख्य पृष्ठ",
    "homeOne": "मुख्य पृष्ठ 1",
    "homeTwo": "मुख्य पृष्ठ 2",
    "homeThree": "मुख्य पृष्ठ 3",
    "userProfile": "उपयोगकर्ता प्रोफ़ाइल",
    "shop": "दुकान",
    "productDetails": "उत्पाद विवरण",
    "cart": "कार्ट",
    "checkout": "जांच",
    "payment": "भुगतान",
    "fashion": "फैशन",
    "gadgets": "गैजेट",
    "accessories": "सामान",
    "categories": "श्रेणियाँ",
    "pages": "Pages",
    "about": "के बारे में",
    "termAndCondition": "नियम और शर्तें",
    "privacyPolicy": "गोपनीयता नीति",
    "blogDetail": "ब्लॉग",
    "blog": "ब्लॉग",
    "faq": "सामान्य प्रश्न",
    "Page": "Page",
    "404Page": '404 Page',
    "session": "Session",
    "signIn": "साइन इन करें",
    "register": "रजिस्टर करें",
    "forgotPassword": "Forgot Password",
    "thankYou": "धन्यवाद",
    "contactUs": "संपर्क करें",
    "social": "सामाजिक",
    "facebook": "Facebook",
    "twitter": "Twitter",
    "youtube": "Youtube",
    "googlePlus": "Google Plus",
    "instagram": "Instagram",
    "reports": "रिपोर्टों",
    "invoices": "चालान",
    "profile": "प्रोफ़ाइल",
    "products": "उत्पादों",
    "productAdd": "उत्पाद जोड़ें",
    "goToSite": "साइट पर जाएं",
    "adminPanel": "Admin Panel",
    "edit": "Edit",
    "OurStory": 'हमारी कहानी',
    "OurCategories": "हमारी श्रेणियाँ",
    "viewAll": "सभी देखें",
    "FeaturedProducts": "विशेष रुप से प्रदर्शित प्रोडक्टस",
    "OurProcess": "हमारी प्रक्रिया",
    "ShopOnline": "ऑनलाइन खरीदें",
    "total": "संपूर्ण",
    "subtotal": "अनु-जॉड़",
    "tax": "कर",
    "information": "जानकारी",
    "customer": "ग्राहक",
    "MyOrder": "मेरा आदेश",
    "MyBids": "मेरी बोलियां",
    "credit": "मेरी श्रेय",
    "MyWishlist": "मेरी इच्छा सूची",
    "MyAddresses": "मेरे पते",
    "MyAccount": "मेरा खाता",
    "KeyFeatures": "Key Features",
    "valueProposition": "Value Proposition",
    "userJourney": "User Journey",
    "keyClients": "Our Key Clients"



}
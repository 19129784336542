// this  data is used for cart content
export const cart = []

// this data is used for wishlist 
export const wishlist = [{
    "availablity": true,
    "brand": "Liod Marcos",
    "category": "Jewellery",
    "category_type": "accessories",
    "color": "Black",
    "description": "Ipsum dolor sit amet consectetur, adipisicing elit. Distinctio aperiam debitis ipsa veniam eos quas excepturi quae? Recusandae distinctio nihil quia quis, eaque aspernatur perferendis repudiandae adipisci labore, impedit beatae!",
    "discount_price": 78.0,
    "features": ["Slim Fit", "Pure Steel", "Free Shipping and delivery in 7 Days"],
    "id": 118,
    "image": "/static/images/accessroies/a-3-a.jpg",
    "image_gallery": ["/static/images/accessroies/a-3-a.jpg", "/static/images/accessroies/a-3-b.jpg", "/static/images/accessroies/a-3-c.jpg", "/static/images/accessroies/a-3-d.jpg"],
    "name": "Steel Chain",
    "popular": false,
    "price": 56.75,
    "product_code": "#EM1217",
    "quantity": 1,
    "rating": 5,
    "status": 0,
    "tags": ["Chain", "Long Chain", "Accessories"],
    "type": "accessories"
}]
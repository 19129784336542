<template>
  <v-layout row wrap>
    <v-flex lg4 v-for="(product, key) in data" :key="key">
      <product-item :data="product" :catid="catid"></product-item>
    </v-flex>
  </v-layout>
</template>

<script>
import ProductItem from "./ProductItem";

export default {
  props: ["data", "catid", "colmd"],
  components: {
    ProductItem,
  },
  data() {
    return {
      col: this.$props.colmd,
    };
  },
  methods: {
    onPageChange() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
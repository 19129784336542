import api from "Api";


export default {
    ordersProductId: '',
    quantityInCart: '',
    orderId: '',

    async fetchProductCart(variationId, userId) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/orders-products/fetch', {
                productsVariationId: variationId,
                userId: userId
            });
            this.ordersProductId = response.ordersProductId;
            this.quantityInCart = response.quantityInCart;
            return response.data;
        } catch (error) {
            return null;
        }
    },

    async doFavourite(productId, userId) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/favourites/like', {
                productId,
                userId
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async submitBid(bidDetails) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/bids', bidDetails);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async addtocart(product) {
        try {

            if (product.ordersProductId) {
                const response = await api.patch(process.env.VUE_APP_BASE_API + '/orders-products/' + product.ordersProductId, {
                    productId: product.productId,
                    productsVariationId: product.productsVariationId,
                    quantity: product.quantity,
                    unitPrice: product.unitPrice,
                    salePrice: product.salePrice,
                    createdBy: product.createdBy,
                    userId: product.userId,
                    isMobile: false
                });

                this.orderId = response.data.orderId;
                return response.data;
            } else {
                const response = await api.post(process.env.VUE_APP_BASE_API + '/orders-products/', {
                    productId: product.productId,
                    productsVariationId: product.productsVariationId,
                    quantity: product.quantity,
                    unitPrice: product.unitPrice,
                    salePrice: product.salePrice,
                    createdBy: product.createdBy,
                    userId: product.userId,
                    isMobile: false

                });
                this.orderId = response.data.orderId;
                return response.data;
            }
        } catch (error) {
            throw error;
        }

    },

    async managequantity(product, qty) {
        try {
            await api.patch(process.env.VUE_APP_BASE_API + '/orders-products/' + product.ordersProductId, {
                productId: product.proId,
                productsVariationId: product.productsVariationId,
                quantity: qty,
                unitPrice: product.price,
                salePrice: product.salePrice,
                createdBy: product.createdBy,
                userId: product.userId,
                isMobile: false
            });
            return true;
        } catch (error) {
            throw error;
        }

    },

    async removeFromCart(cartProductId) {
        try {
            const response = await api.delete(process.env.VUE_APP_BASE_API + '/orders-products/' + cartProductId);

            return response.status;
        } catch (error) {
            throw error;
        }
    },
}
<template>
  <div class="emb-product-detail">
    <template>
      <emb-page-title
        :heading="selectedProduct.name"
        :backgroundicon="this.catImage"
      ></emb-page-title>
      <div class="emb-productDetail-content white">
        <div class="product-detail section-gap">
          <v-container grid-list-xl py-0>
            <v-layout row wrap product-detail-row>
              <v-flex xs12 sm12 md6 lg6 xl6 text-center>
                <v-layout column wrap>
                  <v-flex
                    xs10
                    lg10
                    sm10
                    md10
                    xl10
                    product-detail-img
                    style="overflow: hidden"
                  >
                    <div class="product-detail-thumb">
                      <div class="image-wrapper emb-card">
                        <a href="javascript:void(0)">
                          <img
                            class="detailImg"
                            :src="this.selectedImage"
                            width="auto"
                            height="auto"
                            alt="product detail image"
                          />
                        </a>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs2 sm2 md2 lg2 xl2 product-gallery>
                    <div
                      class="detail-image-gallery d-inline-block mb-6 mx-2"
                      v-for="(
                        productItemImg, key
                      ) in selectedProduct.productsImage"
                      :key="key"
                      @mouseover="
                        togglePreviewImage(productItemImg.image + '-lg')
                      "
                    >
                      <a href="javascript:void(0)">
                        <img
                          :src="productItemImg.image + '-md'"
                          width="71"
                          height="91"
                          alt="product image"
                        />
                      </a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 xl5>
                <h3>{{ selectedProduct.name }}</h3>
                <p
                  class="text-small text-italic"
                  v-if="selectedProduct.suppliedBy"
                >
                  {{ selectedProduct.suppliedBy }}
                </p>
                <h5 class="font-custom d-inline-block">
                  <emb-currency-sign></emb-currency-sign>{{ this.productPrice }}
                </h5>
                <span
                  class="font-custom font-italic grey--text"
                  v-if="selectedProduct && selectedProduct.measurementUnit"
                  >&nbsp;&nbsp;per {{ selectedProduct.measurementUnit.code }}
                  <template v-if="selectedProduct.isfreightAllowed"
                    >(Exclusive of taxes and freight)</template
                  ></span
                >
                <emb-bid-popup ref="BidPopup"></emb-bid-popup>

                <!-- <ul class="product-availablity list-unstyled pl-0 mb-4 mt-4">
                  <li>
                    <span class="font-weight-medium">Product Code</span> :
                    <span class="font-weight-regular">{{
                      selectedProduct.product_code
                    }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium">Tags</span>
                    <span>:</span>
                    <span
                      class="font-weight-regular"
                      v-for="(tag, key) in selectedProduct.tags"
                      :key="key"
                    >
                      {{ tag }}
                    </span>
                  </li>
                </ul> -->

                <p>{{ selectedProduct.descpription }}</p>
                <div class="bullet-points mb-4">
                  <ul class="features pl-13">
                    <li
                      v-for="(Features, key) in selectedProduct.features"
                      :key="key"
                    >
                      {{ Features }}
                    </li>
                  </ul>
                </div>
                <div
                  class="select-group mb-4"
                  v-show="
                    selectedProduct &&
                    selectedProduct.productsVariation &&
                    selectedProduct.productsVariation.filter(
                      (item) => item.enabled
                    ).length > 1
                  "
                >
                  <h6 class="text-bold font-bold">Pack Size</h6>
                  <v-radio-group v-model="radioGroup" @change="pricechange()">
                    <v-layout wrap>
                      <v-flex
                        md3
                        pa-2
                        v-for="(
                          productsVariation, key
                        ) in this.selectedProduct.productsVariation.filter(
                          (item) => item.enabled
                        )"
                        :key="key"
                      >
                        <h4 class="white--text variation-options font-custom">
                          <v-radio
                            v-bind:class="{
                              selected:
                                radioGroup.variationid === productsVariation.id,
                            }"
                            :label="`${productsVariation.name}`"
                            :value="{
                              variationid: productsVariation.id,
                              variationname: productsVariation.name,
                              variationunitprice: productsVariation.unitPrice,
                              variationsaleprice: productsVariation.salePrice,
                              variationcreatedBy: productsVariation.createdBy,
                              manageStock: productsVariation.manageStock,
                              stockStatus: productsVariation.stockStatus,
                              stockQuantity: productsVariation.stockQuantity,
                              allowBackOrder: productsVariation.allowBackOrder,
                            }"
                          ></v-radio>
                        </h4>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </div>
                <div class="mb-6">
                  <a
                    href="javascript:void(0)"
                    :class="{ favourite: isFavorite }"
                    @click="toggleFavouriteProduct(selectedProduct)"
                  >
                    <template v-if="isFavorite">
                      <v-icon color="red">favorite</v-icon> Favourite
                    </template>
                    <template v-else>
                      <v-icon>favorite_border</v-icon> Add To Favourite
                    </template>
                  </a>
                </div>
                <div class="select-group mb-4">
                  <v-layout wrap>
                    <v-flex xs12 sm4 lg4 md4 lg3 xl3 pb-0>
                      <div class="quantity-toggle">
                        <button
                          @click="decrement()"
                          :disabled="productQuantity === multiplier"
                        >
                          &mdash;
                        </button>
                        <input
                          type="number"
                          :value="productQuantity"
                          readonly
                          :min="multiplier"
                        />
                        <button @click="increment()">&#xff0b;</button>
                      </div>
                    </v-flex>
                  </v-layout>
                  <br />
                </div>

                <div class="mb-6 btn-wrap">
                  <v-btn
                    large
                    class="white buyProduct cpx-0"
                    :disabled="isOutOfStock"
                    @click="showReviewPopup"
                    >Bid For Product</v-btn
                  >
                  <v-btn
                    v-if="ifItemExistInCart(selectedProduct)"
                    class="accent cpx-0 buyProduct"
                    large
                    to="/cart"
                  >
                    View Cart
                  </v-btn>

                  <v-btn
                    v-else
                    class="accent d-inline-block px-0 buyProduct"
                    large
                    @click="addProductToCart(selectedProduct)"
                    :disabled="isOutOfStock"
                  >
                    {{ isOutOfStock ? "Out of Stock" : "Add To Cart" }}
                  </v-btn>
                  <template>
                    <emb-whatsapp-order
                      class="mx-2"
                      :href="shareUrl"
                      :productName="selectedProduct.name"
                    ></emb-whatsapp-order>
                  </template>
                </div>
                <div>
                  <h6 class="text-bold font-bold">Description</h6>
                  <p>{{ selectedProduct.shortDescription }}</p>
                </div>
                <div>
                  <span>Share Now</span>
                  <emb-social-share
                    class="mx-2"
                    :href="shareUrl"
                    :productName="selectedProduct.name"
                  ></emb-social-share>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <div class="related-product section-gap bg-grey">
          <v-container grid-list-xl class="py-0">
            <div class="sec-title">
              <h2 class="font-weight-medium">You Might Also Like</h2>
            </div>
            <div class="product-listing">
              <v-layout row wrap mb-4>
                <template>
                  <v-flex
                    xs12
                    sm6
                    md6
                    lg3
                    xl3
                    mb3
                    text-center
                    v-for="(product, index) in products"
                    :key="index"
                  >
                    <div class="emb-card" v-if="index <= 3">
                      <div
                        class="thumb-wrap"
                        v-for="(pr, key) in product.productsImage"
                        :key="key"
                      >
                        <router-link
                          :to="
                            '/product/product-detail/' +
                            product.id +
                            '/' +
                            slugify(product.name)
                          "
                        >
                          <img
                            :src="pr.image + '-md'"
                            alt="related product"
                            width="626"
                            height="800"
                            v-if="pr.isCover"
                          />
                        </router-link>
                        <div class="wishlist-icon"></div>
                        <div class="add-to-cart" v-if="false">
                          <v-btn
                            v-if="ifItemExistInCart(product)"
                            to="/cart"
                            class="primary"
                            small
                            icon
                          >
                            <v-icon medium>edit</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            class="accent"
                            small
                            icon
                            @click="addProductToCart(product)"
                          >
                            <v-icon>shopping_cart</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="emb-card-content px-6 py-4 white">
                        <h5 v-text="product.name" style="min-height: 50px"></h5>
                        <div class="emb-meta-info2 my-1 block">
                          <div
                            class="layout flex-column text-left justify-space-between pa-4"
                          >
                            <div class="block">
                              <h6
                                class="accent--text font-weight-medium font-custom"
                                v-show="
                                  product.productsVariation[0].salePrice != 0.0
                                "
                              >
                                <emb-currency-sign></emb-currency-sign
                                >{{
                                  getMasked(
                                    product.productsVariation[0].salePrice
                                  )
                                }}
                                <span class="grey--text discounted">
                                  <s>
                                    <emb-currency-sign></emb-currency-sign
                                    >{{
                                      getMasked(
                                        product.productsVariation[0].unitPrice
                                      )
                                    }}
                                  </s>
                                </span>
                                <p class="mb-0 discounted grey--text">
                                  Per {{ product.measurementUnit.code }}
                                </p>
                              </h6>
                              <h6
                                class="accent--text font-weight-medium font-custom"
                                v-show="
                                  product.productsVariation[0].salePrice == 0.0
                                "
                              >
                                <emb-currency-sign></emb-currency-sign
                                >{{
                                  getMasked(
                                    product.productsVariation[0].unitPrice
                                  )
                                }}
                                <p class="mb-0 discounted grey--text">
                                  Per {{ product.measurementUnit.code }}
                                </p>
                              </h6>
                            </div>
                            <div class="block">
                              <router-link
                                :to="
                                  '/product/product-detail/' +
                                  product.id +
                                  '/' +
                                  slugify(product.name)
                                "
                              >
                                <v-btn class="accent" block small>
                                  {{
                                    radioGroup && radioGroup.ordersProductId
                                      ? "Update"
                                      : "Add to"
                                  }}
                                  Cart
                                </v-btn>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </div>
          </v-container>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "Api";

import AppConfig from "Constants/AppConfig";

import initiateService from "../services/initiate.service";
import { bus } from "../main";
import cartService from "../services/cart/cart.service";
import userService from "../services/auth/user.service";
// import userService from "../services/auth/user.service";

export default {
  computed: {
    ...mapGetters(["cart"]),
  },
  components: {},
  mounted() {
    this.id = this.$route.params.id;
    if (!(initiateService.isloggedin || userService.loginsuccess)) {
      this.$router.push("/signin?redirect=" + location.pathname);
      return false;
    }
    this.title = this.$route.params.title;
    this.getParametre(this.title, this.id);
  },
  watch: {
    $route(to) {
      this.title = to.params.title;
      this.id = to.params.id;
      this.getParametre(this.title, this.id);
    },
  },
  data() {
    return {
      addwishicon: AppConfig.addwishicon,
      title: "",
      id: "",
      isFavorite: false,
      productPrice: 0,
      selectedImage: "",
      selectedProduct: {},
      products: [],
      radioGroup: "",
      catImage: "",
      multivariation: false,
      productQuantity: 1,
      multiplier: 1,
      isOutOfStock: false,
      shareUrl: "",
    };
  },
  created() {},
  methods: {
    /* for routing matching **/
    async getParametre(param1, param2) {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/products/" + param2
        );
        this.selectedProduct = response.data;
        this.multiplier = this.selectedProduct.multiplier || 1;
        this.catImage = this.selectedProduct.category[0].icon;

        if (this.selectedProduct.productsVariation.length > 1) {
          this.multivariation = true;
        }

        // selecting first
        let temp = this.selectedProduct.productsVariation.filter(
          (item) => item.enabled
        );
        const productsVariation = temp[0];
        this.radioGroup = {
          variationid: productsVariation.id,
          variationname: productsVariation.name,
          variationunitprice: productsVariation.unitPrice,
          variationsaleprice: productsVariation.salePrice,
          variationcreatedBy: initiateService.loggedin.userid,
          manageStock: productsVariation.manageStock,
          stockStatus: productsVariation.stockStatus,
          stockQuantity: productsVariation.stockQuantity,
          allowBackOrder: productsVariation.allowBackOrder,
        };

        this.productPrice =
          +this.radioGroup.variationsaleprice ||
          this.radioGroup.variationunitprice;

        for (let selectImage of this.selectedProduct.productsImage) {
          if (selectImage.isCover) {
            this.selectedImage = selectImage.image + "-lg";
          }
        }
        this.productQuantity = this.productQuantity || this.multiplier;
        this.pricechange();
        this.isAlreadyFavourized();
        this.shareUrl = location.href;
        document.title =
          this.selectedProduct.name + " - " + AppConfig.pageTitle;
        const response2 = await api.post(
          process.env.VUE_APP_BASE_API + "/products/list/",
          {
            limit: 6,
            categoryId: this.selectedProduct.category[0].id,
          }
        );

        this.products = response2.data.product.filter((item) => {
          if (item.id != this.id) {
            return item.productsVariation.filter((itm) => itm.enabled);
          }
        });
        return true;
      } catch (error) {
        return error;
      }
    },

    /* for opening the popup **/
    showReviewPopup() {
      this.$refs.BidPopup.open(
        this.selectedProduct,
        this.radioGroup,
        this.productPrice
      );
    },

    /* for toggling image **/
    togglePreviewImage(image) {
      this.selectedImage = image;
    },

    /* for adding product in car	**/
    async addProductToCart(product) {
      try {
        let tempproduuct = {
          ordersProductId: this.radioGroup.ordersProductId,
          productId: product.id,
          productsVariationId: this.radioGroup.variationid,
          quantity: this.productQuantity,
          unitPrice: this.radioGroup.variationunitprice,
          salePrice: this.radioGroup.variationsaleprice,
          createdBy: initiateService.loggedin.userid,
          userId: initiateService.loggedin.userid,
        };
        const response = await cartService.addtocart(tempproduuct);
        this.radioGroup.ordersProductId = response.id;
        bus.$emit("productadded");

        this.$snotify.success("Product adding to the cart", {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000,
          showProgressBar: false,
        });
      } catch (error) {
        throw error;
      }
    },
    /* check weather the product exist in cart
     * retun the boolean
     */
    ifItemExistInCart(result) {
      let exists = false;
      for (let item of this.cart) {
        if (item.id == result.objectID) {
          exists = true;
        }
      }
      return exists;
    },
    /* to add a product in wishlist */
    async toggleFavouriteProduct(product) {
      try {
        this.isFavorite = await cartService.doFavourite(
          product.id,
          initiateService.loggedin.userid
        );
      } catch (error) {
        throw error;
      }
    },
    /* check weather the product exist in the wishlist
     * Return boolean
     */
    async isAlreadyFavourized() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/favourites?filter=productId||eq||" +
            this.id +
            "&filter=userId||eq||" +
            initiateService.loggedin.userid
        );
        this.isFavorite = response.data.length > 0;
      } catch (error) {
        throw error;
      }
    },

    async pricechange() {
      this.productPrice = this.getMasked(
        +this.radioGroup.variationsaleprice ||
          this.radioGroup.variationunitprice
      );
      this.checkForOutOfStock();
      try {
        // get existing quantity if already added
        const quantityInCart = await cartService.fetchProductCart(
          this.radioGroup.variationid,
          initiateService.loggedin.userid
        );
        // update quantity
        if (quantityInCart) {
          this.productQuantity = quantityInCart.quantityInCart;
          this.radioGroup.ordersProductId = quantityInCart.ordersProductId;
        } else {
          this.productQuantity = this.multiplier;
          this.radioGroup.ordersProductId = null;
        }
      } catch (error) {
        throw error;
      }
    },
    checkForOutOfStock() {
      if (this.radioGroup.manageStock) {
        this.isOutOfStock = +this.radioGroup.stockQuantity <= 0;
        // now check for backorders
        if (this.isOutOfStock && this.radioGroup.allowBackOrder) {
          this.isOutOfStock = false; // allowing back order
        }
      } else {
        this.isOutOfStock = this.radioGroup.stockStatus !== "In Stock";
      }
    },
    getMasked(price = null) {
      if (!(initiateService.isloggedin || userService.loginsuccess)) {
        // check if loggedin
        // Dhiraj
        // split
        const priceSplit = price.split(".");
        // get first digit
        const firstDigit = priceSplit[0].charAt(0);
        let maskedPrice = firstDigit;
        // remove first digit
        priceSplit[0] = priceSplit[0].slice(1);
        if (priceSplit[0].length) {
          maskedPrice += "X".repeat(priceSplit[0].length);
        }
        if (priceSplit[1].length) {
          maskedPrice += "." + "X".repeat(priceSplit[1].length);
        }
        return maskedPrice;
      }
      return price;
    },
    increment() {
      this.productQuantity += this.multiplier;
    },
    decrement() {
      if (this.productQuantity === this.multiplier) {
        return;
      } else {
        this.productQuantity -= this.multiplier;
      }
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.quantity-toggle {
  display: flex;
}
.quantity-toggle input {
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  width: 4.5rem;
  text-align: center;
  padding: 0 0.5rem;
}
.quantity-toggle button {
  border: 2px solid #ddd;
  padding: 0.5rem;
  background: #f5f5f5;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
}
.quantity-toggle button:disabled {
  background: #e5e5e5;
}
</style>
<template>
  <v-dialog
    class="mb-4"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title>Enter Bid Amount</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card class="review-popup white py-4">
      <v-container container-fluid grid-list-xl>
        <v-layout row wrap align-center justify-center text-center>
          <v-flex xs12 sm12 md12 lg6 xl6>
            <h6>
              {{ this.productName }}
              <p class="mb-0 text-italic text-small">
                {{ this.productVariation.variationname }}
              </p>
            </h6>
            <v-form class="example-form" v-model="valid">
              <v-text-field
                type="number"
                class="text-center border text-center"
                @change="validatebid"
                v-on:keyup="validatebid"
                :rules="bidRules.validbidding"
                validate-on-keyup
                placeholder="Bid Amound"
                v-model="productBid"
              ></v-text-field>
              <h5>Select Quantity</h5>

              <div class="quantity-toggle justify-center my-3">
                <v-btn
                  @click.stop="decrement2()"
                  :disabled="productQuantity === multiplier"
                >
                  &mdash;
                </v-btn>
                <input
                  type="number"
                  :value="productQuantity"
                  readonly
                  :min="multiplier"
                />
                <v-btn @click.stop="increment2()">&#xff0b;</v-btn>
              </div>

              <h5>
                Total Bid Amount: <emb-currency-sign></emb-currency-sign
                >{{ formatNumber(productBid * productQuantity) }}
              </h5>
              <v-btn class="accent" v-show="valid" large @click="submitBid"
                >Place Bid</v-btn
              >
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import cartService from "../../services/cart/cart.service";
import initiateService from "../../services/initiate.service";
export default {
  data() {
    return {
      dialog: false,
      bidAllowed: "",
      productPrice: null,
      valid: false,
      productBid: null,
      productName: "",
      product: {},
      productVariation: {},
      productQuantity: 1,
      multiplier: 1,
      bidRules: {
        validbidding: [
          (v) => !!v || "E-mail address is required",
          (v) =>
            v < this.productPrice ||
            "Bid Amount should be less than to product price",
          (v) =>
            v > (this.productPrice / 100) * this.bidAllowed ||
            "Bid Amount too low to proceed",
        ],
      },
    };
  },
  methods: {
    /**
     * This function opens the popup
     **/
    open(product, productVariation, productPrice) {
      this.dialog = true;
      this.product = product;
      //  allowed bid percentage
      this.productName = product.name;
      this.bidAllowed = product.bidAllowed;
      this.productVariation = productVariation;
      this.multiplier = product.multiplier || 1;
      this.productQuantity = this.multiplier || this.productQuantity;
      //  selected product price
      this.productPrice = productPrice;
      this.productBid = this.toFixed(productPrice - 1);
    },
    validatebid() {
      this.productBid = +this.toFixed(this.productBid);
    },
    formatNumber(num) {
      num = num + "" || "";
      // works for integer and floating as well
      var numberAndDecimal = num.split(".");
      var decimals = numberAndDecimal[1] || null;
      numberAndDecimal = numberAndDecimal[0].replace(
        /(\d)(?=(\d\d)+\d$)/g,
        "$1,"
      );
      num = decimals ? numberAndDecimal + "." + decimals : numberAndDecimal;

      return num;
    },
    toFixed(amount) {
      if (amount) {
        if (typeof amount === "string") {
          amount = +amount;
        }
        return amount.toFixed(2);
      }
      return amount;
    },
    /**
     * This function close the popup
     **/
    close() {
      this.dialog = false;
    },
    async submitBid() {
      try {
        if (initiateService.loggedin.userid) {
          let bidDetails = {
            bidPrice: this.productBid,
            totalBidPrice: this.productBid * this.productQuantity,
            productPrice: this.productPrice,
            productsVariationId: this.productVariation.variationid,
            bidQuantity: this.productQuantity,
            productId: this.product.id,
            userId: initiateService.loggedin.userid,
            isMobile: false,
          };
          const response = await cartService.submitBid(bidDetails);

          if (response.enabled) {
            this.$snotify.success("Bid Placed successfully", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 3000,
              showProgressBar: false,
            });
          }
          this.close();
          return response;
        }
      } catch (error) {
        return null;
      }
    },

    increment2() {
      this.productQuantity += this.multiplier;
    },
    decrement2() {
      if (this.productQuantity === this.multiplier) {
        return;
      } else {
        this.productQuantity -= this.multiplier;
      }
    },
  },
};
</script>
<style scoped>
.quantity-toggle {
  display: flex;
}
.quantity-toggle input {
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  width: 4.5rem;
  text-align: center;
  padding: 0 0.5rem;
}
.quantity-toggle button {
  border: 2px solid #ddd;
  padding: 0.5rem;
  background: #f5f5f5;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
}
.quantity-toggle button:disabled {
  background: #e5e5e5;
}
</style>
<template>
  <div class="emb-payment-wrap">
    <!-- Contact Details -->
    <div class="emb-card mb-12 pa-3 white">
      <v-layout row wrap align-center ma-0>
        <v-flex lg12 class="d-flex justify-space-between align-center">
          <h5 class="m-0">Order Details</h5>
        </v-flex>
        <v-flex md8 sm12>
          <v-simple-table>
            <tbody>
              <tr>
                <th>Order Id</th>
                <td>{{ this.orderData.orderReference }}</td>
              </tr>
              <tr v-if="this.orderData.orderTimestamp">
                <th>Order Placed on</th>
                <td>{{ this.orderData.orderTimestamp }}</td>
              </tr>
              <tr>
                <th>Order Status</th>
                <td>
                  <v-chip :color="statusColorMap[orderData.status]" small>{{
                    this.orderData.status
                  }}</v-chip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex>

        <v-flex md4 sm12>
          <v-simple-table>
            <tbody>
              <tr v-if="orderData.lrNumber">
                <th>LR Number</th>
                <td>
                  {{ orderData.lrNumber }}
                </td>
              </tr>
              <tr v-if="orderData.lrReceipt">
                <th>LR Receipt</th>
                <td>
                  <v-btn
                    x-small
                    :href="orderData.lrReceipt"
                    target="_blank"
                    class="accent ma-0"
                  >
                    <v-icon x-small>download</v-icon>
                    Receipt
                  </v-btn>
                </td>
              </tr>
              <tr v-if="orderData.invoiceAttachment">
                <th>Invoice</th>
                <td>
                  <v-btn
                    x-small
                    :href="orderData.invoiceAttachment"
                    target="_blank"
                    class="accent ma-0"
                  >
                    <v-icon x-small>download</v-icon>
                    Download Invoice
                  </v-btn>
                </td>
              </tr>
              <template v-show="orderData.status == 'Delivery Confirmed'">
                <tr v-if="orderData.ordersTicket.length">
                  <th>View Ticket</th>
                  <td>
                    <router-link :to="'/account/view-ticket/' + orderData.id">
                      <v-btn x-small class="accent ma-0"> View Ticket</v-btn>
                    </router-link>
                  </td>
                </tr>
                <tr v-else>
                  <th>Raise Ticket</th>
                  <td>
                    <router-link :to="'/account/raise-ticket/' + orderData.id">
                      <v-btn x-small class="accent ma-0">Raise Ticket </v-btn>
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </div>

    <!-- cart Details -->
    <div class="emb-card mb-12 pa-3 white">
      <v-layout row wrap align-center ma-0>
        <v-flex
          lg4
          v-for="(cart, index) in this.orderData.ordersProduct"
          :key="index"
        >
          <v-card>
            <v-layout row align-center ma-0 mb-3>
              <v-flex lg4>
                <router-link
                  :to="
                    '/product/product-detail/' +
                    cart.product.id +
                    '/' +
                    slugify(cart.product.name)
                  "
                >
                  <img
                    :src="
                      'https://api.ashapuracommodities.com/products-images/img/' +
                      cart.product.id +
                      '/md'
                    "
                    width="80"
                    height="77"
                  />
                </router-link>
              </v-flex>
              <v-flex lg8 cart-product-name pa-0>
                <h6 class="mb-1 word-wrap-break">
                  <router-link
                    :to="
                      '/product/product-detail/' +
                      cart.product.id +
                      '/' +
                      slugify(cart.product.name)
                    "
                  >
                    {{ cart.product.name }}
                  </router-link>
                </h6>
                <p class="mb-0">{{ cart.productsVariation.name }}</p>
                <p class="mb-0">
                  <small
                    ><emb-currency-sign></emb-currency-sign>
                    {{ cart.salePrice }}</small
                  >
                  x
                  {{ cart.quantity }}
                </p>
                <p class="ma-0">
                  <emb-currency-sign></emb-currency-sign>
                  {{ cart.subTotal }}
                </p>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <v-layout>
      <v-flex md4 class="mr-md-5" v-if="orderData.contact">
        <!-- Billing Address -->
        <div class="emb-card mb-12 pa-6 white">
          <h5 class="m-0">Contact Details</h5>
          <p class="ma-0" v-text="orderData.contact.contactPersonName"></p>
          <p class="ma-0" v-text="orderData.contact.mobileNumber"></p>
          <p class="ma-0" v-text="orderData.contact.emailAddress"></p>
        </div>
      </v-flex>

      <v-flex md4 class="mr-md-5">
        <!-- Billing Address -->
        <div class="emb-card mb-12 pa-6 white">
          <h5 class="m-0">Billing Address</h5>
          <p class="ma-0" v-text="orderData.address.name"></p>
          <p class="ma-0" v-text="orderData.address.line1"></p>
          <p class="ma-0" v-text="orderData.address.line2"></p>
          <p
            class="ma-0"
            v-if="orderData.address.city.name"
            v-text="
              this.orderData.address.city.name +
              ', ' +
              this.orderData.address.state.name +
              ', ' +
              this.orderData.address.country.name
            "
          ></p>
          <p class="ma-0" v-text="orderData.address.pincode.pincode"></p>
        </div>
      </v-flex>

      <v-flex md4>
        <!-- Billing Address -->
        <div class="emb-card mb-12 pa-6 white">
          <h5 class="m-0">Shipping Address</h5>
          <p class="ma-0" v-text="orderData.shippingAddress.name"></p>
          <p class="ma-0" v-text="orderData.shippingAddress.line1"></p>
          <p class="ma-0" v-text="orderData.shippingAddress.line2"></p>
          <p
            class="ma-0"
            v-if="orderData.shippingAddress.city.name"
            v-text="
              this.orderData.shippingAddress.city.name +
              ', ' +
              this.orderData.shippingAddress.state.name +
              ', ' +
              this.orderData.shippingAddress.country.name
            "
          ></p>
          <p
            class="ma-0"
            v-text="orderData.shippingAddress.pincode.pincode"
          ></p>
        </div>
      </v-flex>
    </v-layout>
    <h5 class="m-0">Billing Details</h5>
    <v-simple-table>
      <tbody>
        <tr>
          <td>Order Total</td>
          <td>
            <emb-currency-sign></emb-currency-sign>
            {{ formatNumber(orderData.total) }}
          </td>
        </tr>

        <tr v-if="orderData.couponId" class="red--text">
          <td>Discount</td>
          <td>
            -&nbsp;<emb-currency-sign></emb-currency-sign>
            {{ formatNumber(orderData.discountAmount) }}
          </td>
        </tr>
        <tr v-if="orderData && orderData.deliveryOption">
          <td>Delivery Option</td>
          <td>
            {{ this.orderData.deliveryOption }}
            <p
              class="text-left text-small text-italic text-wrap"
              v-if="
                orderData &&
                ['VRL', 'nominatedTransport'].indexOf(
                  orderData.deliveryOption
                ) > -1
              "
            >
              ({{
                orderData.deliveryOption === "VRL"
                  ? orderData.vrlDeliveryLocation.name
                  : orderData.nominatedTransportDetails
              }})
            </p>
          </td>
        </tr>

        <tr v-if="orderData && orderData.deliveryOption">
          <td>Freight Charges</td>
          <td>
            <emb-currency-sign></emb-currency-sign>
            {{ formatNumber(this.orderData.freightCharges) }}
          </td>
        </tr>
        <tr>
          <td>Total Tax</td>
          <td>
            <emb-currency-sign></emb-currency-sign>
            {{ formatNumber(orderData.taxedAmount) }}
          </td>
        </tr>

        <tr>
          <td>
            <h5 class="ma-0">Grand Total</h5>
          </td>
          <td>
            <h5>
              <emb-currency-sign></emb-currency-sign>
              {{ formatNumber(orderData.grandTotal) }}
            </h5>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import api from "Api";

import moment from "moment";
export default {
  data() {
    return {
      orderData: {},
      oderId: "",
      statusColorMap: {
        "Order Placed": "primary",
        "Partial Payment": "warning",
        "Order Delivered": "success",
        "Order Cancelled": "error",
        "Order Dispatched": "info",
        "Delivery Confirmed": "success",
        "Not Delivered": "error",
      },
    };
  },
  mounted() {
    this.fetchdetails();
    this.oderId = this.$route.query.id;
    this.fetchdetails(this.oderId);
  },
  methods: {
    async fetchdetails(oderId) {
      try {
        if (oderId) {
          const response = await api.get(
            process.env.VUE_APP_BASE_API +
              "/orders/" +
              oderId +
              "?doTotal=false",
            {}
          );
          this.orderData = response.data;
          if (this.orderData.orderTimestamp) {
            this.orderData.orderTimestamp = moment(
              this.orderData.orderTimestamp
            )
              .utc(true)
              .local()
              .format("LLLL");
          }
        }
      } catch (error) {
        this.cartquntity = 0;
      } finally {
        // bus.$emit('cartQuantityUpdated');
      }
    },

    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    formatNumber(num) {
      num = num + "" || "";
      // works for integer and floating as well
      var numberAndDecimal = num.split(".");
      var decimals = numberAndDecimal[1] || null;
      numberAndDecimal = numberAndDecimal[0].replace(
        /(\d)(?=(\d\d)+\d$)/g,
        "$1,"
      );
      num = decimals ? numberAndDecimal + "." + decimals : numberAndDecimal;

      return num;
    },
  },
};
</script>

<style>
</style>
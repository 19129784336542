<template>
  <div class="address-block-wrap emb-card pa-4">
    <h4 class="mb-6">Address Infomation</h4>
    <v-layout row wrap>
      <v-flex
        xs12
        sm6
        md4
        lg4
        xl4
        v-for="(item, index) in this.address"
        :key="index"
        v-show="item.enabled"
      >
        <v-card class="mx-auto" max-width="344" elevation="2" outlined>
          <v-card-text>
            <h4 class="h3 text--primary" v-text="item.name"></h4>
            <div class="text--primary">
              <p class="ma-0" v-if="item.line1" v-text="item.line1"></p>
              <p class="ma-0" v-if="item.line2" v-text="item.line2"></p>
              <p
                class="ma-0"
                v-if="item.city.name"
                v-text="
                  item.city.name +
                  ', ' +
                  item.state.name +
                  ', ' +
                  item.country.name
                "
              ></p>
              <p
                class="ma-0"
                v-if="item.pincode.pincode"
                v-text="item.pincode.pincode"
              ></p>
            </div>
          </v-card-text>
          <v-card-actions v-if="false">
            <router-link :to="{ name: 'EditAddress', query: { id: item.id } }"
              ><v-btn class="accent mx-0">Edit</v-btn></router-link
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../services/initiate.service";

export default {
  data() {
    return {
      address: [],
      valid: false,
      contactId: 0,
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
    };
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    async getAddress(userid) {
      if (!userid) {
        userid = initiateService.loggedin.userid;
      }
      try {
        await this.getContactId();
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/addresses?filter=contactId||eq||" +
            this.contactId
        );

        for (let key in response.data) {
          // why loop?
          this.address.push(response.data[key]);
        }
      } catch (error) {
        return error;
      }
    },

    async getContactId() {
      try {
        if (this.contactId) {
          return this.contactId;
        }
        // get contact Id
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/contacts?filter=userId||eq||" +
            initiateService.loggedin.userid
        );
        if (response.data && response.data.length > 0) {
          this.contactId = response.data[0].id;
          return this.contactId;
        }
      } catch (error) {
        throw error;
      }
    },
    saveDetails() {
      this.$refs.form.validate();
      if (this.valid == true) {
        this.$store.dispatch("addUserDetails", this.userInfo);
      }
    },
  },
};
</script>
<template>
  <div class="footerV1-wrap section-gap pb-0 overflow-hidden">
    <v-container grid-list-xl py-0 pb-12>
      <v-layout row wrap>
        <v-flex xs6 sm6 md3 lg3 xl3>
          <div class="about-wrap">
            <h6 class="white--text mb-6">Corporate Office</h6>
            <span class="white--text text-h5">{{ description }}</span>
            <p class="white--text">{{ salesoffice }}</p>
          </div>

          <div class="my-3">
            <h6 class="white--text mb-6">Follow Us</h6>
            <emb-social-follow class=""></emb-social-follow>
          </div>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 xl3>
          <h6 class="white--text mb-4">{{ $t("message.information") }}</h6>
          <div class="abt-menu">
            <ul class="list-unstyled pl-0">
              <li v-for="(list, key) in about" :key="key">
                <router-link :to="list.path">{{
                  $t(list.menuItem)
                }}</router-link>
              </li>
            </ul>
          </div>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 xl3>
          <h6 class="white--text mb-4">{{ $t("message.customer") }}</h6>
          <div class="features-menu">
            <ul class="list-unstyled pl-0">
              <li v-for="(list, key) in this.session" :key="key">
                <a :href="list.path">{{ $t(list.menuItem) }}</a>
              </li>
            </ul>
          </div>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 xl3>
          <div class="policy-menu">
            <h6 class="white--text mb-4">{{ $t("message.social") }}</h6>
            <ul class="list-unstyled pl-0">
              <li v-for="(list, key) in socialLinks" :key="key">
                <a :href="list.path" target="_blank"
                  ><v-icon class="white--text">{{ $t(list.menuicon) }}</v-icon>
                  {{ $t(list.menuItem) }}</a
                >
              </li>
            </ul>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <div class="py-2" style="background: #3c9900">
      <div class="footer-bottom">
        <div class="footer-bottom-content px-3">
          <v-row justify="center" align="center">
            <v-col cols="12" class="text-center">
              <small class="white--text"
                >{{ copyrightText }}. Powered by
                <a
                  href="https://innovuratech.com/"
                  target="_blank"
                  class="white--text"
                  style="font-size: inherit"
                  >Innovura Technologies</a
                ></small
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import { bus } from "../../../main";
import userService from "../../../services/auth/user.service";
import initiateService from "../../../services/initiate.service";
export default {
  props: ["title", "description", "salesoffice"],

  mounted() {
    if (initiateService.isloggedin) {
      this.session = [
        {
          menuItem: "message.MyOrder",
          path: "/account/order-history",
        },
        {
          menuItem: "message.MyBids",
          path: "/account/bid-history",
        },
        {
          menuItem: "message.Credit",
          path: "/account/credit",
        },
        {
          menuItem: "message.MyWishlist",
          path: "/account/wishlist",
        },
        {
          menuItem: "message.MyAddresses",
          path: "/account/address",
        },
        {
          menuItem: "message.MyAccount",
          path: "/account/profile",
        },
      ];
    }
  },
  data() {
    return {
      about: [
        {
          menuItem: "message.about",
          path: "/about",
        },
        {
          menuItem: "message.termAndCondition",
          path: "/term-condition",
        },
        {
          menuItem: "message.privacyPolicy",
          path: "/privacy-policy",
        },
        {
          menuItem: "message.contactUs",
          path: "/contact",
        },
      ],
      session: [
        {
          menuItem: "message.signIn",
          path: "https://shop.tradebridge.co.in/login",
        },
        {
          menuItem: "message.register",
          path: "https://shop.tradebridge.co.in/create-account",
        },
        {
          menuItem: "message.forgotPassword",
          path: "https://shop.tradebridge.co.in/recover-password",
        },
      ],
      socialLinks: [
        {
          menuItem: "message.facebook",
          path: "https://www.facebook.com/TradeBridgeB2B",
          menuicon: "fa-facebook",
        },
        {
          menuItem: "message.twitter",
          path: "https://twitter.com/Tradebridge2",
          menuicon: "fa-twitter",
        },
        {
          menuItem: "message.instagram",
          path: "https://www.instagram.com/tradebridge_b2b",
          menuicon: "fa-instagram",
        },
        {
          menuItem: "message.youtube",
          path: "https://www.youtube.com/channel/UCrBs01ultY5EojVNRRKNzzg",
          menuicon: "fa-youtube-play",
        },
      ],
      copyrightText: AppConfig.copyrightText,
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      this.updatestatus();
    });
  },
  methods: {
    updatestatus() {
      if (initiateService.isloggedin || userService.loginsuccess) {
        this.session = [
          {
            menuItem: "message.MyOrder",
            path: "/account/order-history",
          },
          {
            menuItem: "message.MyBids",
            path: "/account/bid-history",
          },
          {
            menuItem: "message.Credit",
            path: "/account/credit",
          },
          {
            menuItem: "message.MyWishlist",
            path: "/account/wishlist",
          },
          {
            menuItem: "message.MyAddresses",
            path: "/account/address",
          },
          {
            menuItem: "message.MyAccount",
            path: "/account/profile",
          },
        ];
      } else {
        this.session = [];
        this.session = [
          {
            menuItem: "message.signIn",
            path: "/signin",
          },
          {
            menuItem: "message.register",
            path: "/signup",
          },
          {
            menuItem: "message.forgotPassword",
            path: "/forgot-password",
          },
        ];
      }
    },
  },
};
</script>

<template>
  <div class="emb-testimonial-wrap white section-gap pb-0 title-gap" style="background: #f8f8f8 !important">
    <div class="container">
      <div class="sec-title text-center">
        <h2 class="mb-0">{{ secTitle }}</h2>
        <p>
          A break from all your worries sure would help a lot and you knew then
          a tale of a fateful trip this tropic part
        </p>
      </div>
      <slick ref="carousel" :options="slickOptions">
        <div v-for="(testimonial, key) in data" :key="key" class="slide-item">
          <v-hover  v-slot="{ hover }">
              
          <v-card class="emb-card white mt-5 mb-10"  :elevation="hover ? 12 : 1">
              <img src="/static/images/revised/OurClientSays.png" class="quote-icon"/>
            <v-row align="center">
              <v-col cols="4">
                <img
                  class="br-100"
                  alt="customer review"
                  src="/static/images/revised/avtar.png"
                  width="74"
                  height="74"
                />
              </v-col>
              <v-col cols="8">
                <h6 class="font-weight-bold font-custom mb-0">
                  {{ testimonial.name }}
                </h6>
                <p class="mb-0">{{ testimonial.position }}</p>
              </v-col>
              <v-col cols="12">
                <p class="font-italic mb-0" @click="readmore =-1">{{ testimonial.content | truncate(readmore, '...') }}</p>
              </v-col>
            </v-row>
          </v-card>
          </v-hover>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Slick from "vue-slick";

export default {
  props: ["data", "secTitle"],
  computed: {
    ...mapGetters(["rtlLayout"]),
  },
  components: { Slick },
  data() {
    return {
        readmore:300,
      slickOptions: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        swipe: true,
        autoplay: true,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 601,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.emb-card {
  position: relative;
}
img.quote-icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    transform: translateY(-40%);
}
.slick-next::before {
        content: "arrow_right_thin_circle_outline ";
    }
    .slick-prev::before {
        content: " arrow_left_thin_circle_outline";
    }
</style>
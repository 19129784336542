export default [
  {
    id: 1,
    thumb:
      "https://images.yourstory.com/cs/5/dc9aa1302d6c11e9aa979329348d4c3e/Untitleddesign-2021-07-08T141925-1625734211299.png?fm=auto&ar=2:1&mode=crop&crop=faces&w=800",
    origanalThumb: "/static/images/blog/1.png",
    subtitle:
      "Amazon of agro commodities: How TradeBridge is digitising the Rs 3 lakh crore dry fruits and spices market",
    desc:
      "B2B agritech startup TradeBridge operates a 24X7 commodity trading marketplace that connects wholesale buyers and sellers. It has customers from Reliance Retail and Star Bazaar to mandis and D2C snack brands.",
    blogDesc: "",
    blogurl:
      "https://yourstory.com/2021/07/amazon-agro-commodities-tradebridge-digitising-dry-fruits-spices/amp",
    post_date: "July 9, 2021",
    authorName: "Sohini Mitter",
  },
  {
    id: 6,
    thumb: "/static/images/blog/6.jpeg",
    origanalThumb: "/static/images/blog/6.jpeg",
    subtitle:
      "TradeBridge - An AI-driven, B2B eMarketplace for Agro-Commodities",
    desc:
      "TradeBridge is a full-stack tech platform that transforms the Agriculture supply chain and empowers Indian farmers",
    blogDesc: "",
    blogurl:
      "https://www.foodtechbiz.com/anufood-india-2022/tradebridge-an-ai-driven-b2b-emarketplace-for-agro-commodities",
    post_date: "Aug 3, 2022",
    authorName: "FoodTechBiz Desk",
  },
  {
    id: 3,
    subtitle: "TradeBridge announced collaboration with Epaylater",
    thumb:
      "https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg",
    desc:
      "The new service addition will now provide customers with instant credit approvals,interest-free credit for 18 days, disbursements of up to 25 lakhs, zero processing fees, and attractive cashback, thereby enhancing the overall payment experience of the customers",
    blogurl:
      "https://economictimes.indiatimes.com/industry/services/retail/tradebridge-announced-collaboration-with-epaylater/articleshow/86227444.cms",
    post_date: "Sep 15, 2021",
    authorName: "Jayashree Bhosale",
  },
  {
    id: 5,
    subtitle: "Career in AgriTech",
    thumb: "https://i.ytimg.com/vi/IoEDVdOrsEU/maxresdefault.jpg",
    desc:
      "Are you planning to excel in the field of AgriTech? Then this video is for you, we have Mr. Sameer Bhanushali, Founder & CEO of TradeBridge as an expert who is answering the basic question to start your career in the field of AgriTech",
    blogurl: "https://www.youtube.com/watch?v=IoEDVdOrsEU",
    post_date: "Aug 11, 2021",
    authorName: "Sameer Bhanushali",
  },
  {
    id: 4,
    subtitle:
      "TradeBridge eyes more agri-products on its platform to expand operations",
    thumb:
      "https://www.thehindubusinessline.com/economy/agri-business/borjmr/article35585873.ece/alternates/FREE_615/IMG05isbsdryfruit21GC8B7ROVjpg",
    desc: "",
    blogurl:
      "https://www.thehindubusinessline.com/economy/agri-business/tradebridge-eyes-more-agri-products-on-its-platform-to-expand-operations/article35585874.ece",
    authorName: "Sameer Bhanushali",
    post_date: "July 28, 2021",
  },
  {
    id: 2,
    thumb:
      "http://static.businessworld.in/article/article_extra_large_image/1478073024_Frbdt4_agritech.jpg",
    origanalThumb: "/static/images/blog/1.png",
    subtitle: "How Agritech Startups Are Revamping Agriculture In India?",
    desc:
      "Indian agriculture is facing formidable challenges in the form of decreasing agricultural land against a rising population, small and fragmented land holdings, poor quality of seeds, lack of mechanisation, lack of analytics and data, low yield per unit crop and a reliance on middlemen.",
    blogDesc: "",
    blogurl:
      "http://bwdisrupt.businessworld.in/article/How-Agritech-Startups-Are-Revamping-Agriculture-In-India-/21-07-2021-397376/",
    post_date: "July 21, 2021",
    authorName: "Sameer Bhanushali",
  },
];

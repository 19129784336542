<template>
  <div>
    <v-container>
      <div class="layout row py-10">
        <v-flex lg3 v-for="(category, key) in categoriesData" :key="key">
          <div
            class="emb-card pa-2 mx-2 home-categories-cards mb-3"
            :style="{ backgroundColor: category.bgcolor }"
            @click="goToCategory(category.id, category.name)"
          >
            <div
              class="feature-section-v2-list flex-column text-center home-categories-section"
            >
              <v-img
                :alt="category.name"
                lazy-src="../../../static/images/nopreview.png"
                :src="category.icon"
                class="img-fluid mx-auto category-icon rounded-circle"
                width="50%"
              />
              <div class="feature-section-v2-content">
                <h4 class="mb-2 font-weight-bold">{{ category.name }}</h4>
                <p class="mb-0">
                  Starting From
                  <strong
                    ><emb-currency-sign></emb-currency-sign>
                    {{ category.amountStartingFrom || 1 }}</strong
                  >
                </p>
                <router-link
                  :to="'' + category.id + '/' + slugify(category.name)"
                >
                  View More
                </router-link>
              </div>
            </div>
          </div>
        </v-flex>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["categoriesData"],
  computed: {
    ...mapGetters(["cart", "wishlist"]),
  },
  mounted() {},
  methods: {
    goToCategory(categoryId, categoryName) {
      this.$router.push(
        "/category/" + categoryId + "/" + this.slugify(categoryName)
      );
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.category-icon {
  width: 100px;
  height: 100px;
}
</style>
<template>
  <div class="">
    <v-card class="pa-10">
      <v-layout row wrap align-center ma-0>
        <v-flex lg12 class="d-flex justify-space-between align-center">
          <h5 class="m-0">Ticket  generated on {{ this.ticketData.createdTimestamp }}</h5>
          <v-chip :color="statusColorMap[ticketData.status]" small>{{
                    this.ticketData.status
                  }}</v-chip>
        </v-flex>
        <v-flex md8 sm12>
          <v-simple-table>
            <tbody>
              <tr>
                <th>Order Id</th>
                <td>{{ this.ticketData.orderId }}</td>                
              </tr>
              <tr>
                  <th>Comment</th>
                  <td>{{ this.ticketData.comments }}</td>
              </tr>
              
              <tr>
                  <th>Attachments</th>
                  <td>
                      <v-btn x-small color="primary" class="ma-1" v-show="ticketData.attachment1" :href="ticketData.attachment1" target="_blank">Attachment 1</v-btn>
                      <v-btn x-small color="primary" class="ma-1" v-show="ticketData.attachment2" :href="ticketData.attachment1" target="_blank">Attachment 1</v-btn>
                      <v-btn x-small color="primary" class="ma-1" v-show="ticketData.attachment3" :href="ticketData.attachment1" target="_blank">Attachment 1</v-btn>
                      </td>
              </tr>

              <tr v-show="ticketData.closureComments">
                  <th>Closure Comment</th>
                  <td>{{ this.ticketData.closureComments }}</td>
              </tr>
              
            </tbody>
          </v-simple-table>
        </v-flex>

        <v-flex md4 sm12>
          <v-simple-table>
            <tbody>
            </tbody>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import api from "Api";

import moment from "moment";

export default {
    data(){
        return{
            ticketData:{},
            statusColorMap: {
                "Open": "primary",
                "Closed": "warning",
            },
        }
    },
    mounted(){
        this.orderId = this.$route.params.orderId;
        this.fetchdetails(this.orderId);
    },
    methods:{
          async fetchdetails(orderId) {
      try {
        if (orderId) {
          const response = await api.get(
            process.env.VUE_APP_BASE_API + "/orders/" + orderId + "?doTotal=false",
            {}
          );
          this.ticketData = response.data.ordersTicket[0];
          if (this.ticketData.createdTimestamp) {
            this.ticketData.createdTimestamp = moment(
              this.ticketData.createdTimestamp
            )
              .utc(true)
              .local()
              .format("LLLL");
          }
          
        }
      } catch (error) {
        this.cartquntity = 0;
      } finally {
        // bus.$emit('cartQuantityUpdated');
      }
    },
    }
};
</script>
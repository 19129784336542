<template>
  <div class="profile-wrapper emb-card pa-4">
    <div class="pt-4">
      <v-row justify="space-between" align="center">
        <v-col cols="12" xs="12" sm="6" md="6">
          <v-card class="mx-auto mb-6" max-width="344" outlined elevation="2">
            <v-img
              class="white--text align-end"
              height="200px"
              src="/static/images/credit-card.png"
            >
              <v-card-title>
                <template v-if="isRegistered">
                  >
                  <div class="black--text">
                    <emb-currency-sign></emb-currency-sign>
                    {{ creditAvailableAmount }}
                  </div>
                  <div class="flex-break"></div>
                  >
                  <div class="grey--text">Total available credit</div>
                </template>
                <template v-else
                  ><div class="grey--text">Get Instant</div>
                  <div class="flex-break"></div>
                  <div class="black--text">Credit</div></template
                >
              </v-card-title>
            </v-img>
          </v-card>
          <div class="text-center" v-if="!isRegistered">
            <v-btn
              color="green"
              elevation="2"
              x-large
              rounded
              @click="openCreditPopup"
              class="white--text"
              >Proceed</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6">
          <v-img src="/static/images/credit-creative.svg" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from "Api";

import { bus } from "../../main";
import initiateService from "../../services/initiate.service";
import userService from "../../services/auth/user.service";

export default {
  data() {
    return {
      isRegistered: false,
      signupUrl: null,
      creditAvailableAmount: 0,
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      if (userService.loggedin.userid || initiateService.loggedin.userid) {
        this.getUserInfo();
      }
    });
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/users/check-credit/" +
            initiateService.loggedin.userid
        );
        this.isRegistered = response.data.isRegistered;
        this.signupUrl = response.data.signupUrl;
        this.creditAvailableAmount =
          initiateService.loggedin.creditAvailableAmount;
        return true;
      } catch (error) {
        return error;
      }
    },
    openCreditPopup() {
      // open URL to avail credit
      window.open(this.signupUrl);
    },
  },
};
</script>
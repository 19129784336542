<template>
 <div class="emb-detailOffer-wrap">
  <div class="offer-list text-center white--text">
   <v-row justify="center">
    <v-col cols="4" md="2" class="text-center ma-2">
     <img class="" src="static/images/revised/pan-india-network.png" />
     <h2>Pan India</h2>
     <h6>Network</h6>
    </v-col>

    <v-col cols="4" md="2" class="text-center ma-2">
     <img class="" src="static/images/revised/TotalRegisteredUsers.png" />
     <h2>{{ endVal }}+</h2>
     <h6>Total Registered Users</h6>
    </v-col>

    <v-col cols="4" md="2" class="text-center ma-2">
     <img class="" src="static/images/revised/MonthlyActiveUsers.png" />
     <h2>{{ activeusr }}+</h2>
     <h6>Monthly Active Users</h6>
    </v-col>
    <v-col cols="4" md="2" class="text-center ma-2">
     <img class="" src="static/images/revised/RepeatCustomers.png" />
     <h2>{{ customer }}%</h2>
     <h6 class="">Repeat Customer</h6>
    </v-col>
    <v-col cols="4" md="2" class="text-center ma-2">
     <img class="" src="static/images/revised/AverageOrderValue.png" />
     <h2>{{ ordervalue }}+</h2>
     <h6 class="">Average Order Value</h6>
    </v-col>
   </v-row>
  </div>
 </div>
</template>
<script>
export default {
 components: {},
 data() {
  return {
   delay: 1000,
   endVal: "70,000",
   activeusr: "9,000",
   customer: 65,
   ordervalue: "17,000",

   options: {
    useEasing: true,
    useGrouping: true,
    separator: ",",
    decimal: ".",
    prefix: "",
    suffix: "",
   },
  };
 },
 methods: {},
};
</script>
<style scoped>
.emb-detailOffer-wrap {
 background: linear-gradient(45deg, #67b30b, #13722d);
}
.emb-detailOffer-wrap .offer-list img {
 width: 40%;
}
.emb-detailOffer-wrap .offer-list h2 {
 font-size: 2rem;
}
.emb-detailOffer-wrap .offer-list h2,
.emb-detailOffer-wrap .offer-list h6 {
 color: #fff;
}
.emb-detailOffer-wrap .offer-list h6 {
 font-size: 1.2rem;
 font-weight: 400;
 font-family: "Lexend", sans-serif;
}
</style>

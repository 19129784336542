<template>
  <div>
    <gmap-map :center="center" :zoom="10" style="width: 100%; height: 400px">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :icon="m.icon"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="toggleInfoWindow(m, index)"
      >
      </gmap-marker>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      center: { lat: 19.076527, lng: 73.0073196 },
      markers: [
        {
          label: "TradeBridge",
          position: { lat: 19.076527, lng: 73.00731960000007 },
          content:
            "<b>Sales Office</b><br/>\r\nF-6, APMC Market I,<br/>\r\nPhase II, Sector 19, Vashi, <br/>\r\nNavi Mumbai - 400 705, <br/>\r\nMaharashtra, INDIA <br/>",
          icon: "http://www.ashapuracommodities.com/wp-content/uploads/2018/07/unnamed-e1531990104649.png",
        },
        // {
        //   label: "Ashapura Corporate Office II",
        //   position: { lat: 19.021663, lng: 73.017806 },
        //   content:
        //     "<b>Ashapura Corporate Office</b>\r\n<p>F- 304, 3rd floor, <br>\r\nTower- 2, Seawoods Grand Central,  <br>\r\nSector 40, Nerul ,  <br>\r\nNavi Mumbai – 400706 ,  <br>\r\nMaharashtra, India</p>",
        //   icon:
        //     "http://www.ashapuracommodities.com/wp-content/uploads/2018/07/unnamed-e1531990104649.png",
        // },
        // {
        //   label: "Factoru Unit I",
        //   position: { lat: 19.100673, lng: 73.022583 },
        //   content:
        //     "<b>Factory Unit I</b><br/>\r\nPlot A-655, <br/>\r\nTTC Industrial Area, <br/>\r\nKhairane MIDC Ramnath Solar, <br/>\r\nNavi Mumbai – 400710\r\nMaharashtra, India.",
        //   icon:
        //     "http://www.ashapuracommodities.com/wp-content/uploads/2018/07/unnamed-e1531990104649.png",
        // },
        // {
        //   label: "Factoru Unit II",
        //   position: { lat: 18.8209813, lng: 73.2729581 },
        //   content:
        //     "<b>Factory Unit II</b><br/>\r\nGate No./Survey No. 53/3/B/1 & 53/3/B/2, <br/>\r\nRevenue Village, <br/>\r\nKumbhivali, <br/>\r\nTaluka Khalapur, Dist. Raigad - 410202<br/>\r\nMaharashtra, India.",
        //   icon:
        //     "http://www.ashapuracommodities.com/wp-content/uploads/2018/07/unnamed-e1531990104649.png",
        // },
      ],
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
    };
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.content;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
import api from "Api";
import initiateService from "./initiate.service";

export default {
    async checkTickets() {

    },

    async uploadTicketAttachments(formData) {
        try {

            const response = await api.post(process.env.VUE_APP_BASE_API + '/orders-tickets/upload', {
                userId: initiateService.loggedin.userid,
                multipartFile: formData.get('attachement1')
            }, {
                headers: {
                    'Authorization': initiateService.loggedin.accessToken,
                    'content-type': formData.get('attachement1').type
                }
            });

            return response;

        } catch (error) {

            return error;
        }
    },
    async raiseTicket(ticketData) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/orders-tickets', {
                orderId: ticketData.orderId,
                comments: ticketData.comment,
                attachment1: ticketData.attachment1,
                attachment2: ticketData.attachment2,
                attachment3: ticketData.attachment3,
            });

            return response.data;

        } catch (error) {

            return error;
        }
    },
}
export default {
    featured: [{
            objectID: 1,
            image: "",
            name: "Almond Solitaire",
            price: "25.75",
            rating: 4
        },
        {
            objectID: 2,
            image: "/static/images/men/2-item-a.jpg",
            name: "Pistachio Kernals",
            price: "25.75",
            rating: 2
        },
        {
            objectID: 3,
            image: "/static/images/men/4-item-a.jpg",
            name: "Cashew-Goa",
            price: "55.75",
            rating: 2
        },
        {
            objectID: 4,
            image: "/static/images/men/16-item-a.jpg",
            name: "Walnuts",
            price: "29.75",
            rating: 5
        },
        {
            objectID: 5,
            image: "/static/images/19-item-a.jpg",
            name: "Hoodie",
            price: "29.75",
            rating: 5
        }
    ],
}
<template>
  <div class="feature-product-wrap section-gap title-gap bg-light">
    <div class="container">
      <div class="sec-title">
        <h2>{{ secTitle }}</h2>
      </div>
      <div class="tab-content">
        <slick ref="carousel" :options="slickOptions" v-if="data.length">
          <div v-for="(products, key) in data" :key="key" class="tab-item">
            <div class="emb-card">
              <div class="thumb-wrap">
                <router-link
                  :to="'/product/product-detail/' + products.id+'/'+ slugify(products.name)"
                  v-for="(img, key) in products.productsImage"
                  :key="key"
                >
                  <v-img
                   v-if="img.isCover"
                :alt="products.name"
                lazy-src="../../../static/images/nopreview.png"
                :src="img.image + '-md'"
                class="img-fluid mx-auto category-icon rounded-circle"
              />
                </router-link>
                <div class="wishlist-icon"></div>
                <div class="add-to-cart" v-if="false">
                  <v-btn
                    v-if="ifItemExistInCart(products, cart)"
                    to="/cart"
                    class="accent"
                    icon
                    absolute
                    bottom
                  >
                    <v-icon>remove_red_eye</v-icon>
                  </v-btn>
                  <v-btn v-else class="accent" icon>
                    <v-icon>shopping_cart</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="emb-card-content pa-4 text-left">
                <h5 class="font-weight-medium text-left product-list-title">
                  {{ products.name | truncate(60, "...") }}
                </h5>
                <div class="emb-meta-info2 my-1 block">
                  <div
                    class="block"
                    v-for="(
                      productsVariation, key
                    ) in products.productsVariation.filter(
                      (productsVariation) => productsVariation.enabled
                    )"
                    :key="key"
                  >
                    <template v-show="productsVariation.enabled">
                      <h6
                        class="mb-0 mt-2 font-custom font-weight-bold"
                        v-if="productsVariation.salePrice != 0.0"
                      >
                        <emb-currency-sign></emb-currency-sign
                        >{{ getMasked(productsVariation.salePrice) }}
                        <span class="grey--text discounted">
                          <s
                            ><emb-currency-sign></emb-currency-sign
                            >{{ getMasked(productsVariation.unitPrice) }}</s
                          ></span
                        >
                        <span class="grey--text text-italic">
                          per {{ products.measurementUnit.code }}
                        </span>
                      </h6>
                      <h6 class="mb-0 mt-2 font-custom font-weight-bold" v-else>
                        <emb-currency-sign></emb-currency-sign
                        >{{ getMasked(productsVariation.unitPrice) }}
                        <span class="grey--text text-italic">
                          per {{ products.measurementUnit.code }}
                        </span>
                      </h6>
                    </template>
                    <br />
                  </div>
                  <div class="block">
                    <router-link :to="'/product/product-detail/' + products.id+'/'+ slugify(products.name)">
                      <v-btn class="accent" block small>
                        {{ isLoggedIn ? "Add to Cart" : "Login to view rate" }}
                      </v-btn>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import { mapGetters } from "vuex";

import initiateService from "../../services/initiate.service";
import userService from "../../services/auth/user.service";
import AppConfig from "Constants/AppConfig";
import { bus } from "../../main";

export default {
  props: ["data", "secTitle"],
  computed: {
    ...mapGetters(["rtlLayout", "cart", "wishlist", "products"]),
  },
  components: {
    Slick,
  },
  created() {
    bus.$on("isLoggedin", () => {
      this.isLoggedIn = initiateService.isloggedin || userService.loginsuccess;
    });
  },
  mounted() {
    this.isLoggedIn = initiateService.isloggedin || userService.loginsuccess;
  },
  data() {
    return {
      addwishicon: AppConfig.addwishicon,
      selectedTab: 0,
      activeTab: null,
      isLoggedIn: false,
      slickOptions: {
        autoplay: true,
        slidesToShow: 4,
        infinite: true,
        arrows: true,
        dots: true,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * method for to change item
     */
    onTabChange(key) {
      this.selectedTab = key;
    },
    /**
     * method for checking if item exists in cart
     */
    ifItemExistInCart(result) {
      let exists = false;
      for (let item of this.cart) {
        if (item.id == result.objectID) {
          exists = true;
        }
      }
      return exists;
    },
    getMasked(price = null) {
      // check if loggedin
      if (!this.isLoggedIn) {
        // split
        const priceSplit = price.split(".");
        // get first digit
        const firstDigit = priceSplit[0].charAt(0);
        let maskedPrice = firstDigit;
        // remove first digit
        priceSplit[0] = priceSplit[0].slice(1);
        if (priceSplit[0].length) {
          maskedPrice += "X".repeat(priceSplit[0].length);
        }
        if (priceSplit[1].length) {
          maskedPrice += "." + "X".repeat(priceSplit[1].length);
        }
        return maskedPrice;
      }
      return price;
    },
    slugify(str){
         str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
    }
  },
};
</script>

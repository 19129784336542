<template>
  <div>
    <div class="main">
      <!-- Main Banner -->
      <emb-home-main-banner-v3
        :data="homeBannerV3Data"
        :data2="featureV2categoryData"
      ></emb-home-main-banner-v3>

      <!-- Key features -->
      <div class="section-gap title-gap">
        <key-features :secTitle="$t('message.KeyFeatures')"></key-features>
      </div>

      <!-- value Proposition -->
      <div class="section-gap title-gap" style="background: #f8f8f8">
        <v-container grid-list-xl>
          <div class="sec-title mb-0">
            <h2>{{ $t("message.valueProposition") }}</h2>
          </div>
          <v-row align="center" justify="center" class="my-3">
            <v-col cols="12">
              <img
                src="/static/images/revised/value-proposition.png"
                alt="Value Proposition"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Our Approach -->
      <div class="section-gap">
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <v-flex xl6 xs12 md5 lg5 py-0>
              <img
                src="/static/images/revised/our-approach.png"
                alt="vission"
              />
            </v-flex>
            <v-flex xl6 xs12 md7 lg7 class="pr-md-12">
              <div class="sec-title mb-4">
                <h2 class="text-left">{{ $t("message.OurApproach") }}</h2>
              </div>
              <p class="font-custom">
                TradeBridge is an AI driven, B2B e-marketplace for
                Agro-Commodities providing farmers, processors & importers
                seamless access to millions of retailers & wholesalers across
                India. TradeBridge does not buy or sell crops and is not a
                broker. Instead, we offer you the ability to effortlessly market
                your commodity via our platform.
              </p>
              <p class="font-custom">
                We have built a complete digital platform for Buyers and
                Vendors; a trade marketplace to buy & sell, without having to
                worry about anything but "Price". We take care of logistics,
                quality inspections, secure payments, returns, grievance
                redressals & extension of credit. We are introducing technology
                to improve food traceability and sustainability, and insurance
                coverage for all the major risks in the agri-business.
              </p>
            </v-flex>

            <v-flex xl6 xs12 md5 lg5 class="pr-md-12">
              <div
                class="d-flex mb-2 justify-start align-start"
                v-for="(usp, key) in usps"
                :key="key"
              >
                <img
                  src="static/images/revised/leaf.png"
                  class="img-fluid"
                  width="19px"
                />
                <h6 class="d-inline-block ml-2 mb-0">{{ usp }}</h6>
              </div>
            </v-flex>

            <v-flex xl6 xs12 md7 lg7 py-0>
              <v-card>
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/RyV_SaQB3nY?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <embCounter></embCounter>
      <!-- User Journey -->
      <div class="section-gap title-gap">
        <v-container grid-list-xl>
          <div class="sec-title text-center">
            <h2 class="mb-0">{{ $t("message.userJourney") }}</h2>
            <p>
              TradeBridge facilitates your Business Purchases in 2C(two clicks)
            </p>
          </div>
          <v-row justify="center" align="center" class="journey-row">
            <v-col md="4" class="journey-columns">
              <img src="static/images/revised/Choose.png" class="img-fluid" />
              <h4>Choose</h4>
              <p>
                Choose the Commodity and Specifications<br />
                of the product you wish to buy
              </p>
            </v-col>

            <v-col md="4" class="journey-columns journey-columns-middle">
              <img src="static/images/revised/Pay.png" class="img-fluid" />
              <h4>Pay</h4>
              <p>for your purchase via easy and <br />secure payment options</p>
            </v-col>
            <v-col md="4" class="journey-columns">
              <img src="static/images/revised/Choose.png" class="img-fluid" />
              <h4>Sit back & relax</h4>
              <p>While we do all the hard work</p>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- User Journey -->
      <div class="section-gap title-gap" style="background: #f8f8f8 !important">
        <v-container grid-list-xl>
          <div class="sec-title text-center">
            <h2 class="mb-0">{{ $t("message.keyClients") }}</h2>
          </div>
          <v-row justify="center" class="">
            <v-col
              cols="4"
              md="2"
              class="clients-list"
              v-for="(i, key) in 24"
              :key="key"
            >
              <v-card class="pa-2">
                <img
                  :src="'static/images/clients/logo' + i + '.png'"
                  class="img-fluid client-image"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- <emb-testimonial
        secTitle="Our Clients Say"
        :data="TestimonialData"
      ></emb-testimonial> -->

      <v-lazy transition="fade-transition">
        <embAppDownload secTitle="Download our app"></embAppDownload>
      </v-lazy>

      <feature-v2
        v-if="false"
        :secTitle="$t('message.OurCategories')"
        :data="featureV2categoryData"
      ></feature-v2>

      <emb-feature-product
        v-if="false"
        :secTitle="$t('message.FeaturedProducts')"
        :data="productData"
        class="grey lighten-4"
      ></emb-feature-product>

      <v-lazy transition="fade-transition" min-height="100px" v-if="false">
        <parallexSection secTitle="Our Process"></parallexSection>
      </v-lazy>
    </div>
  </div>
</template>
<script>
// layout components
import HomeMainBannerV3 from "Components/Layouts/Banner/HomeMainBannerV3";

// widgets
import FeatureV2 from "Components/Widgets/FeatureV2";
import FeatureProduct from "Components/Widgets/FeatureProduct";

import parallexSection from "Components/Widgets/parallexSection";
import Counter from "Components/Widgets/Counter";
import AppDownload from "Components/Widgets/AppDownload";
import KeyFeatures from "Components/Widgets/KeyFeatures";
// import Testimonial from "Components/Widgets/Testimonial";

// data
//import homeBannerV3Data from "Assets/data/homeBannerV3Data";
import featureProductData from "Assets/data/featureProductData";
import latestArrivalData from "Assets/data/latestArrivalData";
import TestimonialData from "Assets/data/TestimonialData";
import api from "Api";
import { bus } from "../main";
import initiateService from "../services/initiate.service";

export default {
  components: {
    embHomeMainBannerV3: HomeMainBannerV3,
    embFeatureProduct: FeatureProduct,
    featureV2: FeatureV2,
    parallexSection: parallexSection,
    embAppDownload: AppDownload,
    embCounter: Counter,
    KeyFeatures: KeyFeatures,
    // embTestimonial: Testimonial,
  },
  data() {
    return {
      homeBannerV3Data: [],
      featureProductData,
      latestArrivalData,
      TestimonialData,
      featureV2categoryData: [],
      productData: [],
      usps: [
        "Unified Marketplace",
        "User Friendly Interface",
        "Unlimited access to Pan India markets",
        "Transparent and Reliable Market information",
        "Integrated and secured payment gateways",
        "Verified buyers and sellers",
        "24x7 Market",
      ],
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      initiateService.syncmycart();
      this.getProductsData();
    });
  },
  mounted() {
    this.getCategoriesData();
    this.getProductsData();
    this.getBannersData();
    // window.addEventListener("resize", this.getBannersData());
  },
  methods: {
    async getCategoriesData() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/categories?featuredInWebsite=true"
        );

        for (let key in response.data.data) {
          this.featureV2categoryData.push(response.data.data[key]);
        }
        return true;
      } catch (error) {
        return error;
      }
    },
    async getProductsData() {
      try {
        this.productData = [];
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/products?filter=featured||eq||true"
        );

        for (let key in response.data) {
          if (response.data[key].featured) {
            // remove disabled productsVariations
            response.data[key].productsVariation = response.data[
              key
            ].productsVariation.filter(
              (productsVariation) => productsVariation.enabled
            );
            this.productData.push(response.data[key]);
          }
        }
        return true;
      } catch (error) {
        return error;
      }
    },
    async getBannersData() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/banners"
        );
        let temp = [];
        for (let key in response.data) {
          if (response.data[key].enabled && window.innerWidth > 1000) {
            temp[key] = {
              image: response.data[key].desktopImage,
              subHeading: response.data[key].name,
              heading: response.data[key].description,
              url: response.data[key].link,
            };
          } else {
            temp[key] = {
              image: response.data[key].mobileImage,
              subHeading: response.data[key].name,
              heading: response.data[key].description,
              url: response.data[key].link,
            };
          }
        }
        this.homeBannerV3Data = temp;
        return true;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.journey-row {
  position: relative;
}

.journey-row .journey-columns {
  text-align: center;
  position: relative;
  z-index: 9;
}
.journey-row .journey-columns img.img-fluid {
  max-width: 200px;
}
.clients-list .pa-2.v-card {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.clients-list .pa-2.v-card img {
  max-height: 67px;
}
</style>

<template>
  <div>
    <div class="product-item-wrap emb-card mb-4">
      <div class="layout row align-center justify-center">
        <v-flex lg3>
          <div class="thumb-warp text-center">
            <div class="wishlist-icon"></div>

            <router-link
              :to="'/product/product-detail/' + data.id+'/'+ slugify(data.name)"
              v-for="(img, key) in data.productsImage"
              :key="key"
            >
              <img
                alt="product"
                :src="img.image + '-md'"
                class="mx-auto"
                v-if="img.isCover"
              />
            </router-link>
            <div class="add-to-cart" v-if="false">
              <v-btn
                v-if="ifItemExistInCart(data, cart)"
                to="/cart"
                class="accent"
                icon
                absolute
                bottom
              >
                <v-icon>remove_red_eye</v-icon>
              </v-btn>
              <v-btn v-else @click="addProductToCart(data)" class="accent" icon>
                <v-icon>shopping_cart</v-icon>
              </v-btn>
            </div>
          </div>
        </v-flex>
        <v-flex lg5>
          <div class="emb-card-content pa-3 text-left">
            <h5 class="font-weight-medium text-left product-list-title">
              {{ data.name | truncate(60, "...") }}
            </h5>
            <p>{{ data.shortDescription }}</p>
            <div class="emb-meta-info2 my-1 block">
              <div class="block">
                <h6
                  class="mb-0 mt-2 font-custom font-weight-bold"
                  v-if="data.productsVariation[0].salePrice != 0.0"
                >
                  <emb-currency-sign></emb-currency-sign
                  >{{ getMasked(data.productsVariation[0].salePrice) }}
                  <span class="grey--text discounted">
                    <s>
                      <emb-currency-sign></emb-currency-sign
                      >{{ getMasked(data.productsVariation[0].unitPrice) }}
                    </s>
                  </span>
                  <span class="grey--text">
                    per {{ data.measurementUnit.code }}</span
                  >
                </h6>
                <h6 class="mb-0 mt-2 font-custom font-weight-bold" v-else>
                  <emb-currency-sign></emb-currency-sign
                  >{{ getMasked(data.productsVariation[0].unitPrice) }}
                  <span class="grey--text">
                    per {{ data.measurementUnit.code }}</span
                  >
                </h6>
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex lg3>
          <div class="block">
            <router-link :to="'/product/product-detail/' + data.id+'/'+ slugify(data.name)">
              <v-btn class="accent" block small
                >{{ isLoggedIn ? "Add to Cart" : "Login to view rate" }}
              </v-btn>
            </router-link>
          </div>
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import initiateService from "../../services/initiate.service";
import userService from "../../services/auth/user.service";
import { bus } from "../../main";


export default {
  props: ["data", "colxs", "colsm", "colmd", "collg", "colxl", "product"],
  computed: {
    ...mapGetters(["cart", "wishlist"]),
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      this.isLoggedIn = initiateService.isloggedin || userService.loginsuccess;
    });
  },
  mounted() {
    this.isLoggedIn = initiateService.isloggedin || userService.loginsuccess;
  },
  methods: {
    /**
     * method for adding item to cart
     */
    addProductToCart(item) {
      this.$snotify.success("Product adding to the cart", {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
        showProgressBar: false,
      });
      setTimeout(() => {
        this.$store.dispatch("addProductToCart", item);
      }, 500);
    },
    /**
     * method for checking if item exists in cart
     */
    ifItemExistInCart(result) {
      let exists = false;
      for (let item of this.cart) {
        if (item.id == result.objectID) {
          exists = true;
        }
      }
      return exists;
    },
    getMasked(price = null) {
      if (!(initiateService.isloggedin || userService.loginsuccess)) {
        // check if loggedin
        // Dhiraj
        // split
        const priceSplit = price.split(".");
        // get first digit
        const firstDigit = priceSplit[0].charAt(0);
        let maskedPrice = firstDigit;
        // remove first digit
        priceSplit[0] = priceSplit[0].slice(1);
        if (priceSplit[0].length) {
          maskedPrice += "X".repeat(priceSplit[0].length);
        }
        if (priceSplit[1].length) {
          maskedPrice += "." + "X".repeat(priceSplit[1].length);
        }
        return maskedPrice;
      }
      return price;
    },
    slugify(str){
         str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
    }
  },
};
</script>
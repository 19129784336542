<template>
  <div class="account-wrap">
    <div class="inner-container">
      <div class="bg-white final-receipt-page section-gap">
        <div class="account-info">
          <div class="container grid-list-xl">
            <v-layout row wrap fill-height profile-list>
              <v-flex xs12 sm12 md4 lg3 xl2 user-nav-list px-0>
                <div class="emb-card account-block fill-height py-0">
                  <template v-for="(option, key, index) in settings">
                    <v-list
                      v-for="cateogary in settings[key]"
                      :key="cateogary.name"
                      class="py-0 text-center"
                    >
                      <v-list-item
                        class="py-2 text-left"
                        @click="onTabChange(index)"
                        :to="cateogary.path"
                      >
                        <v-list-item-action class="ma-0" active-class="accent">
                          <v-icon>{{ cateogary.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="py-0 text-left">
                          <v-list-item-title>
                            <span>{{ cateogary.title }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </div>
              </v-flex>
              <v-flex xs12 sm12 md8 lg9 xl9 user-content-wrapper>
                <div v-for="(option, key, index) in settings" :key="key">
                  <router-view v-if="index == selectedTab"></router-view>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import initiateService from "../../services/initiate.service";

export default {
  data() {
    return {
      settings: {
        orderHistory: [
          {
            title: "My Orders",
            icon: "shopping_bag",
            path: "/account/order-history",
            id: "orderHistory",
          },
        ],
        bidHistory: [
          {
            title: "My Bids",
            icon: "gavel",
            path: "/account/bid-history",
            id: "bidHistory",
          },
        ],
        credit: [
          {
            title: "My Credit",
            icon: "account_balance_wallet",
            path: "/account/credit",
            id: "credit",
          },
        ],
        wishlist: [
          {
            title: "My Wishlist",
            icon: "favorite",
            path: "/account/wishlist",
            id: "wishlist",
          },
        ],
        address: [
          {
            title: "Addresses",
            icon: "location_on",
            path: "/account/address",
            id: "addresses",
          },
        ],
        profile: [
          {
            title: "Profile",
            icon: "account_circle",
            path: "/account/profile",
            id: "profile",
          },
        ],
      },
      avatar: initiateService.loggedin.avatar + "-md",
      username: initiateService.loggedin.username,
      useremailId: initiateService.loggedin.useremailId,
      selectedTab: 0,
    };
  },
  mounted() {
    initiateService.init();
  },
  methods: {
    onTabChange(key) {
      this.selectedTab = key;
    },
  },
};
</script>

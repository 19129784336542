import en from './en'
import hn from './hn'

export default {
    en: {
        message: en
    },
    hn: {
        message: hn
    },
}
/* eslint-disable */
// Sidebar Routers
export const menus = [
    {
     path: "/",
     name: "message.home",
     icon: "home",
     children: null,
    },
    {
     path: "/impact/",
     name: "message.impact",
     icon: "category",
     children: null,
    },
    {
     path: "/category/",
     href: "https://shop.tradebridge.co.in/webcategories",
     name: "message.categories",
     icon: "category",
     children: null,
    },
    {
     path: "/about",
     name: "message.about",
     icon: "domain",
     children: null,
    },
    {
     path: "/blog",
     name: "message.blog",
     icon: "pages",
     children: null,
    },
    {
     path: "/contact",
     name: "message.contactUs",
     icon: "perm_contact_calendar",
     children: null,
    },
   ];
   
   export const adminPanelMenus = [
    {
     path: "/admin-panel/reports",
     name: "message.reports",
     icon: "poll",
     children: null,
     active: true,
    },
    {
     path: "/admin-panel/invoices",
     name: "message.invoices",
     icon: "recent_actors",
     children: null,
     active: false,
    },
    {
     path: "",
     name: "message.products",
     icon: "shopping_cart",
     active: false,
     children: [
      {
       name: "message.products",
       path: "/admin-panel/products",
      },
      {
       name: "message.productAdd",
       path: "/admin-panel/product-add",
      },
     ],
    },
    {
     path: "/admin-panel/account/profile",
     name: "message.profile",
     icon: "account_circle",
     active: false,
     children: null,
    },
    {
     path: "/",
     name: "message.goToSite",
     icon: "home",
     active: false,
     children: null,
    },
   ];
   
<template>
 <div class="emb-impact-wrap sec-title">
  <div class="impact-title">
   <img class="banner" src="/static/images/impact-banner.png" alt="" />
   <div class="container">
    <v-col class="">
     <div class="">
      <h2 class="mb-2 text-center font-weight-bold">
       "Be the change you wish to see in the world"
      </h2>
      <h3 class="font-weight-regular text-center mb-0">Mahatma Gandhi</h3>
     </div>
     <v-row class="impact-top-2 layout align-center">
      <img src="/static/images/impact-farmers.png" alt="" />
      <p class="">Our farmers are our prime focus. We intend to give each of the 150 million farmers in India the opportunity and ease to conquer their biggest fear.
      </p>
     </v-row>
    </v-col>
   </div>
  </div>

  <div class="emb-impact-content">
   <div class="impact-page section-gap">
    <div class="section-top">
     <div class="section-wrap">
      <p>Our farmers are our backbone and deserve a pedestal</p>
      <h3>We call it TradeBridge</h3>
     </div>
    </div>
    <v-container grid-list-xl class="section-two-container">
     <v-row class="layout wrap align-center">
      <v-col class="layout-left">
       <v-row
        class="text-center align-center py-5"
        v-for="(item, key) in this.impactList"
        :key="key">
        <img
         src="/static/images/circle-tik.png"
         class="img-fluid mr-3"
         width="50px" />
        <h5 class="mb-0 fa-2x">{{ item }}</h5>
       </v-row>
      </v-col>
      <img src="/static/images/impact-education.png" class="right-img" alt="" />
     </v-row>
     <v-col class="associates">
      <img src="/static/images/impact-numbers.png" alt="" />
      <p class="text-center fa-2x mt-16">
       And of-course numbers follow.
       <br />
       We'll probably (most definitely) boast about them too.
      </p>
      <h2 class="font-weight-bold">
       30,000 +
       <br />
       associated farmers
      </h2>
     </v-col>
    </v-container>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Testimonial from "Components/Widgets/Testimonial";
export default {
 computed: {
  ...mapGetters(["rtlLayout"]),
 },
 components: {
  // embTestimonial: Testimonial,
 },
 data() {
  return {
   valid: false,
   emailRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+/.test(v) || "E-mail must be valid",
   ],
   inputRules: {
    basictextRules: [(v) => !!v || "This field should not be empty"],
   },
   //   TestimonialData,
   /**
    * Slick Options
    **/
   slickOptions: {
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    arrows: false,
    dots: false,
    slidesToScroll: 1,
    swipe: true,
    seed: 100,
    rtl: this.rtlLayout,
    responsive: [
     {
      breakpoint: 390,
     },
    ],
   },
   teamListing: [
    {
     image: "sameer.jpg",
     name: "Sameer Bhanushali",
     designation: "Founder and CEO",
     linkedin: "https://www.linkedin.com/in/bhanushali-sameer/",
    },
    {
     image: "bilal.jpg",
     name: "Bilal Khimji",
     designation: "Chief Strategy Officer",
     linkedin: "https://www.linkedin.com/in/bilal-khimji",
    },
    {
     image: "rudra.jpg",
     name: "Rudra Prasad Chandra",
     designation: "Head - Marketing",
     linkedin: "https://www.linkedin.com/in/rudra-prasad-chandra-000523168/",
    },
    {
     image: "shriwat.jpg",
     name: "Shriwat Kadam",
     designation: "Brand Manager",
     linkedin: "https://www.linkedin.com/in/shriwat-kadam-a16029119/",
    },
    {
     image: "vineet-fendarkar.png",
     name: "Vineet Fendarkar",
     designation: "Supply Chain Manager",
     linkedin: null,
    },
    {
     image: "shivani-karia.jpg",
     name: "Shivani Karia",
     designation: "Finance Manager",
     linkedin: null,
    },
   ],
   features: [
    {
     image: "/static/images/about/DrivenbyInsight.png",
     name: "Driven by Insight",
    },
    {
     image: "/static/images/about/InspiredbyTechnology.png",
     name: "Inspired by Technology",
    },
    {
     image: "/static/images/about/DesignedwithPurpose.png",
     name: "Designed with purpose",
    },
    {
     image: "/static/images/about/DeliveredtoPerfection.png",
     name: "Delivered to perfection",
    },
   ],
   impactList: [
    "We do not chase numbers.",
    "We worship collaboration.",
    "We adore empowerment.",
    "We love self-sufficiency.",
   ],
  };
 },
 methods: {
  saveDetails() {
   this.$refs.form.validate();
  },
 },
};
</script>
<style scoped>
.page-title-bar2 {
 padding: 11rem 0;
 background-image: url("/static/images/about/aboutBanner.png");
 background-size: cover;
 background-position: center;
}
.page-title-bar:before {
 display: none;
}
@media (max-width: 600px) {
 .page-title-bar2 {
  background-position: 45% !important;
 }
}
.team-card {
 position: relative;
}
.linkedin {
 background-color: #0077b5;
 color: white;
 position: absolute;
 bottom: -19px;
 width: 40px;
 height: 40px;
 border-radius: 20px;
 left: 155px;
 padding: 4px;
 border: 3px solid white;
}
</style>

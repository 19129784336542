<template>
  <div class="feature-v2-wrap section-gap title-gap overlay-section">
    <v-container grid-list-xl class="text-center">
      <div class="sec-title">
        <h2>{{ secTitle }}</h2>
      </div>
      <slick ref="slider" :options="slickOpt" class="mb-2" v-if="data.length">
        <div
          v-for="(feature, key) in data"
          :key="key"
          @click="goToCategory(feature.id, feature.name)"
        >
          <div
            :class="feature.name.toLowerCase().trim()"
            class="emb-card pa-2 mx-2 home-categories-cards mb-3"
            :style="{ backgroundColor: feature.bgcolor }"
          >
            <div
              class="feature-section-v2-list flex-column text-center home-categories-section"
            >
              <v-img
                :alt="feature.name"
                lazy-src="../../../static/images/nopreview.png"
                :src="feature.icon"
                class="img-fluid mx-auto category-icon rounded-circle"
              />
              <div class="feature-section-v2-content">
                <h4 class="mb-2 font-weight-bold">{{ feature.name }}</h4>
                <p class="mb-0">
                  Starting From
                  <strong class="font-custom"
                    ><emb-currency-sign></emb-currency-sign>
                    {{ feature.amountStartingFrom || 1 }}</strong
                  >
                </p>
                <router-link
                  :to="'category/' + feature.id + '/' + slugify(feature.name)"
                >
                  View More
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </slick>
      <router-link to="/category/">
        <v-btn class="accent" large>{{ $t("message.viewAll") }}</v-btn>
      </router-link>
    </v-container>
  </div>
</template>
<script>
import Slick from "vue-slick";
export default {
  components: {
    Slick,
  },
  props: ["data", "secTitle"],
  data() {
    return {
      slickOpt: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: true,
        swipe: true,
        autoplay: true,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  methods: {
    goToCategory(categoryId, categoryName) {
      this.$router.push(
        "/category/" + categoryId + "/" + this.slugify(categoryName)
      );
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.category-icon {
  width: 100px;
  height: 100px;
}
</style>
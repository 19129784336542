<template>
  <div class="order-history-wrap emb-card pa-4">
    <h4 class="mb-4">Order History</h4>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :loading="loading"
      :server-items-length="pagination.total"
      :items-per-page="limit"
      :options.sync="options"
      :page.sync="page"
      @click:row="openRow"
    >
      <template v-slot:item.status="{ item }">
        <v-chip small :color="statusColorMap[item.status]">{{
          item.status
        }}</v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <router-link
          :to="{ path: 'order-detail', query: { id: item.orderId } }"
        >
          <v-icon class="accent--text">east</v-icon>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../services/initiate.service";
import moment from "moment";

export default {
  data() {
    return {
      headers: [
        { text: "Order Id", value: "orderReference" },
        { text: "Order Date", value: "name" },
        { text: "Total (INR)", value: "price" },
        { text: "Total Products", value: "totalProducts" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      tableData: [],
      loading: true,
      orders: [],
      options: {},
      limit: 10,
      page: 1,
      pagination: {
        count: 0,
        page: 0,
        pageCount: 0,
        total: 0,
      },
      statusColorMap: {
        "Order Placed": "primary",
        "Partial Payment": "warning",
        "Order Delivered": "success",
        "Order Cancelled": "error",
        "Order Dispatched": "info",
        "Delivery Confirmed": "success",
        "Not Delivered": "error",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getOrders();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async getOrders(userid) {
      if (!userid) {
        userid = initiateService.loggedin.userid;
      }
      try {
        this.loading = true;
        this.tableData = [];
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/orders?filter=userId||eq||" +
            userid +
            "&sort=createdTimestamp,DESC&limit=" +
            this.limit +
            "&page=" +
            this.page +
            "&filter=status||ne||in cart"
        );

        for (let item of response.data.data) {
            
          let temp = {
            value: false,
            orderId: item.id,
            orderReference: item.orderReference,
            name: moment(item.createdTimestamp)
              .utc(true)
              .local()
              .format("ddd, Do MMM YYYY"),
            price: item.grandTotal,
            status: item.status,
            totalProducts: item.totalProducts,
          };
          this.tableData.push(temp);
        }

        this.pagination = response.data;
        // remove data
        delete this.pagination.data;
      } catch (error) {
        this.page = -1;
        return error;
      } finally {
        this.loading = false;
      }
    },
    openRow(order) {
      if (order.orderId) {
        this.$router.push("/account/order-detail?id=" + order.orderId);
      }
    },
  },
};
</script>

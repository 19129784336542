<template>
  <div class="profile-wrapper emb-card pa-4">
    <h4>Profile Information</h4>
    <div class="pt-4">
      <v-row justify="space-between" align="center">
        <v-col cols="8">
          <v-simple-table>
            <tbody>
              <tr v-for="(info, key) in profileData" :key="key">
                <th>{{ info.key }}</th>
                <td>{{ info.value }}</td>
              </tr>
            </tbody></v-simple-table
          >
        </v-col>
        <v-col cols="3">
          <v-img :src="avatar" />
        </v-col>
      </v-row>
      <router-link :to="{ name: 'EditProfileInfo', query: { type: 'info' } }">
        <v-btn class="accent mx-0">Edit</v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "Api";

import { bus } from "../../main";
import initiateService from "../../services/initiate.service";
import userService from "../../services/auth/user.service";

export default {
  data() {
    return {
      panNumber: null,
      gstNumber: null,
      fssaiNumber: null,
      avatar: null,
      walletBalance: 0,
      walletUsageAmount: 0,

      profileData: [
        {
          key: "First Name",
          value: initiateService.loggedin.username,
        },
        {
          key: "Email Id",
          value: initiateService.loggedin.useremailId,
        },
        {
          key: "Mobile Number",
          value: initiateService.loggedin.phonenumber,
        },
      ],
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      if (userService.loggedin.userid || initiateService.loggedin.userid) {
        this.getUserInfo();
      }
    });
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/users/" +
            initiateService.loggedin.userid
        );

        this.panNumber = response.data.organization.panNumber;
        this.gstNumber = response.data.organization.gstNumber;
        this.fssaiNumber = response.data.organization.fssaiNumber;
        this.avatar = response.data.avatar + "-md";
        this.walletBalance = response.data.walletBalance;
        this.walletUsageAmount = response.data.walletUsageAmount;

        this.profileData.push({
          key: "Pan Number",
          value: this.panNumber,
        });
        if (this.gstNumber) {
          this.profileData.push({
            key: "GST Number",
            value: this.gstNumber,
          });
        }
        if (this.fssaiNumber) {
          this.profileData.push({
            key: "FSSAI Number",
            value: this.fssaiNumber,
          });
        }

        if (this.walletBalance) {
          this.profileData.push({
            key: "Available Wallet Balance",
            value: this.walletBalance,
          });
        }

        if (this.walletUsageAmount) {
          this.profileData.push({
            key: "Allowed Wallet Usage Amount",
            value: this.walletUsageAmount,
          });
        }

        return true;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
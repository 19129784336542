<template>
  <div class="emb-accesories-wrap">
    <div v-if="this.categories">
      <emb-page-title
        heading="Our Categories"
        class="category-page-title"
        backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
      ></emb-page-title>
      <categories-list :categoriesData="this.categoriesData"></categories-list>
    </div>

    <div v-else>
      <emb-page-title
        :heading="this.categoriesData.name"
        :subHeading="this.categoriesData.description"
        :backgroundicon="this.categoriesData.image"
      >
      </emb-page-title>
      <div class="accesories-content section-gap">
        <v-container grid-list-xl py-0>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              View As
              <v-btn plain @click="viewchange(false)"
                ><v-icon>mdi-grid</v-icon></v-btn
              >
              <v-btn plain @click="viewchange(true)"
                ><v-icon>mdi-format-list-bulleted</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <sidebar-filters></sidebar-filters>
            </v-col>
            <v-col cols="12" sm="12" md="9">
              <div class="" v-show="productData2 == ''">
                <v-card class="pa-5 text-center">
                  <h4 class="m-0">
                    No Products are available in this category
                  </h4>
                </v-card>
              </div>
              <product-items-list
                :data="productData2"
                v-if="this.listview"
              ></product-items-list>
              <product-items :data="productData2" v-else></product-items>

              <div class="text-center my-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.pageCount + 1"
                  @input="getCategory"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItems from "Components/Ecommerce/ProductItems";
import ProductItemsList from "Components/Ecommerce/ProductItemsList";
import SidebarFilters from "Components/Ecommerce/SidebarFilters";

import api from "Api";

import AppConfig from "Constants/AppConfig";
import CategoriesList from "../components/Ecommerce/categoriesList.vue";

import initiateService from "../services/initiate.service";
import userService from "../services/auth/user.service";

export default {
  components: {
    ProductItems,
    ProductItemsList,
    SidebarFilters,
    CategoriesList,
  },
  data() {
    return {
      listview: false,
      categoriesData: {},
      productData2: [],
      allcategories: {},
      categories: true,
      mobileVersion: false,
      pagination: {
        count: 0,
        page: 1,
        pageCount: 1,
        total: 0,
      },
      title: "All categories",
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  mounted() {
    document.title = this.title + " - " + AppConfig.pageTitle;
  },
  methods: {
    async getCategory() {
      try {
        var cid = this.$route.params.id;
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/categories/" + cid
        );
        this.categoriesData = response.data;
        var requstBody = {
          limit: 9,
          categoryId: cid,
          page: this.pagination.page - 1,
        };

        document.title = this.categoriesData.name + " - " + AppConfig.pageTitle;
        const response2 = await api.post(
          process.env.VUE_APP_BASE_API + "/products/list",
          requstBody
        );

        this.productData2 = [];
        for (let key in response2.data.product) {
          if (cid == response2.data.product[key].category[0].id) {
            this.productData2.push(response2.data.product[key]);
          }
        }

        this.pagination = {
          ...this.pagination,
          ...{
            pageCount: response2.data.pageCount,
            total: response2.data.total,
          },
        };
        // scroll to top
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: "smooth",
        });
        return true;
      } catch (error) {
        return error;
      }
    },
    async getallCategory() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/categories/"
        );
        this.categoriesData = response.data.data;
        return true;
      } catch (error) {
        return error;
      }
    },
    viewchange(val) {
      this.listview = val;
    },
    fetchData() {
      if (this.$route.params.id == undefined) {
        this.categories = true;
        this.getallCategory();
      } else {
        this.categories = false;
        this.getCategory();
        this.listview = false;
      }
    },
  },
  getMasked(price = null) {
    if (!(initiateService.isloggedin || userService.loginsuccess)) {
      // check if loggedin
      // Dhiraj
      // split
      const priceSplit = price.split(".");
      // get first digit
      const firstDigit = priceSplit[0].charAt(0);
      let maskedPrice = firstDigit;
      // remove first digit
      priceSplit[0] = priceSplit[0].slice(1);
      if (priceSplit[0].length) {
        maskedPrice += "X".repeat(priceSplit[0].length);
      }
      if (priceSplit[1].length) {
        maskedPrice += "." + "X".repeat(priceSplit[1].length);
      }
      return maskedPrice;
    }
    return price;
  },
  created: function () {
    window.location.href="https://shop.tradebridge.co.in/login";
    this.fetchData();
  },
  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  },
};
</script>
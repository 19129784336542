import api from "Api";

import initiateService from "../initiate.service";
import { bus } from "../../main";
class userAuthService {
    loginsuccess = false;
    loggedin = {
        userid: '',
        avatar: '',
        accessToken: '',
        username: '',
        useremailId: ''

    };
    async login(user, remember) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/users/login', {
                emailAddress: user.username,
                password: user.password
            });

            if (response.data.accessToken) {
                this.doLogin(response.data);
                bus.$emit('isLoggedin');
            }

            if (remember) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return true;

        } catch (error) {
            this.loginsuccess = false;
            return error;
        }

    }

    doLogin(user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.loginsuccess = true;
        this.loggedin.userid = user.id;
        this.loggedin.avatar = user.avatar;
        this.loggedin.accessToken = user.accessToken;
        this.loggedin.username = user.fullName;
        this.loggedin.useremailId = user.emailAddress;
        initiateService.init();
    }

    async verifyemail(email) {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/users?filter=emailAddress||eq||' + email);
            if (Object.keys(response.data).length === 0) {
                return false
            } else {
                return true
            }

        } catch (error) {
            throw error;
        }
    }


    async validReferral(referral) {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/users?filter=referralCode||eq||' + referral);
            if (Object.keys(response.data).length === 0) {
                return false
            } else {
                return true
            }

        } catch (error) {
            throw error;
        }
    }

    async checkMobileDuplicate(mobileNumber) {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/users?filter=mobileNumber||eq||' + mobileNumber);
            if (Object.keys(response.data).length === 0) {
                return false
            } else {
                return true
            }

        } catch (error) {
            throw error;
        }
    }

    async verifyPAN(panNumber) {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/organizations?filter=panNumber||eq||' + panNumber.trim());
            if (Object.keys(response.data).length === 0) {
                return false
            } else {
                return true
            }

        } catch (error) {
            throw error;
        }
    }

    async verifyGST(gstNumber) {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/organizations?filter=gstNumber||eq||' + gstNumber.trim());
            if (Object.keys(response.data).length === 0) {
                return false
            } else {
                return true
            }

        } catch (error) {
            throw error;
        }
    }
    async forgetPassword(mobile) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/users/forgot-password', {
                mobileNumber: mobile
            })
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async verifyForgotPasswordOtp(otp, hash) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/users/verifyForgotPasswordOtp', {
                otp: otp,
                hash: hash,
            })
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async resetPassword(password, hash) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/users/reset-password', {
                password: password,
                hash: hash,
            })
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async register(user) {
        try {
            const response = await api.post(process.env.VUE_APP_BASE_API + '/users/verifyOtp', {
                fullName: user.fullName,
                organizationName: user.organizationName,
                referralCode: user.referralCode || null,
                fssaiNumber: user.fssaiNumber || null,
                gstNumber: user.gstNumber || null,
                panNumber: user.panNumber || null,
                mobileNumber: user.mobileNumber,
                emailAddress: user.emailAddress,
                password: user.password,
                usersRole: 'Customer',
                otp: user.otp,
                hash: user.hash,
                isMobile: false

            });
            const registerUser = response.data;
            if (registerUser.user) {
                this.doLogin(registerUser.user);
                return true;
            } else {
                throw registerUser.message;
            }
        } catch (error) {
            throw error;
        }
    }
}

export default new userAuthService()
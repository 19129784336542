<template>
  <div class="order-history-wrap emb-card pa-4">
    <h4 class="mb-4">Bid History</h4>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :loading="loading"
      :server-items-length="pagination.total"
      :items-per-page="limit"
      :options.sync="options"
      :page.sync="page"
      @click:row="openRow"
    >
      <template v-slot:item.product="{ item }">
        <h6>{{ item.product.name }}</h6>
        <p class="mb-0 text-small text-italic">
          {{ item.productsVariation.name }}
        </p>
      </template>

      <template v-slot:item.quotedPrice="{ item }">
        <template v-if="+item.quotedPrice">
          {{ item.quotedPrice }}
        </template>
        <template v-else> NA </template>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="statusColorMap[item.status]">{{
          item.status
        }}</v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <router-link :to="{ path: 'bid-detail', query: { id: item.id } }">
          <v-icon class="accent--text">east</v-icon>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../services/initiate.service";

export default {
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Product", value: "product" },
        { text: "Bid Price (INR)", value: "bidPrice" },
        { text: "TradeBridge Price (INR)", value: "quotedPrice" },
        { text: "Quantity", value: "bidQuantity" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      tableData: [],
      loading: true,
      orders: [],
      options: {},
      limit: 10,
      page: 1,
      pagination: {
        count: 0,
        page: 0,
        pageCount: 0,
        total: 0,
      },
      statusColorMap: {
        Pending: "blue-grey",
        Quoted: "amber",
        Rejected: "error",
        Expired: "error",
        Agreed: "light-green",
        Ordered: "success",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getOrders();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async getOrders(userid) {
      if (!userid) {
        userid = initiateService.loggedin.userid;
      }
      try {
        this.loading = true;
        this.tableData = [];
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/bids?filter=userId||eq||" +
            userid +
            "&sort=createdTimestamp,DESC&limit=" +
            this.limit +
            "&page=" +
            this.page
        );

        for (let item of response.data.data) {
          this.tableData.push(item);
        }

        this.pagination = response.data;
        // remove data
        delete this.pagination.data;
      } catch (error) {
        this.page = -1;
        return error;
      } finally {
        this.loading = false;
      }
    },
    openRow(bid) {
      if (bid.id) {
        this.$router.push("/account/bid-detail?id=" + bid.id);
      }
    },
  },
};
</script>

<template>
  <div class="emb-cart-wrap emb-card">
    <emb-page-title
      heading="Here's what's in your bag"
      subHeading="Our latest news and learning articles."
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    >
    </emb-page-title>
    <div class="cart-content section-gap">
      <v-container grid-list-xl py-0>
        <div v-if="cart == ''" class="text-center">
          <div class="text-center">
            <div class="mb-6">
              <img
                alt="cart-empty"
                height="128"
                src="static/images/empty-cart.png"
                width="128"
              />
            </div>
            <h4>Your Shopping Bag is empty</h4>
            <router-link class="primary--text" to="/"
              >Go to Shopping</router-link
            >
          </div>
        </div>
        <div v-else class="cart-shop-list">
          <div class="emb-card mb-12 pa-6 white">
            <div v-for="(item, index) in cart" :key="index">
              <v-row class="cart-item-wrap pa-2">
                <v-col
                  cols="3"
                  sm="3"
                  md="2"
                  class="d-inline-flex align-center justify-center"
                >
                  <router-link
                    :to="
                      '/product/product-detail/' +
                      item.proId +
                      '/' +
                      slugify(item.name)
                    "
                  >
                    <img :src="item.proimage" alt="cart-item" width="100" />
                  </router-link>
                </v-col>
                <v-col
                  cols="9"
                  sm="9"
                  md="4"
                  class="d-inline-flex align-center justify-start justify-md-center"
                >
                  <div class="text-md-center">
                    <router-link
                      :to="
                        '/product/product-detail/' +
                        item.proId +
                        '/' +
                        slugify(item.name)
                      "
                    >
                      <h5 class="mb-1">{{ item.proname }}</h5>
                    </router-link>
                    <p class="mb-0">{{ item.name }}</p>
                    <p class="mb-0">
                      <emb-currency-sign></emb-currency-sign>
                      {{ item.price }} per
                      {{ item.measurementUnit }}
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="2"
                  class="d-inline-flex flex-column align-center justify-center"
                >
                  <div class="quantity-toggle">
                    <button
                      v-on:click.stop="decrement(index, item.multiplier)"
                      :disabled="processing || item.quantity < 1 || item.bidId"
                    >
                      &mdash;
                    </button>
                    <input
                      type="text"
                      :value="item.quantity"
                      readonly
                      :min="1"
                      :disabled="item.bidId"
                    />
                    <button
                      v-on:click.stop="increment(index, item.multiplier)"
                      :disabled="processing || item.bidId"
                    >
                      &#xff0b;
                    </button>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="2"
                  class="d-inline-flex align-center justify-center"
                >
                  <h4 class="mb-0 font-custom">
                    <emb-currency-sign></emb-currency-sign>
                    {{ formatNumber(item.quantity * item.price) }}
                  </h4>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  class="res-float-icon d-inline-flex align-center justify-md-center justify-end"
                >
                  <v-btn
                    class="accent--text remove-cart"
                    icon
                    @click="deleteProductFromCart(item.ordersProductId, index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-layout align-center justify-end>
            <vue-snotify></vue-snotify>
            <v-flex xs12 sm12 md5 lg5 xl5 py-0>
              <div class="layout align-center justify-space-between subtotal">
                <p>{{ $t("message.subtotal") }}</p>
                <span class="font-custom">
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(subTotal) }}
                </span>
              </div>
              <div class="layout align-center justify-space-between subtotal">
                <p>{{ $t("message.tax") }}(GST)</p>
                <span class="font-custom">
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(tax) }}
                </span>
              </div>
              <v-divider class="my-3"></v-divider>
              <div
                class="layout align-center justify-space-between subtotal layout-gap"
              >
                <h4>{{ $t("message.total") }}</h4>
                <h4 class="font-custom">
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(getTotalPrice) }}
                </h4>
              </div>
              <div class="layout justify-end subtotal">
                <v-btn large class="accent mr-0" to="/checkout/payment">
                  {{ $t("message.checkout") }}
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <emb-delete-confirmation
            ref="deleteConfirmationDialog"
            message="Are you sure you want to delete this product?"
            @onConfirm="onDeleteProductFromCart"
          >
          </emb-delete-confirmation>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import api from "Api";

import { bus } from "../main";
import userAuthService from "../services/auth/user.service";
import initiateService from "../services/initiate.service";
import cartService from "../services/cart/cart.service";

export default {
  data() {
    return {
      selectDeletedProduct: null,
      total: null,
      items: [],
      processing: false,
      login: false,
      LoginSuccess: false,
      result1: false,
      result2: false,
      headers: [
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "image",
        },
        { text: "Product Name", value: "name" },
        { text: "Product Quantity", value: "quantity" },
        { text: "Product Total", value: "total" },
        { text: "Remove", value: "remove" },
      ],
      cart: [],
      tax: 0,
      shipping: 0,
      subTotal: 0,
      mobileView: false,
    };
  },
  components: {},

  created() {
    bus.$on("isLoggedin", () => {
      this.LoginSuccess = userAuthService.loginsuccess;
      this.getcart();
    });
  },
  mounted() {
    this.getcart();
    if (window.innerWidth < 767) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  },
  computed: {
    ...mapGetters([]),

    itemTotal() {
      let productTotal = null;
      if (this.cart.length > 0) {
        for (const item of this.cart) {
          productTotal += item.price * item.quantity;
        }
        return this.toFixed(productTotal);
      } else {
        return this.toFixed(productTotal);
      }
    },
    getTotalPrice() {
      let totalPrice = +this.tax + +this.subTotal;
      return this.toFixed(totalPrice);
    },
  },
  methods: {
    async getcart() {
      let userid =
        initiateService.loggedin.userid || userAuthService.loggedin.userid;
      if (!userid) {
        const user = JSON.parse(localStorage.getItem("user"));
        userid = user.id;
        if (!userid) {
          return false;
        }
      }
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/orders/info/" + userid,
          {}
        );

        this.cart = [];
        let temp = [];

        for (let item of response.data.ordersProduct) {
          let unitPrice = 0;
          let measurementUnit = "";
          let productsVariationName = "";
          if (item.salePrice == "0.00") {
            unitPrice = item.unitPrice;
          } else {
            unitPrice = item.salePrice;
          }
          if (
            item.productsVariation != null &&
            item.productsVariation.name != null
          ) {
            productsVariationName = item.productsVariation.name;
            measurementUnit = item.product.measurementUnit.code;
          }
          temp = {
            proimage:
              process.env.VUE_APP_BASE_API +
              "/products-images/img/" +
              item.product.id +
              "/md",
            ordersProductId: item.id,
            proId: item.product.id,
            proname: item.product.name,
            quantity: item.quantity,
            multiplier: item.product.multiplier || 1,
            price: unitPrice,
            salePrice: item.salePrice,
            productsVariationId: item.productsVariationId,
            taxedAmount: item.taxedAmount,
            subTotal: this.toFixed(item.subTotal),
            name: productsVariationName,
            measurementUnit: measurementUnit,
            createdBy: item.createdBy,
            userId: item.userId,
            bidId: item.bidId,
          };

          this.cart.push(temp);
        }

        this.total = initiateService.total;
        this.tax = initiateService.taxedAmount;
        this.shipping = initiateService.shippingCharges;

        this.calculate();
        return true;
      } catch (error) {
        return error;
      }
    },
    calculate() {
      this.tax = 0;
      this.subTotal = 0;
      for (let key in this.cart) {
        this.tax += this.cart[key].taxedAmount;
        this.subTotal += +this.cart[key].subTotal;
      }
      let totalPrice = this.tax + this.subTotal;

      this.subTotal = this.toFixed(this.subTotal);
      this.tax = this.toFixed(this.tax);
      totalPrice = this.toFixed(totalPrice);

      return totalPrice;
    },
    toFixed(amount) {
      if (amount) {
        if (typeof amount === "string") {
          amount = +amount;
        }
        return amount.toFixed(2);
      }
      return amount;
    },
    async deleteProductFromCart(ordersProductId) {
      await cartService.removeFromCart(ordersProductId);
      this.getcart();
      bus.$emit("productadded");
    },
    onDeleteProductFromCart() {
      this.$snotify.error("Product Removing from cart", {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
      });

      this.getcart();
    },
    async increment(index) {
      this.processing = !this.processing;
      this.result1 = false;

      let temp = this.cart[index].quantity;
      temp += this.cart[index].multiplier;

      this.result1 = await cartService.managequantity(this.cart[index], temp);

      if (this.result1) {
        this.getcart();
      }
      bus.$emit("productadded");
      this.processing = !this.processing;
      return true;
    },
    async decrement(index) {
      this.processing = !this.processing;
      this.result2 = false;
      if (this.cart[index].quantity === 1) {
        return;
      } else {
        let temp = this.cart[index].quantity;
        temp -= this.cart[index].multiplier;
        this.result2 = await cartService.managequantity(this.cart[index], temp);
        if (this.result2) {
          this.getcart();
        }
      }
      bus.$emit("productadded");
      this.processing = !this.processing;
      return true;
    },
    formatNumber(num) {
      num = num + "" || "";
      // works for integer and floating as well
      var numberAndDecimal = num.split(".");
      var decimals = numberAndDecimal[1] || null;
      numberAndDecimal = numberAndDecimal[0].replace(
        /(\d)(?=(\d\d)+\d$)/g,
        "$1,"
      );
      num = decimals ? numberAndDecimal + "." + decimals : numberAndDecimal;

      return num;
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.quantity-toggle {
  display: flex;
}
.quantity-toggle input {
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  width: 4.5rem;
  text-align: center;
  padding: 0 0.5rem;
}
.quantity-toggle button {
  border: 2px solid #ddd;
  padding: 0.5rem;
  background: #f5f5f5;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
}
.quantity-toggle button:disabled {
  background: #e5e5e5;
}
</style>

<template>
  <div class="emb-payment-wrap">
    <emb-page-title
      heading="Payment Information"
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    ></emb-page-title>
    <div class="emb-payment-content section-gap overflow-x-hidden">
      <v-container grid-list-xl py-0>
        <template v-if="this.cart.length > 0">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <billing-address
                @changeStepOneForm="enableStepOneForm"
                addressType="billing"
              ></billing-address>
            </v-expansion-panel>
            <v-expansion-panel
              :disabled="!stepOneFormValid"
              :class="{ contracted: !stepOneFormValid }"
            >
              <billing-address
                @stepTwoFormValid="changePanelValue"
                addressType="shipping"
              ></billing-address>
            </v-expansion-panel>
            <v-expansion-panel
              :disabled="!stepTwoFormValid"
              :class="{ contracted: !stepTwoFormValid }"
            >
              <promo-codes
                @changePanel="promochangePanelValue"
                :orderId="orderId"
                :amount="totalAmount"
              ></promo-codes>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-else>
          <div class="text-center">
            <div class="mb-6">
              <img
                alt="cart-empty"
                height="128"
                src="/static/images/empty-cart.png"
                width="128"
              />
            </div>
            <h4>Your Shopping Bag is empty.</h4>
            <router-link class="primary--text" to="/"
              >Go to Shopping</router-link
            >
          </div>
        </template>
      </v-container>
    </div>

    <v-dialog
      persistent
      class="mb-4"
      transition="dialog-bottom-transition"
      v-model="showFreightDialog"
      max-width="400"
    >
      <v-toolbar dark color="primary">
        <v-toolbar-title>Delivery Option</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card class="review-popup white py-4">
        <v-container container-fluid grid-list-xl>
          <v-layout row wrap align-center justify-center text-center>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-text-field
                v-model="zipCode"
                :rules="inputRules.pincoderule"
                label="Pin Code*"
                required
                validate-on-blur
                @blur="getlocationinfo()"
                pattern="[0-9]+"
                v-on:keyup="getlocationinfo()"
                :maxlength="6"
              ></v-text-field>
              <p v-if="freightCharges">
                Approx. frieght charge of
                <emb-currency-sign></emb-currency-sign>{{ freightCharges }} will
                be applicable
              </p>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-radio-group
                v-model="deliveryOption"
                @change="deliveryOptionSelected"
              >
                <v-flex xs12 sm12 md12 lg12 xl6>
                  <v-layout>
                    <v-flex md4 pa-2>
                      <h4 class="variation-options font-custom">
                        <v-radio
                          label="Self Pickup"
                          value="Self pick up"
                        ></v-radio>
                      </h4>
                    </v-flex>
                    <v-flex md4 pa-2>
                      <h4 class="variation-options font-custom">
                        <v-radio
                          label="Nominated Transport"
                          value="Nominated transport"
                        ></v-radio>
                      </h4>
                    </v-flex>
                    <v-flex md4 pa-2>
                      <h4 class="variation-options font-custom">
                        <v-radio
                          :disabled="!vrlAvailable"
                          label="Warehouse Pickup"
                          value="VRL"
                        ></v-radio>
                      </h4>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 xl6>
                  <br />
                  <v-layout>
                    <v-flex md5 pa-2>
                      <h4 class="variation-options font-custom">
                        <v-radio
                          label="Door to Door delivery"
                          value="Door to door"
                        ></v-radio>
                      </h4>
                    </v-flex>
                    <v-flex md5 pa-2>
                      <h4 class="variation-options font-custom">
                        <v-radio label="Any other" value="Any Other"></v-radio>
                      </h4>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <template v-if="deliveryOption == 'VRL'">
                <v-select
                  v-model="vrlDeliveryLocationId"
                  :items="vrlLocations"
                  label="Select Warehouse"
                  dense
                  @change="showVRLRate"
                ></v-select>
              </template>
              <template v-if="deliveryOption == 'Nominated transport'">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Please enter details"
                      v-model="nominatedTransportDetails"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions class="justify-center">
          <v-btn color="warning" @click="updateDeliveryOptions()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      class="mb-4"
      fullscreen
      transition="dialog-bottom-transition"
      v-model="panInfo"
    >
      <v-toolbar dark color="primary">
        <v-toolbar-title>Mandatory Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card class="white">
        <v-form ref="step2form" class="pa-5" v-model="valid">
          <h5>Complete below information to Proceed</h5>
          <p>(This information will be used in Tax Invoice)</p>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                type="text"
                label="Organization Name"
                placeholder="Enter Organization Name"
                :rules="basictextRules"
                validate-on-blur
                v-model="step2form.organizationName"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="text"
                label="GST Number (optional)"
                placeholder="Enter GST Number (optional)"
                v-model="step2form.gstNumber"
                :rules="gstnumber"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="text"
                label="PAN Number"
                placeholder="Enter PAN Number"
                :rules="pancard"
                validate-on-blur
                v-model="step2form.panNumber"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-4"
                type="text"
                label="FSSAI Number (optional)"
                placeholder="Enter FSSAI Number (optional)"
                v-model="step2form.fssaiNumber"
                :rules="fssai"
                validate-on-blur
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click.stop.prevent="saveDetails('step2form')"
              >
                Confirm <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../services/initiate.service";

import BillingAddress from "./Component/BillingAddress";
import PromoCodes from "./Component/PromoCodes";
import { mapGetters } from "vuex";
import userAuthService from "../../services/auth/user.service";

import { bus } from "../../main";

export default {
  data() {
    return {
      stepOneFormValid: false,
      showFreightDialog: false,
      stepTwoFormValid: false,
      stepThreeFormValid: false,
      expansionPanel: [0],
      valid: false,
      items: [],
      cart: [],
      panInfo: false,
      step2form: {
        organizationName: "",
        fssaiNumber: "",
        gstNumber: "",
        panNumber: "",
      },
      basictextRules: [(v) => !!v || "This field should not be empty"],
      pancard: [
        (v) => !!v || "PAN number is mandatory",
        (v) =>
          /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid",
      ],
      gstnumber: [
        // (v) =>
        //   /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/.test(
        //     v
        //   ) || "GST number must be valid",
      ],
      fssai: [],
      orderId: "",
      pincodeId: null,
      zipCode: "",
      dialog: false,
      freightCharges: "",
      totalAmount: initiateService.grandTotal,
      inputRules: {
        pincoderule: [
          (v) => !!v || "This field should not be empty.",
          (v) => v.length == 6 || "Please enter Valid Pincode",
        ],
      },
      deliveryOption: "Self pick up",
      vrl: false,
      vrlAvailable: false,
      nominatedTransportDetails: "",
      vrlLocations: [],
      vrlDeliveryLocationId: null,
    };
  },
  components: {
    billingAddress: BillingAddress,
    promoCodes: PromoCodes,
  },
  created() {
    bus.$on("isLoggedin", () => {
      this.LoginSuccess = userAuthService.loginsuccess;
      this.getcart();
    });
    bus.$on("updated", () => {
      this.checkPanNumber();
    });
  },
  mounted() {
    this.LoginSuccess =
      initiateService.isloggedin || userAuthService.loginsuccess;
    this.checkPanNumber();
    this.getcart();
  },
  computed: {
    ...mapGetters([]),
    panel: {
      get() {
        return this.expansionPanel;
      },
      set(val) {
        if (val.includes(0)) {
          this.expansionPanel = [0];
          this.stepOneFormValid = false;
        } else if (val.includes(1) && this.expansionPanel != 1) {
          this.expansionPanel = [1];
        } else if (val.includes(2)) {
          this.expansionPanel = [2];
        } else {
          this.expansionPanel = [];
        }
      },
    },
  },
  methods: {
    async getcart() {
      let userid =
        initiateService.loggedin.userid || userAuthService.loggedin.userid;
      if (!userid) return;

      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/orders/info/" + userid,
          {}
        );
        this.orderId = response.data.id;
        if (response.data.deliveryOption) {
          this.deliveryOption = response.data.deliveryOption;
        }
        this.cart = [];
        let temp = [];

        for (let item of response.data.ordersProduct) {
          let unitPrice = 0;
          let measurementUnit = "";
          let productsVariationName = "";
          if (item.salePrice == "0.00") {
            unitPrice = item.unitPrice;
          } else {
            unitPrice = item.salePrice;
          }
          if (
            item.productsVariation != null &&
            item.productsVariation.name != null
          ) {
            productsVariationName = item.productsVariation.name;
            measurementUnit = item.product.measurementUnit.code;
          }

          temp = {
            proimage:
              process.env.VUE_APP_BASE_API +
              "/products-images/img/" +
              item.product.id +
              "/md",
            cartId: item.id,
            proId: item.product.id,
            proname: item.product.name,
            quantity: item.quantity * (item.product.multiplier || 1),
            multiplier: item.product.multiplier || 1,
            price: unitPrice,
            productsVariationId: item.productsVariationId,
            taxedAmount: item.taxedAmount,
            subTotal: item.subTotal,
            name: productsVariationName,
            measurementUnit: measurementUnit,
            isfreightAllowed: item.product.isfreightAllowed,
          };

          this.cart.push(temp);
        }

        this.total = initiateService.total;
        this.tax = initiateService.taxedAmount;
        this.shipping = initiateService.shippingCharges;

        return true;
      } catch (error) {
        return error;
      }
    },
    async enableStepOneForm() {
      this.stepOneFormValid = true;
      this.expansionPanel = [1];
      await this.updateAddressId();
    },
    async updateAddressId() {
      try {
        await api.patch(
          process.env.VUE_APP_BASE_API + "/orders/" + this.orderId,
          {
            addressId: initiateService.loggedin.addressid || null,
            shippingId: initiateService.loggedin.deliveryid || null,
          }
        );
      } catch (error) {
        throw error;
      }
    },
    async changePanelValue() {
      this.zipCode = initiateService.pincode;

      this.stepTwoFormValid = true;
      this.expansionPanel = [2];
      await this.updateAddressId();
    },
    promochangePanelValue() {
      this.stepThreeFormValid = true;

      // work delivery option starts
      let showFreightDialog = initiateService.showDeliveryOptions;
      // since allowed in settings
      if (showFreightDialog) {
        // making it false to check with products
        showFreightDialog = false;
        // check item in cart, if they are of frieght allowed type
        this.cart.some((ordersProduct) => {
          if (ordersProduct.isfreightAllowed) {
            showFreightDialog = true;
            return true;
          }
          return false;
        });
        // if not required
        if (!showFreightDialog) {
          this.deliveryOption = null;
          this.nominatedTransportDetails = "";
          this.vrlDeliveryLocationId = null;
          this.updateDeliveryOptions();
          return;
        }
        this.showFreightDialog = showFreightDialog;
        this.getlocationinfo();
      } else {
        this.$router.push("/checkout/payment/summary");
      }
    },
    getAddress(userid) {
      api
        .get(
          process.env.VUE_APP_BASE_API +
            "/addresses?filter=contactId||eq||" +
            userid
        )
        .then((response) => {
          for (let key in response.data) {
            this.address.push(response.data[key]);
          }
        });
    },
    async getlocationinfo() {
      const pinCode = this.zipCode;
      if (pinCode.length == 6) {
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/pincodes?filter=pincode||eq||" +
            pinCode
        );
        if (response && response.data) {
          this.pincodeId = response.data[0].id;
          if (["Door to door"].indexOf(this.deliveryOption) > -1) {
            this.freightCharges = response.data[0].homeDeliveryCharges;
          } else {
            this.freightCharges = 0;
          }
          // get VRLs
          this.vrlLocations = [];
          if (response.data[0].stateId) {
            const response2 = await api.get(
              process.env.VUE_APP_BASE_API +
                "/vrl-delivery-locations/listByState/" +
                response.data[0].stateId
            );
            this.vrlAvailable = response2.data.length > 0;
            response2.data.forEach((vrl) => {
              this.vrlLocations.push({
                text: vrl.name,
                value: vrl.id,
                warehouseCharges: vrl.warehouseCharges,
              });
            });
          }
        }
      }
    },
    async deliveryOptionSelected() {
      await this.getlocationinfo();
    },
    async updateDeliveryOptions() {
      try {
        // check for warehouse selected
        if (this.deliveryOption == "VRL" && !this.vrlDeliveryLocationId) {
          this.$snotify.error("Kindly select warehouse", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 3000,
          });
          return false;
        }
        // nominated Transport Details
        if (
          this.deliveryOption == "Nominated transport" &&
          this.nominatedTransportDetails.trim() == ""
        ) {
          this.$snotify.error("Kindly enter transport details", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 3000,
          });
          return false;
        }
        if (
          ["Self pick up", "Door to door", "Any other"].indexOf(
            this.deliveryOption
          ) > -1
        ) {
          this.nominatedTransportDetails = "";
          this.vrlDeliveryLocationId = null;
        } else if (this.deliveryOption == "VRL") {
          this.nominatedTransportDetails = "";
        } else if (this.deliveryOption == "Any other") {
          this.vrlDeliveryLocationId = null;
        }

        await api.patch(
          process.env.VUE_APP_BASE_API + "/orders/" + this.orderId,
          {
            pincodeId: this.pincodeId,
            deliveryOption: this.deliveryOption,
            nominatedTransportDetails: this.nominatedTransportDetails || "",
            vrlDeliveryLocation: this.vrlDeliveryLocationId
              ? { id: this.vrlDeliveryLocationId }
              : null,
          }
        );
        this.showFreightDialog = false;
        this.$router.push("/checkout/payment/summary");
      } catch (error) {
        throw error;
      }
    },
    async isPANInvalid() {
      let panNumber = this.step2form.panNumber;

      if (!panNumber) {
        this.$snotify.error("PAN number is required", {
          showProgressBar: false,
        });
        return true;
      }
      try {
        const exist = await userAuthService.verifyPAN(panNumber);
        if (exist) {
          this.$snotify.error("PAN number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$snotify.error(error, {
          showProgressBar: false,
        });
        return true;
      }
    },
    async isGSTInvalid() {
      let gstNumber = this.step2form.gstNumber;

      if (!gstNumber) {
        return false;
      }
      try {
        const exist = await userAuthService.verifyGST(gstNumber);
        if (exist) {
          this.$snotify.error("GST number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async isFSSAIInvalid() {
      let fssaiNumber = this.step2form.fssaiNumber;

      if (!fssaiNumber) {
        return false;
      }
      if (fssaiNumber.length !== 14) {
        this.$snotify.error("FSSAI number is invalid", {
          showProgressBar: false,
        });
        return true;
      }
      return false;
    },
    async saveDetails(formname) {
      if (this.$refs[formname].validate()) {
        if (
          (await this.isPANInvalid()) ||
          (await this.isGSTInvalid()) ||
          (await this.isFSSAIInvalid())
        ) {
          return true;
        }

        try {
          const response = await api.patch(
            process.env.VUE_APP_BASE_API +
              "/users/" +
              initiateService.loggedin.userid,
            {
              organization: {
                name: this.step2form.organizationName,
                fssaiNumber: this.step2form.fssaiNumber,
                gstNumber: this.step2form.gstNumber,
                panNumber: this.step2form.panNumber,
              },
            }
          );
          let updated = response.data.organization.panNumber;

          if (updated.length) {
            bus.$emit("updated");
          }
        } catch (error) {
          this.$snotify.error(error, {
            showProgressBar: false,
          });
          return false;
        }
      }
    },
    async checkPanNumber() {
      try {
        const response = await initiateService.checkPanNumber();

        if (!response.panNumber) {
          this.panInfo = true;
        } else {
          this.panInfo = false;
        }
      } catch (error) {
        error;
      }
    },
    async closeModal() {
      this.panInfo = false;
      this.$router.go(-1);
    },
    async showVRLRate(vrlId) {
      if (this.vrlDeliveryLocationId) {
        this.freightCharges = 0;
        // loop till we get it
        this.vrlLocations.some((vrl) => {
          if (vrl.value === vrlId) {
            this.freightCharges = vrl.warehouseCharges;
            return true;
          }
          return false;
        });
      }
    },
  },
};
</script>
<style scoped>
.review-popup .layout .flex {
  padding: 5px 15px !important;
}
.review-popup .layout .v-input--radio-group__input .flex {
  padding: 4px !important;
}
</style>
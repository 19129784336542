<template>
	<div class="testimonail-v2 text-center section-gap  title-gap">
		<v-container grid-list-xl py-0>
            <div class="sec-title">
				<h2 class=" white--text">{{secTitle}}</h2>
			</div>
			<v-layout row wrap align-center justify-center>
				<v-row  >
					<v-col v-for="(data,key) in data"	:key="key" md="3" cols="6"	class="slide-item px-4">
							<div class="testi-card-v2 mb-6 text-center">
								<div class="layout row wrap align-center justify-center">
									<v-flex xs8 sm8 md6 lg6 xl6 text-center image-icon >
										<img class="br-100 text-center mx-auto" :src="data.img" width="350" height="350" alt="">
									</v-flex>
								</div>
							</div>
							<div class="testi-content text-center">
								<h4 class="font-weight-medium mb-0 white--text">{{data.name}}</h4>
							</div>
						</v-col>
				</v-row>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["rtlLayout"])
  },
  components: { },
   props: ["secTitle"],
  data() {
     
    return {
      
      data: [
        {
          img: "/static/images/usp/shop-online.png",
          name: "Shop Online",
        },
        
        {
          img: "/static/images/usp/order-dispatch.png",
          name: "Order Dispatch",
        },
        
        {
          img: "/static/images/usp/order-tracking.png",
          name: "Order Tracking",
        },
        
        {
          img: "/static/images/usp/delivered.png",
          name: "Delivered",
        }

        
      ]
    };
  }
};
</script>

<template>
  <div class="emb-thankyou-wrap section-gap">
    <div class="container py-0">
      <div class="layout justify-center align-center">
        <v-flex sm12 md10 lg8 xl8>
          <div class="form-img thank-you-image text-center">
            <h2 class="white--text">Thank You</h2>
          </div>
          <div class="layout align-center justify-center">
            <v-flex xs12 md8 lg6 xl6>
              <div class="emb-card pa-6 thankyou-block white pa-6 text-center">
                <h4 class="px-6 pb-2 text-center">For shopping with us.</h4>
                <v-divider class="pt-2 pb-4"></v-divider>
                <div class="mx-auto text-center">
                  <h6>Status: {{ orderData.status }}</h6>
                  <v-btn
                    color="primary"
                    v-show="
                      orderData.status == 'Delivery Confirmed' ||
                      orderData.status == 'Order Placed'
                    "
                    @click.stop="orderDetail"
                    >View Detail</v-btn
                  >
                </div>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
import api from "Api";

// import { bus } from "../main";
import Nprogress from "nprogress";
export default {
  data() {
    return {
      orderData: {},
    };
  },
  mounted() {
    this.getorderstatus(this.$route.query.id);
  },
  methods: {
    async getorderstatus(orderId) {
      if (orderId) {
        Nprogress.start();
        try {
          const response = await api.get(
            process.env.VUE_APP_BASE_API +
              "/orders/" +
              orderId +
              "?doTotal=false"
          );

          this.orderData = response.data;

          return this.orderData;
        } catch (error) {
          return error;
        } finally {
          Nprogress.done();
        }
      }
    },
    async orderDetail() {
      if (this.$route.query.id) {
        this.$router.push("/account/order-detail?id=" + this.$route.query.id);
      }
    },
  },
};
</script>
<style scoped>
.thank-you-image h2 {
  text-shadow: 1px 1px 3px #333;
}
</style>
<template>
  <div>
    <v-expansion-panel-header class="primary"
      ><h4 class="mx-2 mb-0 white--text">
        Select {{ addressType }} address
      </h4></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <div class="px-2">
        <address-component
          :data="this.address"
          :addressType="addressType"
          :selectedAddressId="selectedAddressId"
        ></address-component>
      </div>

      <v-card-text v-if="this.toggleForm" class="border-top">
        <v-form ref="form" v-model="valid">
          <v-layout row wrap>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.fullname"
                :rules="inputRules.basictextRules"
                label="Name*"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.streetName"
                :rules="inputRules.basictextRules"
                label="Line 1*"
                required
              ></v-text-field>
            </v-flex>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.aptBuilding"
                label="Line 2"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.zipCode"
                :rules="inputRules.pincoderule"
                label="Pin Code*"
                required
                validate-on-blur
                @blur="getlocationinfo()"
                pattern="[0-9]+"
                type="number"
                v-on:keyup="getlocationinfo()"
              ></v-text-field>
            </v-flex>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.cityState"
                :rules="inputRules.basictextRules"
                label="City and State*"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex lg4 xl4 xs12 sm6 md6>
              <v-text-field
                v-model="userInfo.country"
                :rules="inputRules.basictextRules"
                label="Country*"
                disabled
              ></v-text-field>
            </v-flex>
          </v-layout>
          <div class="layout justify-start px-4">
            <v-btn pl-0 ml-0 large @click="addNewAddress" class="accent"
              >Add New Address</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../../services/initiate.service";

import { bus } from "../../../main";
import userService from "../../../services/auth/user.service";

import AddressComponent from "./AddressComponent";
export default {
  props: ["addressType"],
  components: {
    addressComponent: AddressComponent,
  },
  data() {
    return {
      valid: false,
      userInfo: {
        fullname: "",
        streetName: "",
        aptBuilding: "",
        zipCode: "",
        cityState: "",
        country: "",
        phone: "",
        emailid: "",
        zipCodeId: "",
        cityid: "",
        stateid: "",
        countryid: "",
        contactId: "",
      },
      address: [],
      selectedAddressId: 0,
      toggleForm: false,
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty."],
        pincoderule: [
          (v) => !!v || "This field should not be empty.",
          (v) => v.length == 6 || "Please enter Valid Pincode",
        ],
        emailRules: [
          (v) => !!v || "This field should not be empty.",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        mobilenumber: [
          (v) => !!v || "Mobile number is mandatory",
          (v) =>
            /^[0-9]{2}[0-9]{8}$/.test(v) || "Please enter valid mobile number",
        ],
      },
    };
  },
  mounted() {
    initiateService.init(true);
    this.userInfo.fullname = initiateService.loggedin.username;
    this.userInfo.emailid = initiateService.loggedin.useremailId;
    this.userInfo.phone = initiateService.loggedin.phonenumber;

    this.toggleForm = false;
    this.getAddress();
  },
  created() {
    bus.$on(`select${this.addressType}Address`, (address) => {
      if (this.addressType === "billing") {
        initiateService.loggedin.addressid = address.id;
        this.$emit("changeStepOneForm");
      } else if (this.addressType === "shipping") {
        initiateService.loggedin.deliveryid = address.id;
        initiateService.pincode = address.pincode;
        this.$emit("stepTwoFormValid");
      }
      this.selectedAddressId = address.id;
    });

    bus.$on("toggleNewAddressForm", () => (this.toggleForm = true));
    bus.$on("refetchAllAddresses", () => {
      this.getAddress();
    });
  },
  methods: {
    async getAddress() {
      try {
        await this.getContactId();
        this.address = [];
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/addresses?filter=contactId||eq||" +
            this.userInfo.contactId
        );
        if (response.data) {
          for (let key in response.data) {
            this.address.push(response.data[key]);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async getContactId() {
      try {
        if (this.userInfo.contactId) {
          return this.userInfo.contactId;
        }
        // get contact Id
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/contacts?filter=userId||eq||" +
            initiateService.loggedin.userid
        );
        if (response.data && response.data.length > 0) {
          this.userInfo.contactId = response.data[0].id;
          return this.userInfo.contactId;
        }
      } catch (error) {
        throw error;
      }
    },
    async addNewAddress() {
      if (this.$refs.form.validate()) {
        try {
          if (!this.userInfo.contactId) {
            await this.getContactId();
          }
          initiateService.pincode = this.userInfo.zipCode ;
          // add new address
          const response = await api.post(process.env.VUE_APP_BASE_API + "/addresses", {
            name: this.userInfo.fullname,
            contactId: this.userInfo.contactId,
            pincodeId: this.userInfo.zipCodeId,
            line1: this.userInfo.streetName,
            line2: this.userInfo.aptBuilding,
            cityId: this.userInfo.cityid,
            stateId: this.userInfo.stateid,
            countryId: this.userInfo.countryid,
            userId:
              initiateService.loggedin.userid || userService.loggedin.userid,
          });
          this.address.push(response.data);
          // get all addresses
          bus.$emit("refetchAllAddresses");
          // select new address
          bus.$emit(`select${this.addressType}Address`, response.data);
          setTimeout(() => {
            // close form
            this.toggleForm = false;
            this.emptyNewAddressForm();
          }, 500);
        } catch (error) {
          throw error;
        }
      }
    },
    emptyNewAddressForm() {
      // empty form
      this.userInfo = {
        fullname: "",
        streetName: "",
        aptBuilding: "",
        zipCode: "",
        cityState: "",
        country: "",
        phone: "",
        emailid: "",
        zipCodeId: "",
        cityid: "",
        stateid: "",
        countryid: "",
        contactId: "",
      };
    },
    getlocationinfo() {
      const pinCode = this.userInfo.zipCode;
      if (pinCode.length == 6) {
        api
          .get(
            process.env.VUE_APP_BASE_API + "/pincodes?filter=pincode||eq||" + pinCode
          )
          .then((response) => {
            this.userInfo.cityState =
              response.data[0].city.name + ", " + response.data[0].state.name;
            this.userInfo.country = response.data[0].country.name;

            this.userInfo.cityid = response.data[0].city.id;
            this.userInfo.stateid = response.data[0].state.id;
            this.userInfo.countryid = response.data[0].country.id;
            this.userInfo.zipCodeId = response.data[0].id;
          });
      }
    },
  },
};
</script>
<template>
  <div class="emb-signIn-wrap section-gap">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm6 md7 lg6 xl6 hidden-sm-and-down>
              <div class="form-img sign-in-image"></div>
            </v-flex>
            <v-flex sm10 md5 lg5 xl6>
              <div class="emb-card sign-in-form form-margin d-block white pa-6">
                <h4>User Sign In</h4>
                <v-form ref="loginform" v-model="valid">
                  <v-text-field
                    type="email"
                    placeholder="Email*"
                    :rules="inputRules.emailRules"
                    validate-on-blur
                    v-model="emailid"
                  >
                  </v-text-field>
                  <v-text-field
                    type="password"
                    placeholder="Password*"
                    :rules="inputRules.password"
                    validate-on-blur
                    v-model="password"
                  >
                  </v-text-field>
                  <div class="layout align-center justify-space-between">
                    <v-checkbox
                      v-model="checkbox"
                      label="Remember Me"
                      required="required"
                    ></v-checkbox>
                    <router-link to="forgot-password" class="text-lg-right"
                      >Forgot Password ?</router-link
                    >
                  </div>
                  <v-btn class="accent mb-3 ma-0" large @click="login()">
                    Sign In
                  </v-btn>
                  <p>
                    Don't Have account?
                    <router-link to="signup" class="accent--text"
                      >Click here to create one</router-link
                    >
                  </p>
                </v-form>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import * as userAuthService from "../../services/auth/user.service";
import { bus } from "../../main";
export default {
  data() {
    return {
      checkbox: false,
      emailid: "",
      password: "",
      valid: false,
      isloggedin: false,
      result: {},
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
        emailRules: [
          (v) => !!v || "E-mail address is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v || "").length > 7 || "Minimum password length is 8",
        ],
      },
    };
  },

  methods: {
    async login() {
      if (this.$refs["loginform"].validate()) {
        let user = {
          username: this.emailid,
          password: this.password,
        };
        try {
          await userAuthService.default.login(user, this.checkbox);

          if (userAuthService.default.loginsuccess) {
            // this.result = JSON.parse(localStorage.getItem("user"));

            this.$snotify.success("Login Successful", {
              showProgressBar: false,
            });
            bus.$emit("LoggedIn", this.result);
            this.$router.push(
              this.$route.query && this.$route.query.redirect
                ? this.$route.query.redirect
                : "/"
            );
          } else {
            this.$snotify.error("Login failed", {
              showProgressBar: false,
            });
          }
        } catch (error) {
          throw error;
        }
      }
    },
  },
};
</script>	

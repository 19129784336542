<template>
  <div>
    <emb-page-title
      heading="Register"
      subHeading=""
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    >
    </emb-page-title>
    <div class="emb-register-wrap section-gap">
      <div class="container py-5">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Personal Details
            </v-stepper-step>

            <v-divider></v-divider>
            <!-- 
            <v-stepper-step step="3">
              Additional Details
            </v-stepper-step>

            <v-divider></v-divider> -->

            <v-stepper-step :complete="e1 > 2" step="2">
              Mobile Details
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="step1form" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="text"
                      label="Full Name"
                      placeholder="Enter Full Name"
                      :rules="inputRules.basictextRules"
                      validate-on-blur
                      v-model="step1form.fullname"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="email"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      :rules="inputRules.emailRules"
                      validate-on-blur
                      v-model="step1form.emailid"
                      v-on:blur="checkEmailDuplicate($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="password"
                      label="Password"
                      placeholder="Enter Password*"
                      :rules="inputRules.password"
                      validate-on-blur
                      v-model="step1form.password"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mb-4"
                      type="password"
                      label="Confirm Password"
                      placeholder="Confirm Passowrd*"
                      :rules="inputRules.confirmpassword"
                      validate-on-blur
                      props="cnpassword"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      type="text"
                      label="Referral Code (optional)"
                      placeholder="Enter Referral Code"
                      validate-on-blur
                      v-model="step1form.referral"
                      v-on:blur="checkValidreferralCode($event)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-btn
                color="warning"
                @click.stop.prevent="saveDetails('step1form')"
              >
                Next <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-form ref="step2form" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="text"
                      label="Organization Name"
                      placeholder="Enter Organization Name"
                      :rules="inputRules.basictextRules"
                      validate-on-blur
                      v-model="step2form.organizationName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="text"
                      label="GST Number (optional)"
                      placeholder="Enter GST Number (optional)"
                      v-model="step2form.gstNumber"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      type="text"
                      label="PAN Number"
                      placeholder="Enter PAN Number"
                      :rules="pancard"
                      validate-on-blur
                      v-model="step2form.panNumber"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mb-4"
                      type="text"
                      label="FSSAI Number (optional)"
                      placeholder="Enter FSSAI Number (optional)"
                      v-model="step2form.fssaiNumber"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-layout justify-space-between>
                <v-btn color="warning" @click.stop.prevent="goBack"
                  ><v-icon>mdi-arrow-left</v-icon>
                  Back
                </v-btn>

                <v-btn
                  color="primary"
                  @click.stop.prevent="saveDetails('step2form')"
                >
                  Next <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="step3form" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mb-4"
                      label="Phone Number"
                      placeholder="Enter Mobile Number"
                      :rules="inputRules.mobilenumber"
                      validate-on-blur
                      v-model="step3form.mob"
                      pattern="[0-9]+"
                      type="number"
                      minlength="10"
                      maxlength="10"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn
                      color="warning"
                      v-if="step3form.mob != '' && step3form.mob.length === 10"
                      @click.stop.prevent="getOtp()"
                    >
                      Get OTP <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <small v-if="this.successmessage">{{
                      this.successmessage
                    }}</small>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mb-4"
                      type="text"
                      placeholder="Enter OTP"
                      :rules="inputRules.otp"
                      validate-on-blur
                      v-model="step3form.otp"
                      :disabled="!this.step3form.hash"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-layout justify-space-between>
                <v-btn color="warning" @click.stop.prevent="goBack"
                  ><v-icon>mdi-arrow-left</v-icon>
                  Back
                </v-btn>

                <v-btn
                  color="primary"
                  @click.stop.prevent="saveDetails('step3form')"
                  :disabled="!step3form.hash && !step3form.otp"
                >
                  Finish <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </div>
</template>
	
<script>
import api from "Api";

import * as userAuthService from "../../services/auth/user.service";
import { bus } from "../../main";

export default {
  data() {
    return {
      step1form: {
        fullname: "",
        emailid: "",
        password: "",
        referral: (this.$route.query && this.$route.query.referralCode) || "",
      },
      step2form: {
        organizationName: "",
        fssaiNumber: "",
        gstNumber: "",
        panNumber: "",
      },
      step3form: {
        mob: "",
        otp: "",
        hash: "",
      },
      successmessage: "",
      e1: 1,
      valid: false,
      pwd: "",
      cnpassword: "",
      otpresponse: [],
      temp: {
        fullName: "",
        organizationName: "",
        referralCode: "",
        fssaiNumber: "",
        gstNumber: "",
        panNumber: "",
        mobileNumber: "",
        emailAddress: "",
        password: "",
        usersRole: "",
        otp: "",
        hash: "",
      },
      params: [],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
        emailRules: [
          (v) => !!v || "E-mail address is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v || "").length > 7 || "Minimum password length is 8",
        ],
        confirmpassword: [
          (v) => !!v || "Confirm password cannot be Empty",
          (v) => (v || "").length > 7 || "Minimum password length is 8",
          (v) => v == this.step1form.password || "Password not Matched",
        ],
        mobilenumber: [(v) => !!v || "Mobile number is mandatory"],
      },
      pancard: [
        (v) => !!v || "PAN number is mandatory",
        (v) =>
          /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid",
      ],
      gstnumber: [
        (v) =>
          /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/.test(
            v
          ) || "GST number must be valid",
      ],
      fssai: [
        (v) => /^([0-9]{14,14})$/.test(v) || "FSSAI number must be valid",
      ],
    };
  },
  methods: {
    async checkEmailDuplicate() {
      let emailAddress = this.step1form.emailid;

      if (!emailAddress) {
        return false;
      }
      try {
        const exist = await userAuthService.default.verifyemail(emailAddress);
        if (exist) {
          this.$snotify.error("Email address already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async checkValidreferralCode() {
      let referral = this.step1form.referral;

      if (!referral) {
        return false;
      }
      try {
        const exist = await userAuthService.default.validReferral(referral);
        if (!exist) {
          this.$snotify.error("Please enter valid Referral Code", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async isPANInvalid() {
      let panNumber = this.step2form.panNumber;

      if (!panNumber) {
        // this.$snotify.error("PAN number is required", {
        //   showProgressBar: false,
        // });
        return false;
      }
      try {
        const exist = await userAuthService.default.verifyPAN(panNumber);
        if (exist) {
          this.$snotify.error("PAN number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async isGSTInvalid() {
      let gstNumber = this.step2form.gstNumber;

      if (!gstNumber) {
        return false;
      }
      try {
        const exist = await userAuthService.default.verifyGST(gstNumber);
        if (exist) {
          this.$snotify.error("GST number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async isFSSAIInvalid() {
      let fssaiNumber = this.step2form.fssaiNumber;

      if (!fssaiNumber) {
        return false;
      }
      if (fssaiNumber.length !== 14) {
        this.$snotify.error("FSSAI number is invalid", {
          showProgressBar: false,
        });
        return true;
      }
      return false;
    },
    async isMobileNumberInvalid() {
      try {
        // check if mobile number is exist
        const exist = await userAuthService.default.checkMobileDuplicate(
          this.step3form.mob
        );
        if (exist) {
          throw "Mobile number already exist";
        }
        return false;
      } catch (error) {
        throw error;
      }
    },
    async getOtp() {
      try {
        await this.isMobileNumberInvalid();
        // send OTP
        const response = await api.post(
          process.env.VUE_APP_BASE_API + "/users/requestOtp",
          {
            mobileNumber: this.step3form.mob,
          }
        );
        this.step3form.hash = response.data.otp.hash;
        this.successmessage = response.data.message;
      } catch (error) {
        this.$snotify.error(error, {
          showProgressBar: false,
        });
      }
    },

    async saveDetails(formname) {
      if (this.$refs[formname].validate()) {
        // Step email check
        if (this.e1 === 1) {
          const exist =
            (await this.checkEmailDuplicate(this.step1form.emailid)) ||
            (await this.checkValidreferralCode());
          if (exist) {
            return false;
          }
        }
        // check if PAN or/and GST is valid
        if (this.e1 === 2) {
          if (
            (await this.isPANInvalid()) ||
            (await this.isGSTInvalid()) ||
            (await this.isFSSAIInvalid())
          ) {
            return false;
          }
        }
        if (this.e1 >= 2) {
          this.temp = {
            fullName: this.step1form.fullname,
            organizationName: this.step2form.organizationName,
            fssaiNumber: this.step2form.fssaiNumber,
            referralCode: this.step1form.referral || null,
            gstNumber: this.step2form.gstNumber,
            panNumber: this.step2form.panNumber,
            mobileNumber: this.step3form.mob,
            emailAddress: this.step1form.emailid,
            password: this.step1form.password,
            usersRole: "Customer",
            hash: this.step3form.hash, //default 9944
            otp: this.step3form.otp,
          };
          try {
            await this.isMobileNumberInvalid();
            await userAuthService.default.register(this.temp);
            bus.$emit("LoggedIn");
            this.$snotify.success("Registered successful", {
              showProgressBar: false,
            });
            this.$router.push(
              this.$route.query && this.$route.query.redirect
                ? this.$route.query.redirect
                : "/"
            );
            return true;
          } catch (error) {
            this.$snotify.error(error, {
              showProgressBar: false,
            });
            return false;
          }
        }
        this.e1 += 1;
      }
    },
    async goBack() {
      this.e1 -= 1;
    },
  },
};
</script>
<template>
  <div class="editInfo-wrap emb-card pa-4">
    <div class="">
      <h4>Edit Profile Information</h4>
      <v-form ref="profileupdate" class="pa-5" v-model="valid">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              type="text"
              label="Full Name"
              placeholder="Enter Full Name"
              :rules="basictextRules"
              validate-on-blur
              v-model="profileupdate.fullname"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              type="text"
              label="Organization Name"
              placeholder="Enter Organization Name"
              :rules="basictextRules"
              validate-on-blur
              v-model="profileupdate.organizationName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              type="text"
              label="GST Number (optional)"
              placeholder="Enter GST Number (optional)"
              v-model="profileupdate.gstNumber"
              :rules="gstnumber"
              validate-on-blur
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              type="text"
              label="PAN Number"
              placeholder="Enter PAN Number"
              :rules="pancard"
              validate-on-blur
              v-model="profileupdate.panNumber"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mb-4"
              type="text"
              label="FSSAI Number (optional)"
              placeholder="Enter FSSAI Number (optional)"
              v-model="profileupdate.fssaiNumber"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click.stop.prevent="saveDetails('profileupdate')"
            >
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { bus } from "../../main";
import initiateService from "../../services/initiate.service";
import userService from "../../services/auth/user.service";
export default {
  data() {
    return {
      profileupdate: {
        fullname: initiateService.loggedin.username,
        organizationName: "",
        fssaiNumber: "",
        gstNumber: "",
        panNumber: "",
      },
      val: "",
      valid: false,
      basictextRules: [(v) => !!v || "This field should not be empty"],
      pancard: [
        (v) => !!v || "PAN number is mandatory",
        (v) =>
          /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid",
      ],
      gstnumber: [
        (v) => !!v || "This field should not be empty",
        (v) =>
          /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/.test(
            v
          ) || "GST number must be valid",
      ],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
    };
  },
  mounted() {
    this.fetchdata();
  },
  methods: {
    async isPANInvalid() {
      let panNumber = this.profileupdate.panNumber;

      if (!panNumber) {
        this.$snotify.error("PAN number is required", {
          showProgressBar: false,
        });
        return true;
      }
      try {
        const exist = await userService.verifyPAN(panNumber);
        if (exist) {
          this.$snotify.error("PAN number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$snotify.error(error, {
          showProgressBar: false,
        });
        return true;
      }
    },
    async isGSTInvalid() {
      let gstNumber = this.profileupdate.gstNumber;

      if (!gstNumber) {
        return false;
      }
      try {
        const exist = await userService.verifyGST(gstNumber);
        if (exist) {
          this.$snotify.error("GST number already exist", {
            showProgressBar: false,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return true;
      }
    },
    async isFSSAIInvalid() {
      let fssaiNumber = this.profileupdate.fssaiNumber;

      if (!fssaiNumber) {
        return false;
      }
      if (fssaiNumber.length !== 14) {
        this.$snotify.error("FSSAI number is invalid", {
          showProgressBar: false,
        });
        return true;
      }
      return false;
    },
    async saveDetails(formname) {
      if (this.$refs[formname].validate()) {
        if (
          (await this.isPANInvalid()) ||
          (await this.isGSTInvalid()) ||
          (await this.isFSSAIInvalid())
        ) {
          return true;
        }

        try {
          const response = await api.patch(
            process.env.VUE_APP_BASE_API +
              "/users/" +
              initiateService.loggedin.userid,
            {
              fullName: this.profileupdate.fullname,
              organization: {
                name: this.profileupdate.organizationName,
                fssaiNumber: this.profileupdate.fssaiNumber,
                gstNumber: this.profileupdate.gstNumber,
                panNumber: this.profileupdate.panNumber,
              },
            }
          );
          let updated = response.data.organization.panNumber;

          if (updated.length) {
            bus.$emit("updated");
          }
        } catch (error) {
          this.$snotify.error(error, {
            showProgressBar: false,
          });
          return false;
        }
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    async fetchdata() {
      const response = await initiateService.checkPanNumber();

      this.profileupdate.organizationName = response.name;
      this.profileupdate.panNumber = response.panNumber;
      this.profileupdate.fssaiNumber = response.fssaiNumber;
      this.profileupdate.gstNumber = response.gstNumber;
    },
  },
};
</script>
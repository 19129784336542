<template>
  <v-container grid-list-xl>
    <div class="sec-title text-center">
      <h2 class="mb-0">{{ secTitle }}</h2>
      <p>Facilities available to our users to ensure seamless trade</p>
    </div>
    <v-row justify="center" class="key-features">
      <v-col
        cols="6"
        md="3"
        v-for="(feature, key) in features"
        :key="key"
        class="pa-0"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 0"
            :class="{ 'on-hover': hover }"
            class="px-2 px-md-5 py-3 h-100 border-0 pb-2"
          >
            <div class="text-center">
              <img
                :src="'static/images/keyfeatures/' + key + '.png'"
                width="40%"
                class="img-fluid"
              />
              <h4 class="text-center font-custom">{{ feature.title }}</h4>
            </div>

            <div
              class="d-flex ma-0 justify-start align-baseline px-2 px-md-4"
              v-for="(pointer, key) in feature.pointers"
              :key="key"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle </v-icon>
              <small class="d-inline-block">{{ pointer }}</small>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["secTitle"],
  data() {
    return {
      features: [
        {
          title: "Doorstep delivery",
          pointers: [
            "Assured delivery timeline",
            "Real-time tracking",
            "Hassle-free product pickup and delivery",
          ],
        },
        {
          title: "Credit facility",
          pointers: [
            "Real Time affordable Credit ",
            "Quick and easy KYC",
            "Customized repayment",
          ],
        },
        {
          title: "Price Discovery",
          pointers: [
            "AI-driven fair pricing",
            "Determined by open market demand and supply dynamics",
          ],
        },
        {
          title: "Quality Standardization",
          pointers: [
            "Standardized products for every trade",
            "Rigorous and regular quality audits",
            "Assured quality product delivery",
          ],
        },
        {
          title: "Traceability",
          pointers: [
            "Live GPS tracking of orders",
            "Track origin of the products ",
            "Lab reports, COO/COA ",
            "Verified buyers and sellers",
          ],
        },
        {
          title: "Secured payments",
          pointers: [
            "Trusted payment gateways",
            "Real time confirmations",
            "Data protection ",
            "Easy refunds",
          ],
        },
        {
          title: "Grievance redressal",
          pointers: [
            "Easy returns policy",
            "Round-the-clock service for consumers",
            "24 hours support",
            "Instant replacement and refunds",
          ],
        },
        {
          title: "Data Analytics ",
          pointers: [
            "Pricing Trend",
            "Market insights and Industry news",
            "Crop reports",
            "Experts views",
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}
.v-card .v-icon {
  color: #c3f3a3;
  font-size: 13px;
}
.v-card:not(.on-hover) {
  opacity: 1;
}
.v-card.on-hover {
  background: #edffe1;
  box-shadow: 0px 3px 20px 5px rgba(0, 0, 0, 0.16) !important;
  z-index: 9;
}
.v-card h4 {
  font-size: 125%;
}
</style>
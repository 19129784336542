<template>
  <div>
    <v-expansion-panel-header class="primary"
      ><h4 class="mx-2 mb-0 white--text">
        Unlock Offers or Apply Promo Codes
      </h4></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <div>
        <v-tabs light slider-color="primary" v-model="active">
          <v-tab class="text-capitalize">Offer Code </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-0">
                <div class="header text-center bg-grey pt-12 pb-4">
                  <div class="mb-6">
                    <img alt="discount" src="/static/images/discount.png" />
                  </div>
                  <h4 class="mb-12 font-weight-medium">Apply Coupon Code</h4>
                </div>

                <v-radio-group
                  v-model="selectedCoupon"
                  row
                  class="text-center d-block"
                >
                  <v-container>
                    <v-row justify="center">
                      <v-col
                        lg="3"
                        v-for="(item, index) in this.offers"
                        :key="index"
                        :value="item.id"
                      >
                        <v-card>
                          <v-radio
                            class="ma-0 border-0"
                            style="width: 100%"
                            @click.stop="applyCoupons(item.code, item.id)"
                          >
                            <label slot="label" class="text-left">
                              <a
                                href="javascript:void(0)"
                                class="d-block text-left"
                              >
                                <span class="black--text font-weight-medium">{{
                                  item.name
                                }}</span>
                                <br />
                                <span class="grey--text text-center"
                                  >{{ item.code }}
                                </span>
                                <span class="grey--text text-center"
                                  >{{ item.totalAvailable }}
                                </span>
                                <span class="grey--text text-center"
                                  >Valid till {{ item.validTill }}
                                </span>
                              </a>
                            </label>
                          </v-radio>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-radio-group>

                <div class="form-wrapper py-12 text-center">
                  <div class="d-inline-flex">
                    <v-text-field
                      class="mt-0 pt-0"
                      light
                      label="Enter coupon code"
                      v-model="couponCode"
                      required
                      placeholder="Enter coupon code"
                      outlined
                      dense
                    >
                    </v-text-field>
                    <v-btn color="error" @click="applyCoupons(null)">
                      <v-icon right dark> send </v-icon>
                    </v-btn>
                  </div>
                  <p v-show="couponMsg" v-text="couponMsg"></p>
                  <p class="mb-0 grey--text">
                    *Only Valid coupon or offer code is acceptable.
                  </p>
                </div>

                <v-row align="start" justify="center" class="text-center">
                  <v-col>
                    <v-btn
                      pl-0
                      ml-0
                      large
                      @click="enterCouponCode"
                      class="accent"
                      >Continue</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "Api";

import initiateService from "../../../services/initiate.service";

export default {
  props: ["changePanel", "orderId", "amount"],
  computed: {
    ...mapGetters([""]),
  },
  data() {
    return {
      offers: [],
      selectedCoupon: "",
      couponCode: "",
      couponMsg: null,
      orderIds: this.$props.orderId,
      Totalamount: {},
      active: null,
    };
  },
  mounted() {
    initiateService.syncmycart().then((response) => {
      this.Totalamount = response.total;
      this.selectedCoupon = response.couponId || 0;
      this.getcoupons();
    });
  },
  methods: {
    /**
     * This function sets the value into store for CouponCode
     **/
    enterCouponCode() {
      this.$store.dispatch("enterCouponCode", this.couponCode);
      this.$emit("changePanel");
    },
    async getcoupons() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/coupons/list"
        );
        for (let key in response.data) {
          this.offers.push(response.data[key]);
          // get selected coupon code
          if (this.selectedCoupon == response.data[key].id) {
            this.couponCode = response.data[key].code;
          }
        }
        return true;
      } catch (error) {
        return error;
      }
    },
    async applyCoupons(code) {
      try {
        if (code) {
          this.couponCode = code;
        }
        if (!this.couponCode) {
          return false;
        }
        const response = await api.post(
          process.env.VUE_APP_BASE_API + "/coupons/applyPromo",
          {
            code: this.couponCode,
            orderId: this.orderIds,
            amount: this.Totalamount,
          }
        );
        this.couponMsg = response.data.message;
        if (response.data.totalDiscount) {
          this.$snotify.success(this.couponMsg, {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 1000,
            showProgressBar: false,
          });
        } else {
          this.$snotify.error(this.couponMsg, {
            showProgressBar: false,
          });
        }

        return true;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
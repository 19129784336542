<template>
  <div class="appdownload-v2 white section-gap">
    <v-container grid-list-xl pb-0>
      <v-row align="center" justify="space-between">
        <v-col cols="12" md="6">
          <div class="sec-title mb-3">
            <h2 class="text-left">{{ secTitle }}</h2>
          </div>
          <h6 class="text-left">Fast, Simple & Delightful.</h6>
          <h6 class="text-left">All it takes is 30 seconds to Download.</h6>
          <div class="d-flex justify-start mt-3 " style="max-width:350px">
            <a :href="playStore" target="_blank" class="mr-2">
              <img
                src="/static/images/revised/play-store.png" class="img-fluid"
              />
            </a>
            <a :href="appStore" target="_blank" class="mr-2">
              <img
                src="/static/images/revised/app-store.png" class="img-fluid"
              />
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <img src="static/images/revised/Downloadourapp2.jpeg" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
export default {
  props: ["data", "secTitle"],
  data() {
    return {
      appStore: AppConfig.appStore,
      playStore: AppConfig.playStore,
    };
  },
  mounted() {
    this.appStore = AppConfig.appStore;
    this.playStore = AppConfig.playStore;
  },
};
</script>

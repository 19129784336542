//=======================| App Configuration |========================//
let d = new Date();

export default {
  appLogo: "/static/images/logo.png",
  appLogoDark: "/static/images/logo.png", // App Logo,
  searchIcon: "/static/images/footer/search.png",
  pageTitle: "TradeBridge - Wholesale Agri Market Place",
  cartIcon: "/static/images/footer/cart-icon.png",
  addwishicon: "/static/images/products/Wish–1.png",
  removewishicon: "/static/images/products/Wish.png",
  brand: "TradeBridge", // Brand Name
  copyrightText:
    "Orchestrade Technologies Pvt Ltd. " +
    d.getFullYear() +
    ". All Rights Reversed", // Copyright
  color: {
    primary: "#3C9900",
    accent: "#3C9900",
  },
  webVersion: "1.7.0",
  baseApiURL: process.env.VUE_APP_BASE_API,
  appStore: "https://apps.apple.com/in/app/tradebridge/id1522137937",
  playStore:
    "https://play.google.com/store/apps/details?id=com.ashapura.tradebridge&hl=en&gl=IN",
  showLang: false,
  whatsAppOrderMobileNumber: 8657394820,
};

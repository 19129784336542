<template>
  <div class="fixed-header-wrap">
    <div class="header-wrap  px-4 py-1">
      <v-container>
        <v-layout align-center justify-space-between row ma-0>
          <v-flex xs6 sm6 md4 lg3 xl2 px-0>
            <div class="site-logo d-inline-block">
              <router-link to="/">
                <img alt="site-logo" height="34" :src="appLogo" width="224" />
              </router-link>
            </div>
          </v-flex>
          <v-flex xs6 sm6 md4 lg7 xl7 px-0>
            <div>
              <div class="menu-alignment">
                <emb-menu></emb-menu>
              </div>
              <div class="layout justify-end responsive-menu">
                <v-btn
                  icon
                  dark
                  @click="toggleMobileSidebar"
                  class="toggle-btn"
                >
                  <i class="material-icons">menu</i>
                </v-btn>
                <emb-search></emb-search>
                <span class="d-flex align-self-center mx-3">|</span>
                <emb-cart></emb-cart>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import AppConfig from "Constants/AppConfig";
import Search from "./Search";

import Cart from "./Cart";

export default {
  data() {
    return {
      appLogo: AppConfig.appLogo,
    };
  },
  components: {
    embMenu: Menu,
    embSearch: Search,

    embCart: Cart,
  },
  methods: {
    toggleMobileSidebar() {
      this.$store.dispatch("toggleSidebar", true);
    },
  },
};
</script>
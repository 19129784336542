<template>
<div>
    <emb-page-title
      heading="Blog"
      subHeading=""
      backgroundicon="/static/images/revised/blog.jpg"
    >
    </emb-page-title>
    	 <v-container>
             <emb-blog secTitle="Blogs" :data="blogData"> </emb-blog>
         </v-container>
</div>
</template>

<script>
import Blog from "Components/Widgets/Blog";
import blogData from "Assets/data/blogs";
export default {
  props: ["data", "secTitle"],
  components: {
    embBlog: Blog,
  },
  data(){
      return{
          blogData
      }
  } 
  
};
</script>

export default [
   {
      img: "/static/images/user-5.jpg",
      username: "Bruce Law",
      date: "Sept 12, 2018",
      comment:
         "Do you like Cheese Whiz? Spray tan? Fake eyelashes? That's what is Lorem Ipsum to many—it rubs them the wrong way, all the way. It's unreal, uncanny, makes you wonder if something is wrong, it seems to seek your attention for all the wrong reasons.",
      subComment: [
         {
            img: "/static/images/user-2.jpg",
            username: "Spencer Charlie",
            date: "Sept 14, 2018",
            type: "subComment",
            comment:
               "sub --  You begin with a text, you sculpt information, you chisel away what's not needed, you come to the point, make things clear, add value, you're a content person, you like words. Design is no afterthought, far from it, but it comes in a deserved second."
         },
         {
            img: "/static/images/user-3.jpg",
            username: "Harley Weiner",
            date: "oct 3, 2018",
            type: "subComment",
            comment:
               "sub --  But. A big but: Lorem Ipsum is not t the root of the problem, it just shows what's going wrong. Chances are there wasn't collaboration, communication, and checkpoints, there wasn't a process agreed upon or specified with the granularity required."
         }
      ]
   },
   {
      img: "/static/images/user-4.jpg",
      username: "Embryo Vrumer",
      date: "Oct 29,  2018",
      subComment: null,
      comment:
         "Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method  of drilling a hole in the cartridge and re-filling it with toner Discount toner cartridges were originally  as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner."
   },
   {
      img: "/static/images/user-2.jpg",
      username: "Spencer Charlie",
      date: "17 Aug,  2018",
      subComment: null,
      comment:
         "Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method  of drilling a hole in the cartridge and re-filling it with toner Discount toner cartridges were originally  as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner."
   }
]
<template>
  <div>
    <v-row class="my-3">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(item, index) in data"
        :key="index"
        v-show="item.enabled"
      >
        <v-card
          class="mx-auto"
          v-bind:class="{ selectedcard: Address.id === item.id }"
          max-width="344"
          elevation="2"
          outlined
        >
          <v-card-text>
            <h4 class="h3 text--primary" v-text="item.name"></h4>
            <div class="text--primary">
              <p class="ma-0" v-if="item.line1" v-text="item.line1"></p>
              <p class="ma-0" v-if="item.line2" v-text="item.line2"></p>
              <p
                class="ma-0"
                v-if="item.city.name"
                v-text="
                  item.city.name +
                  ', ' +
                  item.state.name +
                  ', ' +
                  item.country.name
                "
              ></p>
              <p
                class="ma-0"
                v-if="item.pincode.pincode"
                v-text="item.pincode.pincode"
              ></p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click.stop="selectedAddress(item.id,item.pincode.pincode)">
              Select{{ Address.id === item.id ? "ed" : "" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4"
        ><v-card class="text-center" max-width="344" elevation="2" outlined>
          <v-btn
            class="ma-10"
            elevation="3"
            large
            raised
            @click.stop="selectedAddress(0)"
            >Add New Address</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "../../../main";
export default {
  props: ["data", "addressType", "selectedAddressId"],
  data() {
    return {
      Address: {
        addressType: "",
        id: this.$props.selectedAddressId || 0,
      },
    };
  },
  mounted() {
    this.Address.addressType = this.addressType;
  },
  methods: {
    selectedAddress(id,pincode) {
      if (id) {
        this.Address.addressType = this.$props.addressType;
        this.Address.id = id;
        this.Address.pincode = pincode;

        bus.$emit(`select${this.addressType}Address`, this.Address);
      } else {
        // add new
        bus.$emit("toggleNewAddressForm");
      }
    },
  },
};
</script>

<style>
.v-card {
  opacity: 0.8;
}

.v-card:hover,
.selectedcard {
  opacity: 1;
}
</style>
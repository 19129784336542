<template>
  <div class="emb-payment-wrap">
    <emb-page-title
      heading="Payment Summary"
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    ></emb-page-title>
    <v-container class="my-5">
      <!-- cart Details -->
      <div class="emb-card mb-12 pa-6 white">
        <v-layout row wrap align-center ma-0>
          <v-flex
            lg3
            v-for="(cart, index) in this.orderData.ordersProduct"
            :key="index"
          >
            <v-layout row align-center ma-0 mb-3>
              <v-flex lg4>
                <img
                  :src="
                    'https://api.ashapuracommodities.com/products-images/img/' +
                    cart.product.id +
                    '/md'
                  "
                  width="80"
                  height="77"
                />
              </v-flex>
              <v-flex lg8 cart-product-name pa-0>
                <h6 class="mb-1 word-wrap-break">
                  {{ cart.product.name }}
                </h6>
                <p class="ma-0">
                  {{ cart.productsVariation.name }}
                </p>
                <small
                  ><emb-currency-sign></emb-currency-sign>
                  {{ +cart.salePrice || cart.unitPrice }}</small
                >
                x
                {{ cart.quantity }}

                <p class="ma-0">
                  <emb-currency-sign></emb-currency-sign>
                  {{ cart.subTotal }}
                </p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
      <!-- Contact Details -->
      <v-layout>
        <v-flex md6 class="mr-md-5">
          <!-- Billing Address -->
          <div class="emb-card mb-12 pa-6 white" v-if="orderData.address">
            <h5>Billing Address</h5>
            <p class="ma-0" v-text="this.orderData.address.name"></p>
            <p class="ma-0" v-text="this.orderData.address.line1"></p>
            <p class="ma-0" v-text="this.orderData.address.line2"></p>
            <p
              class="ma-0"
              v-if="this.orderData.address.city.name"
              v-text="
                this.orderData.address.city.name +
                ', ' +
                this.orderData.address.state.name +
                ', ' +
                this.orderData.address.country.name
              "
            ></p>
            <p class="ma-0" v-text="this.orderData.address.pincode.pincode"></p>
          </div>
        </v-flex>

        <v-flex md6>
          <!-- Billing Address -->
          <div
            class="emb-card mb-12 pa-6 white"
            v-if="orderData.shippingAddress"
          >
            <h5>Shipping Address</h5>
            <p class="ma-0" v-text="this.orderData.shippingAddress.name"></p>
            <p class="ma-0" v-text="this.orderData.shippingAddress.line1"></p>
            <p class="ma-0" v-text="this.orderData.shippingAddress.line2"></p>
            <p
              class="ma-0"
              v-if="this.orderData.shippingAddress.city.name"
              v-text="
                this.orderData.shippingAddress.city.name +
                ', ' +
                this.orderData.shippingAddress.state.name +
                ', ' +
                this.orderData.shippingAddress.country.name
              "
            ></p>
            <p
              class="ma-0"
              v-text="this.orderData.shippingAddress.pincode.pincode"
            ></p>
          </div>
        </v-flex>
      </v-layout>
      <h5>Billing Details</h5>
      <v-simple-table class="payment-summary">
        <tbody>
          <tr>
            <td>Order Total</td>
            <td>
              <emb-currency-sign></emb-currency-sign>
              {{ formatNumber(this.orderData.total) }}
            </td>
          </tr>
          <tr v-if="orderData.couponId" class="red--text">
            <td>Discount</td>
            <td>
              -&nbsp;<emb-currency-sign></emb-currency-sign>
              {{ formatNumber(this.orderData.discountAmount) }}
            </td>
          </tr>
          <tr v-if="orderData && orderData.deliveryOption">
            <td>Delivery Option</td>
            <td>
              {{ this.orderData.deliveryOption }}
              <p
                class="text-left text-small text-italic text-wrap"
                v-if="
                  orderData &&
                  ['VRL', 'nominatedTransport'].indexOf(
                    orderData.deliveryOption
                  ) > -1
                "
              >
                ({{
                  orderData.deliveryOption === "VRL"
                    ? orderData.vrlDeliveryLocation.name
                    : orderData.nominatedTransportDetails
                }})
              </p>
            </td>
          </tr>

          <tr v-if="orderData && orderData.deliveryOption">
            <td>Freight Charges</td>
            <td>
              <emb-currency-sign></emb-currency-sign>
              {{ formatNumber(this.orderData.freightCharges) }}
            </td>
          </tr>

          <tr>
            <td>Total Tax</td>
            <td>
              <emb-currency-sign></emb-currency-sign>
              {{ formatNumber(this.orderData.taxedAmount) }}
            </td>
          </tr>
          <tr v-show="walletBalance">
            <td class="text-wrap">
              <v-checkbox
                :disabled="parseInt(orderData.grandTotal) < 10000"
                v-model="isWallet"
                :label="'Wallet'"
                :messages="'Minimum order grand total should be INR 10,000 to apply the wallet balance'"
                v-on:change="WalletCalculations"
              ></v-checkbox>
            </td>
            <td><emb-currency-sign></emb-currency-sign> {{ usedWallet }}</td>
          </tr>

          <tr>
            <td>
              <h5 class="ma-0">Grand Total</h5>
              <small>Freight will be charged based on actuals.</small>
            </td>
            <td>
              <h5>
                <emb-currency-sign></emb-currency-sign>
                {{ formatNumber(grandTotal) }}
              </h5>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <br />
      <div class="text-center">
        <v-btn color="primary" v-on:click.stop="selectpayment"
          >Proceed to Payment</v-btn
        >
      </div>
      <br />
    </v-container>

    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            ><h4 class="ma-0 white--text">Select Payment Options</h4></v-toolbar
          >
          <v-card-text class="d-flex justify-center align-items-center mt-10">
            <template v-for="(item, index) in paymentMethods">
              <v-btn
                class="ma-2"
                :key="index"
                v-on:click.stop="checkPaymentType(item)"
              >
                <img
                  :src="process.env.VUE_APP_BASE_URL+'/' + item + '.png'"
                  style="height: 35px; width: auto"
                />
              </v-btn>
            </template>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="warning" @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>


<script>
import initiateService from "../../services/initiate.service";
import api from "Api";
import AppConfig from "Constants/AppConfig";
import { bus } from "../../main";
export default {
  data() {
    return {
      dialog: false,
      orderData: {},
      appLogo: AppConfig.appLogo,
      paymentInterval: null,
      paymentTimeout: null,
      paymentPopup: null,
      paymentMethods: null,
      option: {},
      transactiontId: null,
      isWallet: false,
      walletBalance: "",
      walletUsageAmount: "",
      usedWallet: 0,
      grandTotal: 0,
    };
  },
  created() {
    bus.$on("paymentSuccessfull", () => {
      this.$router.push("/thank-you?id=" + this.orderData.id);
    });
  },
  mounted() {
    this.availablePayments();
    this.fetchUserDetails();
    let razorPayScript = document.createElement("script");
    razorPayScript.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    document.head.appendChild(razorPayScript);
  },
  methods: {
    selectpayment() {
      // check enabled payment gateway
      // if just 1, just proceed
      // else who dialog
      if (this.paymentMethods.length === 1) {
        this.checkPaymentType(this.paymentMethods[0]);
      } else {
        this.dialog = true;
      }
    },
    checkPaymentType(item) {
      if (item == "razorpay") {
        this.payFromRazorPay();
      } else {
        this.processForPayment();
      }
    },

    async payFromRazorPay() {
      let description = [];

      this.orderData.ordersProduct.forEach((item) => {
        description.push(item.product.name);
      });

      const razKey = await api.get(
        process.env.VUE_APP_BASE_API + "/common/razor-pay-keys"
      );

      const razOrder = await api.get(
        process.env.VUE_APP_BASE_API +
          "/transactions/generate-order/" +
          this.orderData.id +
          "?isWallet=" +
          this.isWallet
      );

      const rzp_options = {
        key: razKey.data.keyId,
        key_id: razKey.data.keyId,
        key_secret: razKey.data.keySecret,
        amount: razOrder.data.amount * 100,
        order_id: razOrder.data.id,
        name: "TradeBridge",
        image: this.appLogo,
        description: description.toString(),
        handler: async function (response) {
          const test = await api.get(
            process.env.VUE_APP_BASE_API +
              "/transactions/fetch/" +
              response.razorpay_payment_id
          );
          bus.$emit("paymentSuccessfull", test);
        },
        modal: {
          ondismiss: function () {
            alert("Payment Failed");
          },
        },
        prefill: {
          email: initiateService.loggedin.useremailId,
          contact: initiateService.loggedin.phonenumber,
        },
        theme: {
          color: "#3c9900",
        },
      };
      const rzp1 = new window.Razorpay(rzp_options);
      rzp1.open();
      setTimeout(() => {
        // interval to check status
        this.paymentInterval = setInterval(() => {
          this.checkOrderStatus();
        }, 2000);
      }, 1000);
    },
    async availablePayments() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/settings/"
        );
        this.paymentMethods = response.data[0].paymentGateway;
      } catch (error) {
        throw error;
      }
    },
    async fetchUserDetails() {
      await this.fetchdetails();
      this.walletBalance = this.orderData.user.walletBalance;
      this.walletUsageAmount = this.orderData.user.walletUsageAmount;
    },
    async fetchdetails() {
      if (!initiateService.orderId) {
        await initiateService.syncmycart();
      }
      this.orderData = await initiateService.getOrderDetail(
        initiateService.orderId
      );
      return (this.grandTotal = this.orderData.grandTotal);
    },
    clearPaymentInterval() {
      if (this.paymentPopup) {
        this.paymentPopup.close();
        this.paymentPopup = null;
      }
      if (this.paymentInterval) {
        clearInterval(this.paymentInterval);
        this.paymentInterval = true;
      }
      this.clearPaymentTimeout();
    },
    clearPaymentTimeout() {
      if (this.paymentTimeout) {
        clearTimeout(this.paymentTimeout);
        this.paymentTimeout = null;
      }
    },
    processForPayment() {
      this.clearPaymentInterval();
      // open payment popup window
      let params =
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=900,height=600,left=100,top=100";
      this.paymentPopup = window.open(
        process.env.VUE_APP_BASE_API +
          "/transactions/init/" +
          this.orderData.id,
        "Payment",
        params
      );

      // delay
      setTimeout(() => {
        // interval to check status
        this.paymentInterval = setInterval(() => {
          this.checkOrderStatus();
        }, 2000);
        // add payment timeout
        this.clearPaymentTimeout();
        this.paymentTimeout = setTimeout(() => {
          this.clearPaymentInterval();
        }, 1000 * 60 * 5); // 5min
      }, 1000);
    },
    async checkOrderStatus() {
      try {
        const response = await api.get(
          process.env.VUE_APP_BASE_API +
            "/orders/" +
            this.orderData.id +
            "?doTotal=false"
        );
        if (response.data) {
          const order = response.data;
          if (["Order Placed", "Partial Payment"].indexOf(order.status) > -1) {
            // clear interval
            this.clearPaymentInterval();
            // redirect to Thank you
            this.$router.push("/thank-you?id=" + this.orderData.id);
          }
        }
      } catch (error) {
        throw error;
      } finally {
        if (!this.paymentPopup) {
          this.clearPaymentInterval();
        }
      }
    },
    WalletCalculations() {
      if (this.isWallet) {
        if (
          parseInt(this.orderData.grandTotal) > parseInt(this.walletUsageAmount)
        ) {
          if (parseInt(this.walletBalance) > parseInt(this.walletUsageAmount)) {
            this.usedWallet = parseInt(this.walletUsageAmount).toFixed(0);
          } else {
            this.usedWallet = parseInt(this.walletBalance).toFixed(0);
          }
        } else {
          this.usedWallet = (parseInt(this.orderData.grandTotal) - 1).toFixed(
            0
          );
        }
      } else {
        this.usedWallet = 0;
      }
      this.grandTotal = (
        parseInt(this.orderData.grandTotal) - parseInt(this.usedWallet)
      ).toFixed(2);
    },

    formatNumber(num) {
      num = num + "" || "";
      // works for integer and floating as well
      var numberAndDecimal = num.split(".");
      var decimals = numberAndDecimal[1] || null;
      numberAndDecimal = numberAndDecimal[0].replace(
        /(\d)(?=(\d\d)+\d$)/g,
        "$1,"
      );
      num = decimals ? numberAndDecimal + "." + decimals : numberAndDecimal;

      return num;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearPaymentInterval();
    next();
  },
};
</script>

<style>
</style>
<template>
  <div class="mr-3">
    <v-btn class="cart-btn" fab small elevation="0" color="white" to="/cart">
      <v-badge right large color="accent">
        <span slot="badge">{{ this.cartquntity }}</span>
        <img :src="cartIcon" class="img-fluid" style="width: 20px" />
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
import { bus } from "../../../main";
import AppConfig from "Constants/AppConfig";
import initiateService from "../../../services/initiate.service";
import userService from "../../../services/auth/user.service";

export default {
  components: {},
  data() {
    return {
      cartquntity: 0,
      cartIcon: AppConfig.cartIcon,
    };
  },
  created() {
    bus.$on("cartQuantityUpdated", () => {
      this.cartquntity = initiateService.cartquntity;
    });
    bus.$on("isLoggedin", () => {
      if (userService.loggedin.userid || initiateService.loggedin.userid) {
        this.cartquntity = initiateService.cartquntity;
      }else{
        this.cartquntity=0;  
      }
    });
    bus.$on("paymentSuccessfull", () => {
        initiateService.syncmycart();
        alert('paymentSuccessfull');
        this.cartquntity=0;
    });

    bus.$on("productadded", () => {
        
      initiateService.getCartCount(
        userService.loggedin.userid || initiateService.loggedin.userid
      );
    });
    
    bus.$on("checkcart", () => {
        if(userService.loggedin.userid || initiateService.loggedin.userid){
      initiateService.syncmycart(
        userService.loggedin.userid || initiateService.loggedin.userid
      );
      }else{
           this.cartquntity=0;
      }
    });
  },
  mounted() {
      if(initiateService.isloggedin){
          this.cartquntity = initiateService.cartquntity;
      }else{
          this.cartquntity=0;
      }
      
  },
  methods: {},
};
</script>
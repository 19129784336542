export default {
 home: "Home",
 homeOne: "Home One",
 homeTwo: "Home Two",
 homeThree: "Home Three",
 userProfile: "User Profile",
 shop: "Shop",
 productDetails: "Product Details",
 cart: "Cart",
 checkout: "Checkout",
 payment: "Payment",
 fashion: "Fashion",
 gadgets: "Gadgets",
 accessories: "Accessories",
 categories: "Categories",
 impact: "Impact",
 men: "Men",
 jean: "Jean",
 jackets: "Jackets",
 shirt: "Shirt",
 tShirt: "T-Shirt",
 women: "Women",
 dress: "Dress",
 dresses: "Dresses",
 headphone: "Headphone",
 smartphone: "Smartphone",
 watch: "Watch",
 speaker: "Speaker",
 laptopAccessories: "Laptop Accessories",
 belts: "Belts",
 jewellery: "Jewellery",
 purse: "Purse",
 pages: "Pages",
 about: "About",
 termAndCondition: "Terms and Conditions",
 privacyPolicy: "Privacy Policy",
 blogDetail: "Blog Detail",
 faq: "FAQ",
 Page: "Page",
 "404Page": "404 Page",
 session: "Session",
 signIn: "Sign In",
 register: "Register For Free",
 forgotPassword: "Forgot Password",
 thankYou: "Thank You",
 contactUs: "Contact",
 social: "Social",
 facebook: "Facebook",
 twitter: "Twitter",
 youtube: "Youtube",
 googlePlus: "Google Plus",
 instagram: "Instagram",
 reports: "Reports",
 invoices: "Invoices",
 profile: "Profile",
 products: "Products",
 productAdd: "Product Add",
 goToSite: "Go To Site",
 adminPanel: "Admin Panel",
 edit: "Edit",
 blog: "Blog",
 OurStory: "Our Story",
 OurCategories: "Our Categories",
 viewAll: "View All",
 FeaturedProducts: "Featured Products",
 OurProcess: "Our Process",
 total: "Total",
 subtotal: "Sub Total",
 tax: "Tax",
 information: "Information",
 customer: "Customer",
 MyOrder: "My Order",
 MyBids: "My Bids",
 Credit: "My Credit",
 MyWishlist: "My Wishlist",
 MyAddresses: "My Addresses",
 MyAccount: "My Account",
 KeyFeatures: "Key Features",
 valueProposition: "Value Proposition",
 OurApproach: "Our Approach",
 userJourney: "User Journey",
 keyClients: "Our Key Clients",
};

<template>
  <v-layout column wrap>
    <v-flex lg4 v-for="(product, key) in data" :key="key">
      <product-list-item :data="product" :catid="catid"></product-list-item>
    </v-flex>
  </v-layout>
</template>

<script>
import ProductListItem from "./ProductListItem";

export default {
  props: ["data", "catid", "colxs", "colsm", "colmd", "collg", "colxl"],
  components: {
    ProductListItem,
  },
  mounted() {
    this.test();
  },
  methods: {
    onPageChange() {
      window.scrollTo(0, 0);
    },
    test() {},
  },
};
</script>
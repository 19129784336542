<template>
  <div class="home-banner-v3 relative">
    <div grid-list-xl>
      <v-carousel v-if="false" class="slider-background-v3" v-model="model">
        <v-carousel-item v-for="(item, key) in sliderItem" :key="key">
          <v-sheet :color="color" height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <img :src="item.src" />
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="slider-content-v3-revised d-flex align-center font-custom">
        <v-container>
          <div class="row justify-lg-space-between align-center">
            <div class="col-12 col-md-8 text-left">
              <div
                class="d-flex flex-column justify-space-between align-start"
                height="100%"
              >
                <p></p>
                <h4 class="mb-0  white--text">#e-mandi</h4>
                <h6 class="text-inverse white--text ma-0">
                  A B2B virtual marketplace created with the goal of
                  digitalizing agricultural mandis using cutting-edge
                  technology.
                </h6>
                <h2 class="font-bold text-left white--text font-custom">
                  Buy or Sell across India with one Click
                </h2>
              </div>
            </div>
            <div class="col-12 col-md-4 text-center">
              <div class="py-12 px-5 banner-sidepanel">
                <h6 class="font-bold">Product Categories</h6>
                <v-row align="start" justify="center" class="my-4">
                  <v-col
                    cols="4"
                    class="categories pa-3"
                    v-for="(content, key) in data2"
                    :key="key"
                  >
                    <a
                      :href="
                        'https://shop.tradebridge.co.in/buyer-category-products/list/' + content.id "
                    >
                      <img
                        :src="
                          '/static/images/categories/' + content.id + '.png'
                        "
                        class="img-fluid"
                      />
                      <p class="black--text ma-0">{{ content.name }}</p>
                    </a>
                  </v-col>
                </v-row>
                <h6 class="font-bold mb-2">For Best Rates</h6>
                <v-btn
                  class="
                    accent-gradient
                    white--text
                    d-inline-flex
                    justify-center
                    align-center
                    cpx-0
                  "
                  to="/category"
                >
                  <v-icon left white> mdi-cart </v-icon>
                  Order Now
                </v-btn>
              </div>
            </div>
            <div class="col-12 col-md-7 my-3 bottom-sociallink">
              <emb-social-follow class=""></emb-social-follow>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
   
<script>
export default {
  props: ["data", "data2"],
  components: {},
  data() {
    return {
      sliderItem: [
        { src: "/static/images/banner/banner1.png" },
        { src: "/static/images/banner/banner2.png" },
        { src: "/static/images/banner/banner3.png" },
      ],
      slickOptions: {
        rtl: this.rtlLayout,
        slidesToShow: 1,
        infinite: true,
        swipe: true,
        autoplay: true,
        dots: true,
        arrows: true,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.home-banner-v3 {
  position: relative;
}
.slider-background-v3 {
  position: absolute;
}
.slider-content-v3-revised {
  position: relative;
  background: url("/static/images/banner/banner-new.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
}
.slider-content-v3-revised:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #77b31369;
}

.slider-content-v3-revised .container {
    z-index: 1; 
}

.slider-content-v3-revised .categories {
  text-align: center;
}
.slider-content-v3-revised .categories img {
  min-height: auto;
  width: 96%;
  height: auto;
}
.slider-content-v3-revised .banner-sidepanel {
  background: rgb(231 255 189 / 71%);
}
</style>

<template>
  <div class="emb-aboutus-wrap">
    <div class="page-title-bar page-title-bar2">
      <div class="container">
        <v-row align="center" justify="space-between">
          <v-col cols="6" md="">
            <img
              src="/static/images/becomesupplier/topImage.png"
              class="img-fluid"
            />
          </v-col>
          <v-col cols="7" md="5" sm="5">
            <div class="px-4">
              <h2 class="mb-4 text-left font-weight-bold">Become a Supplier</h2>
              <p class="font-weight-regular text-left">
                India's largest Reselling platform with lowest commission rates
              </p>
              <div
                class="
                  d-flex
                  align-center
                  justify-space-between
                  becomesellerform
                "
              >
                <v-text-field
                  prefix="+91"
                  outlined
                  dense
                  height="36px"
                  placeholder="Enter your mobile Number"
                  hide-details
                  v-model="this.contactnumber"
                  :rules="inputRules.mobilenumber"
                  class="custominput"
                />
                <v-btn
                  class="ma-0 gradient-bg"
                  flat
                  text
                  @click.stop="dialog = true"
                >
                  Start Selling
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="emb-about-content"
      style="background: linear-gradient(90deg, #84ce02, #066935)"
    >
      <div class="about-page section-gap">
        <v-container grid-list-xl>
          <v-row justify="space-between" style="width: 100%">
            <v-col
              class="text-center"
              cols="6"
              md="2"
              v-for="(item, key) in this.features"
              :key="key"
            >
              <img :src="item.image" class="img-fluid" width="70px" />
              <h5 class="px-5 white--text">{{ item.name }}</h5>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- Key features -->
    <div class="section-gap title-gap">
      <v-container grid-list-xl>
        <div class="sec-title text-center">
          <h2 class="mb-0">Value Proposition as a Seller</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <v-row justify="center" class="key-features">
          <v-col
            cols="6"
            md="2"
            v-for="(feature, key) in key_features"
            :key="key"
            class=""
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 0"
                :class="{ 'on-hover': hover }"
                class="px-2 px-md-5 py-3 h-100 pb-2"
                style="
                  border: 1px solid rgb(128 128 128 / 24%);
                  border-radius: 5px;
                "
              >
                <div class="text-center">
                  <img
                    :src="
                      'static/images/becomesupplier/keyFeat/' + key + '.png'
                    "
                    width="40px"
                    class="img-fluid"
                  />
                  <h6 class="text-left font-custom">{{ feature.title }}</h6>
                </div>

                <div
                  class="d-flex ma-0 justify-start align-baseline"
                  v-for="(pointer, key) in feature.pointers"
                  :key="key"
                >
                  <v-icon class="mr-2">mdi-checkbox-marked-circle </v-icon>
                  <small class="d-inline-block">{{ pointer }}</small>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="section-gap title-gap" style="background: #f8f8f8 !important">
      <v-container grid-list-xl>
        <div class="sec-title text-center">
          <h2 class="mb-0">Vendor Process Flow</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <v-row justify="center" align="center" class="journey-row2">
          <v-col
            md="2"
            class="journey-columns text-center"
            v-for="(item, key) in this.vendor_process"
            :key="key"
          >
            <img :src="item.image" class="img-fluid" width="50%" />
            <h5>{{ item.name }}</h5>
            <p style="font-size: 90%">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
              quisquam perspiciatis libero mollitia
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <emb-testimonial
      secTitle="Suppliers Testimonials"
      sec
      :data="TestimonialData"
    ></emb-testimonial>

    <div class="section-gap py-10 start-selling">
      <v-container grid-list-xl>
        <v-row>
          <v-col md="6">
            <div class="sec-title text-left white--text">
              <h2 class="mb-0 text-left white--text">Start Selling Today</h2>
              <p class="text-left white--text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <v-btn @click.stop="dialog = true">Start Selling</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="section-gap">
      <v-container grid-list-xl class="text-center">
        <h3>
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat
          deserunt porro eligendi magnam laudantium rerum totam explicabo
          aperiam nostrum, quo doloremque placeat, praesentium dolore. Totam
          voluptates corporis eos aperiam commodi."
        </h3>
      </v-container>
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Become a Supplier</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Full name*" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Contact Number*"
                persistent-hint
                v-model="this.contactnumber"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Email*" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Password*"
                type="password"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialog = false"> Close </v-btn>
          <v-btn color="red darken-1" @click="dialog = false"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="team-section text-center section-gap blue-grey lighten-5 hidden"
    >
      <v-container grid-list-xl text-xl-center>
        <div class="sec-title">
          <h2>Meet Our Team</h2>
        </div>
        <v-layout row wrap align-center justify-center>
          <v-flex
            xs12
            sm12
            md4
            lg4
            xl4
            text-center
            mb-sm-0
            mb-3
            v-for="(teamMembers, key) in teamListing"
            :key="key"
          >
            <div class="team-card mb-6">
              <img
                class="br-100"
                :src="teamMembers.image"
                width="200"
                height="200"
                alt="team-image"
              />
            </div>
            <div class="team-content">
              <h4 class="mb-0">{{ teamMembers.name }}</h4>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Testimonial from "Components/Widgets/Testimonial";
import TestimonialData from "Assets/data/TestimonialData";

export default {
  computed: {
    ...mapGetters(["rtlLayout"]),
  },
  components: {
    embTestimonial: Testimonial,
  },
  data() {
    return {
      TestimonialData,
      valid: false,
      dialog: false,
      contactnumber: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
        mobilenumber: [(v) => !!v || "Mobile number is mandatory"],
      },
      //   TestimonialData,
      /**
       * Slick Options
       **/
      slickOptions: {
        autoplay: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        swipe: true,
        seed: 100,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 390,
          },
        ],
      },
      key_features: [
        {
          title: "Our Approach",
          pointers: ["Go from local to National", "Product Expansion"],
        },
        {
          title: "Price Discovery",
          pointers: [
            "Role Insights ",
            "Transparency",
            "Price Trends",
            "Competitor Pricing",
          ],
        },
        {
          title: "Credit",
          pointers: [
            "Rate Insight",
            "Transparency",
            "Price Trends",
            "Competitor Pricing",
          ],
        },
        {
          title: "Minimum Overheads",
          pointers: [
            "Warehousing",
            "StockManagement",
            "Dispatches",
            "Quality Control",
            "Collections",
          ],
        },
        {
          title: "Logistics",
          pointers: [
            "First Mile",
            "Last Mile",
            "Transit Insurance",
            "Quality Control",
            "Collections",
          ],
        },
        {
          title: "Data Analysis",
          pointers: [
            "Price Trends",
            "Commodity News",
            "Buying Patterns",
            "Predictive Analysis",
          ],
        },
      ],
      teamListing: [
        
      ],
      vendor_process: [
        {
          image:
            "/static/images/becomesupplier/vendor-process/CreateAccount.png",
          name: "Create Account",
        },
        {
          image:
            "/static/images/becomesupplier/vendor-process/ChooseProducts.png",
          name: "Choose Products",
        },
        {
          image: "/static/images/becomesupplier/vendor-process/SetPrice.png",
          name: "Set Price",
        },
        {
          image:
            "/static/images/becomesupplier/vendor-process/GetBids-Orders.png",
          name: "Get Bids-Orders",
        },
        {
          image: "/static/images/becomesupplier/vendor-process/GetPayment.png",
          name: "Get Payment",
        },
        {
          image: "/static/images/becomesupplier/vendor-process/Logistics.png",
          name: "Logistics",
        },
      ],
      features: [
        {
          image: "/static/images/becomesupplier/6moreCategories.png",
          name: "6+ more Categories",
        },
        {
          image: "/static/images/becomesupplier/1CroreBuyers.png",
          name: "1 Crore Buyers",
        },
        {
          image: "/static/images/becomesupplier/Products.png",
          name: "10,000 Products",
        },
        {
          image: "/static/images/becomesupplier/AverageProduct.png",
          name: "50,000 Average Product",
        },
      ],
    };
  },
  methods: {
    saveDetails() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.page-title-bar2 {
  background: #fff;
}
.start-selling {
  background-image: url("/static/images/becomesupplier/bg.png");
  background-size: cover;
  background-position: center;
}
.page-title-bar:before {
  display: none;
}
.gradient-bg {
  background: linear-gradient(90deg, #86cf03, #086d35);
  color: #fff !important;
  align-self: stretch;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: auto;
  min-height: 38px;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}
.v-card .v-icon {
  color: #c3f3a3;
  font-size: 13px;
}
.v-card:not(.on-hover) {
  opacity: 1;
}
.v-card.on-hover {
  background: #edffe1;
  box-shadow: 0px 3px 20px 5px rgba(0, 0, 0, 0.16) !important;
  z-index: 9;
}
@media (max-width: 600px) {
  .page-title-bar2 {
    background-position: 45% !important;
  }
}
</style>
<template>
 <div>
  <div class="headerV2-wrap">
   <div class="header-wrap">
    <!-- <div class="top-header-wrap d-block primary">
     <div class="top-header primary">
      <v-container grid-list-xl>
       <v-layout row wrap align-center justify-end pa-0 ma-0 white--text>
        <v-flex
         xs6
         sm4
         md12
         lg6
         xl6
         px-0
         text-md-left
         text-center
         py-2
         v-if="false">
         <emb-lang v-if="false"></emb-lang>
        </v-flex>

        <v-flex
         xs12
         sm12
         md12
         lg5
         xl5
         px-0
         shrink
         align-self-center="true"
         class="text-right py-2 d-flex flex-row mb-0">
         <v-row align="center" justify="end" no-gutters>
          <template v-if="showLang">
           <v-col class="text-right"> Language:</v-col>
           <v-col md="4" class="">
            <emb-lang v-if="false"></emb-lang>
           </v-col>
          </template>
          <v-col md="6" cols="auto" v-if="!this.LoginSuccess">
           <v-hover>
            <a
             href="https://shop.tradebridge.co.in/login"
             class="white--text mr-2"
             ><v-icon small class="white--text"> mdi-account</v-icon> Login</a
            >
           </v-hover>
           |
           <v-hover>
            <a
             href="https://shop.tradebridge.co.in/create-account"
             class="white--text ml-2"
             ><v-icon small class="white--text"> mdi-login</v-icon> Register for
             Free</a
            >
           </v-hover>
          </v-col>

          <v-col md="12" cols="12" v-else>
           <router-link to="/account/profile">
            <span class="color-white"
             >{{ this.UserName | truncate(12, "...") }}
            </span> </router-link
           >|
           <emb-user-block :data="userLinks"></emb-user-block>
           <v-btn
            class="green darken-1 white--text text-none"
            href="https://shop.tradebridge.co.in/create-account"
            color="primary"
            elevation="0"
            v-if="
             becomeSupplier &&
             (!becomeSupplier.isSeller || !becomeSupplier.isSellerRegistered)
            "
            >Become a Supplier</v-btn
           >
          </v-col>
         </v-row>
        </v-flex>
       </v-layout>
      </v-container>
     </div>
    </div> -->
    <div class="bottom-header">
     <v-container>
      <div class="bottom-header-inner">
       <div class="menu-alignment">
        <v-layout row wrap align-center justify-space-between pa-0 ma-0>
         <v-flex xs6 sm6 md4 lg3 xl2 px-0>
          <div class="site-logo d-inline-block">
           <router-link to="/">
            <img alt="site-logo" :src="appLogo" />
           </router-link>
          </div>
         </v-flex>

         <v-flex xs6 sm6 md4 lg6 xl6 px-0>
          <emb-menu></emb-menu>
          <div class="layout justify-end responsive-menu">
           <v-btn icon dark @click="toggleMobileSidebar" class="toggle-btn">
            <i class="material-icons">menu</i>
           </v-btn>

           <div class="notifications d-flex align-items-center justify-end">
            <!-- <emb-search></emb-search> -->
            <span class="d-flex align-self-center mx-3">|</span>
            <emb-cart v-if="false"></emb-cart>
            <emb-wishlist v-if="false"></emb-wishlist>
           </div>
          </div>
         </v-flex>
         <v-flex xs6 sm6 md4 lg3 xl3 px-0 class="hide-mobile">
          <div
           class="notifications d-flex align-items-center justify-end"
           v-if="false">
           <!-- <emb-search></emb-search> -->
           <span class="d-flex align-self-center mx-3">|</span>
           <emb-cart></emb-cart>
           <emb-wishlist v-if="false"></emb-wishlist>
          </div>
          <div
           class="d-flex justify-start"
           style="max-width: 218px; float: right">
           <a :href="playStore" target="_blank" class="mr-2">
            <img src="/static/images/revised/play-store.png" />
           </a>
           <a :href="appStore" target="_blank" class="mr-2">
            <img src="/static/images/revised/app-store.png" />
           </a>
          </div>
          <emb-cart v-if="false"></emb-cart>
         </v-flex>
        </v-layout>
       </div>
      </div>
     </v-container>
    </div>
   </div>
  </div>
  <div id="fixedHeader">
   <emb-fixed-header></emb-fixed-header>
  </div>

  <v-dialog v-model="dialog" persistent max-width="600px">
   <v-card>
    <v-card-title class="text-h5 green white--text">
     Become a Supplier
    </v-card-title>
    <v-card-text class="pt-3">
     <v-form ref="becomesupply" v-model="valid">
      <v-row>
       <v-col cols="12" sm="6" md="6" class="py-0">
        <v-text-field
         label="Interested products to sell*"
         v-model="becomeSupplier.interestedProducts"
         required
         :rules="basictextRules"
         validate-on-blur></v-text-field>
       </v-col>
       <v-col cols="12" sm="6" md="6" class="py-0">
        <v-text-field
         label="Monthly Capacity (in KG)*"
         persistent-hint
         v-model="becomeSupplier.monthlyCapacity"
         required
         :rules="basictextRules"
         validate-on-blur></v-text-field>
       </v-col>
       <v-col cols="12" sm="6" md="6" class="py-0">
        <v-text-field
         label="Location*"
         persistent-hint
         v-model="becomeSupplier.location"
         required
         :rules="basictextRules"
         validate-on-blur></v-text-field>
       </v-col>
       <v-col cols="12" sm="6" md="6" class="py-0">
        <v-text-field
         label="GST Number*"
         v-model="becomeSupplier.gstNumber"
         required
         :rules="gstNumber"
         validate-on-blur></v-text-field>
       </v-col>
       <v-col cols="12" sm="6" md="6" class="py-0">
        <v-text-field
         label="PAN  Number"
         persistent-hint
         v-model="becomeSupplier.panNumber"
         required
         :rules="pancard"
         validate-on-blur></v-text-field>
       </v-col>
       <v-col cols="12">
        <small>*indicates required field</small>
       </v-col>
      </v-row>
     </v-form>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
     <v-btn color="red lighten-1 white--text" @click="dialog = false">
      Close
     </v-btn>
     <v-btn
      color="green lighten-1 white--text"
      @click="saveDetails('becomesupply')">
      Save
     </v-btn>
    </v-card-actions>
   </v-card>
  </v-dialog>
 </div>
</template>

<script>
import api from "Api";
import AppConfig from "Constants/AppConfig";
import { bus } from "../../../main";
import userAuthService from "../../../services/auth/user.service";
import initiateService from "../../../services/initiate.service";
import Cart from "./Cart";
import Menu from "./Menu";
import Wishlist from "./Wishlist";

export default {
 data() {
  return {
   LoginSuccess: false,
   UserName: "",
   Avatar: "",
   appLogo: AppConfig.appLogo,
   showLang: AppConfig.showLang,
   appStore: AppConfig.appStore,
   playStore: AppConfig.playStore,
   valid: false,
   dialog: false,
   becomeSupplier: {
    organizationId: "",
    interestedProducts: "",
    monthlyCapacity: "",
    location: "",
    gstNumber: "",
    panNumber: "",
    isSeller: false,
    isSellerRegistered: false,
   },
   userLinks: [
    {
     title: "My Orders",
     icon: "shopping_bag",
     path: "/account/order-history",
     id: "orderHistory",
    },
    {
     title: "My Bids",
     icon: "gavel",
     path: "/account/bid-history",
     id: "bidHistory",
    },
    {
     title: "My Credit",
     icon: "account_balance_wallet",
     path: "/account/credit",
     id: "credit",
    },
    {
     title: "My Wishlist",
     icon: "favorite",
     path: "/account/wishlist",
     id: "wishlist",
    },
    {
     title: "Addresses",
     icon: "location_on",
     path: "/account/address",
     id: "addresses",
    },
    {
     title: "Profile",
     icon: "account_circle",
     path: "/account/profile",
     id: "profile",
    },
   ],
   selectedCustomer: "English",
   Languages: ["English", "Hindi"],
   emailRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+/.test(v) || "E-mail must be valid",
   ],
   basictextRules: [(v) => !!v || "This field should not be empty"],
   pancard: [
    (v) => !!v || "PAN number is mandatory",
    (v) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(v) || "PAN number must be valid",
   ],
   gstNumber: [
    (v) => !!v || "This field should not be empty",
    (v) =>
     /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/.test(
      v
     ) || "GST number must be valid",
   ],
  };
 },
 components: {
  embMenu: Menu,
  embCart: Cart,
  // embLang: Lang,
  embWishlist: Wishlist,
  // embSearch: Search,
  // embUserBlock: UserBlock,
 },
 created() {
  bus.$on("isLoggedin", () => {
   this.LoginSuccess =
    userAuthService.loginsuccess || initiateService.loggedin.username;
   this.UserName =
    userAuthService.loggedin.username || initiateService.loggedin.username;
   this.Avatar =
    userAuthService.loggedin.avatar || initiateService.loggedin.avatar;
  });
  bus.$on("LoggedOut", () => {
   localStorage.clear();
   this.LoginSuccess = false;
   this.UserName = "";
   this.Avatar = "";
  });
 },
 mounted() {
  this.fixedHeader();
  initiateService.init(true);
  this.LoginSuccess = initiateService.isloggedin;
  this.UserName = initiateService.loggedin.username;
  this.Avatar = initiateService.loggedin.avatar;
  this.appStore = AppConfig.appStore;
  this.playStore = AppConfig.playStore;
  this.fetchdata();
 },
 watch: {},
 methods: {
  async isPANInvalid() {
   let panNumber = this.becomeSupplier.panNumber;

   if (!panNumber) {
    this.$snotify.error("PAN number is required", {
     showProgressBar: false,
    });
    return true;
   }
   return false;
  },
  async isGSTInvalid() {
   let gstNumber = this.becomeSupplier.gstNumber;

   if (!gstNumber) {
    return true;
   }
   return false;
  },
  toggleMobileSidebar() {
   this.$store.dispatch("toggleSidebar", true);
  },
  fixedHeader() {
   var fixedHeader = document.getElementById("fixedHeader");
   window.onscroll = function () {
    if (window.pageYOffset > 100) {
     fixedHeader.style.opacity = "1";
     fixedHeader.style.visibility = "visible";
     fixedHeader.style.translate = "translateY(0)";
    } else {
     fixedHeader.style.opacity = "0";
     fixedHeader.style.visibility = "hidden";
     fixedHeader.style.translate = "translateY(-200px)";
    }
   };
  },
  async saveDetails(formname) {
   if (this.$refs[formname].validate()) {
    if ((await this.isPANInvalid()) || (await this.isGSTInvalid())) {
     return true;
    }
    try {
     await api.patch(
      process.env.VUE_APP_BASE_API +
       "/users/" +
       initiateService.loggedin.userid,
      {
       interestedProducts: this.becomeSupplier.interestedProducts,
       monthlyCapacity: this.becomeSupplier.monthlyCapacity,
       location: this.becomeSupplier.location,
       isSellerRegistered: true,
       organization: {
        id: this.becomeSupplier.organizationId,
        gstNumber: this.becomeSupplier.gstNumber,
        panNumber: this.becomeSupplier.panNumber,
       },
      }
     );
     this.$snotify.success("Application successfully submited!", {
      showProgressBar: false,
      timeout: 5000,
     });
    } catch (error) {
     this.$snotify.error(error, {
      showProgressBar: false,
      timeout: 5000,
     });
     return false;
    } finally {
     this.dialog = false;
    }
   }
  },
  async fetchdata() {
   const organization = await initiateService.checkPanNumber();

   this.becomeSupplier.organizationId = organization.id;
   this.becomeSupplier.panNumber = organization.panNumber;
   this.becomeSupplier.gstNumber = organization.gstNumber;
   this.becomeSupplier.isSeller = organization.isSeller;
   this.becomeSupplier.isSellerRegistered = organization.isSellerRegistered;
  },
 },
};
</script>

<template>
  <div class="wishlist-container">
    <div class="order-history-wrap emb-card pa-4">
      <h4 class="mb-4">Wishlist</h4>
      <div v-if="wishlists.length === 0 && page === 1" class="text-center">
        <div class="text-center empty">
          <div class="mb-6 md-display-3">
            <v-icon color="red ">favorite</v-icon>
          </div>
          <h4>Your Wishlist is empty</h4>
          <router-link class="primary--text" to="/">Go Shopping</router-link>
        </div>
      </div>
      <v-row v-else class="card-container">
        <v-col
          cols="6"
          sm="6"
          xs="6"
          md="4"
          v-for="(wishlist, index) in wishlists"
          :key="wishlist.id"
        >
          <v-card @click="openProduct(wishlist)">
            <v-img
              :src="
                baseApiURL +
                '/products-images/img/' +
                wishlist.product.id +
                '/md'
              "
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn
                  color="red"
                  icon
                  @click="removeFromFavorite(wishlist, index, $event)"
                  :disabled="loading"
                >
                  <v-icon>favorite</v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-text>
              <div class="font-weight-bold ml-0 mb-0">
                {{ wishlist.product.name }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from "Api";

import initiateService from "../../services/initiate.service";
import cartService from "../../services/cart/cart.service";

export default {
  data() {
    return {
      loading: false,
      wishlists: [],
      page: 0,
      userId: 0,
      baseApiURL: "",
    };
  },
  mounted() {
    this.baseApiURL = process.env.VUE_APP_BASE_API;
    this.getWishlist();
  },
  methods: {
    async getWishlist() {
      if (!this.userId) {
        this.userId = initiateService.loggedin.userid;
      }
      try {
        this.loading = true;
        const response = await api.get(
          process.env.VUE_APP_BASE_API + "/favourites/" + this.userId
        );
        this.page++;
        this.wishlists = response.data;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    async removeFromFavorite(wishlist, index, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (wishlist.product.id) {
        try {
          this.loading = true;
          const isFavorite = await cartService.doFavourite(
            wishlist.product.id,
            this.userId
          );
          if (!isFavorite) {
            // remove from array
            this.wishlists.splice(index, 1);
          }
        } catch (error) {
          throw error;
        } finally {
          this.loading = false;
        }
      }
    },
    openProduct(wishlist) {
      this.$router.push(
        "/product/product-detail/" +
          wishlist.product.id +
          "/" +
          this.slugify(wishlist.product.name)
      );
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.wishlist-container .empty i {
  font-size: 10em;
}
</style>
import api from "Api";

import userAuthService from './auth/user.service';
import { bus } from "../main";

export default {
    isloggedin: false,
    cartquntity: 0,
    loggedin: {
        userid: '',
        avatar: '',
        accessToken: '',
        username: '',
        useremailId: '',
        phonenumber: '',
        addressid: '',
        deliveryid: '',
        creditAvailableAmount: 0,

    },
    showDeliveryOptions: false,
    pincode: null,
    total: 0,
    taxedAmount: 0,
    shippingCharges: 0,
    grandTotal: 0,
    orderId: '',
    cartitem: [],
    orderData: {},

    async fetchSetting() {
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/settings');
            this.showDeliveryOptions = response.data[0].showDeliveryOptions;
            return response.data;
        } catch (error) {
            return error;
        }
    },
    init(ignoreCartCount) {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'))
            this.loggedin.userid = user.id;
            this.loggedin.avatar = user.avatar;
            this.loggedin.accessToken = user.accessToken;
            this.loggedin.username = user.fullName;
            this.loggedin.useremailId = user.emailAddress;
            this.loggedin.phonenumber = user.mobileNumber;
            this.loggedin.creditAvailableAmount = user.creditAvailableAmount;
            this.isloggedin = true;
            if (!ignoreCartCount) {
                this.getCartCount(this.loggedin.userid);
            }
            return true;
        } else {
            return false;
        }
    },
    async logout() {
        localStorage.clear();
        userAuthService.loginsuccess = false;
        userAuthService.loggedin.userid = '';
        this.cartquntity = 0;
        this.isloggedin = false;
        this.loggedin.userid = '';
        this.loggedin.userid = '';
        this.loggedin.avatar = '';
        this.loggedin.accessToken = '';
        this.loggedin.username = '';
        this.loggedin.useremailId = '';
        this.loggedin.phonenumber = '';
        this.loggedin.addressid = '';
        this.loggedin.deliveryid = '';
        this.total = 0;
        this.taxedAmount = 0;
        this.shippingCharges = 0;
        this.grandTotal = 0;
        this.orderId = '';
        this.cartitem = [];
        this.orderData = {};
        bus.$emit('isLoggedin');
        return true
    },
    async checkPanNumber() {
        if (!this.loggedin.userid) {
            return 0;
        }
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/users/' + this.loggedin.userid);
            if (response.data.organization) {
                response.data.organization['isSellerRegistered'] = response.data.isSellerRegistered;
                response.data.organization['isSeller'] = response.data.isSeller;
            }
            return response.data.organization;
        } catch (error) {
            return error;
        }
    },
    async getCartCount(userid) {
        if (!userid) {
            return 0;
        }
        try {
            const response = await api.get(process.env.VUE_APP_BASE_API + '/orders/getCartCount/' + userid, {});
            if (response.data) {
                this.cartquntity = response.data;
            }
        } catch (error) {
            this.cartquntity = 0;
        } finally {
            bus.$emit('cartQuantityUpdated');
        }
    },
    async syncmycart(userid) {
        try {
            if (!userid) {
                const user = JSON.parse(localStorage.getItem('user'))
                userid = user.id;
            }


            const response = await api.get(process.env.VUE_APP_BASE_API + '/orders/info/' + userid, {});
            if (response != null) {
                this.orderId = response.data.id;
                this.cartquntity = response.data.totalQuantity;
                this.cartitem = response.data.ordersProduct;
                this.total = response.data.grandTotal
                this.taxedAmount = response.data.taxedAmount;
                this.shippingCharges = response.data.shippingCharges;
                this.grandTotal = response.data.grandTotal;
                this.loggedin.addressid = response.data.addressId;
                this.loggedin.deliveryid = response.data.shippingId;
            } else {

                this.cartquntity = 0;
            }
            return response.data;
        } catch (error) {
            return error;
        }
    },
    async getOrderDetail(orderId) {
        try {
            if (orderId) {
                const response = await api.get(process.env.VUE_APP_BASE_API + '/orders/' + orderId, {})
                this.orderData = response.data
                return this.orderData;
            }
        } catch (error) {
            this.cartquntity = 0;
        } finally {
            bus.$emit('cartQuantityUpdated');
        }
    }
}
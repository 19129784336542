<template>
  <div class="emb-termCondition-wrap">
    <emb-page-title
      heading="Terms and Conditions"
      subHeading=""
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    >
    </emb-page-title>
    <div class="emb-tnc section-gap">
      <div class="tnc-content text-justify">
        <v-container>
          <div class="sec-title">
            <h2>TERMS AND CONDITIONS OF USE ("TERMS")</h2>
          </div>
          <p>
            <strong
              >Please read these Terms carefully before using this website &
              mobile application (&ldquo;Website&rdquo; & &ldquo;Mobile
              Application&rdquo;). By using this Website & Mobile Application,
              you acknowledge that you have read, understood and agreed to be
              bound by the Terms contained on this page, which govern
              Orchestrade Technologies Pvt Ltd&rsquo;s (hereinafter referred to
              as &ldquo;OTPL&rdquo; and &ldquo;TradeBridge&rdquo;) relationship
              with you in relation to this Website & Mobile Application. OTPL
              may revise, and update these Terms from time to time. Your
              continued usage of the Website & Mobile Application is considered
              as an implied acceptance of the changes and that you agree to
              abide by the changed Terms.
            </strong>
          </p>
          <p>
            In these Terms, references to "you", "your", "User(s)", "they&rdquo;
            shall mean the end user (including its successors and permitted
            assigns) accessing the Website & Mobile Application and its
            contents; "we", "us" and "our" shall mean
            <b>Orchestrade Technologies Pvt Ltd</b> and its affiliates/
            associates; and "party, "parties" shall mean you and/or
            <b>OTPL</b> as the context may require.
          </p>
          <h4 class="pt-3 mb-2">INTRODUCTION</h4>
          <p>
            Use of the Website & Mobile Application is offered to you
            conditioned on acceptance without modification of all the terms,
            conditions and notices contained in these Terms, as may be posted on
            the Mobile Application from time to time. OTPL at its sole
            discretion reserves the right not to accept a User from registering
            on the Website & `without assigning any reason thereof.
          </p>
          <h4 class="pt-3 mb-2">USER ACCOUNT, PASSWORD, AND SECURITY</h4>
          <p>
            Upon downloading the Mobile Application or registering as a User on
            the Website, you will be required to enter your official email ID
            and password for log-in. You are responsible for maintaining the
            confidentiality of your account and are fully responsible for all
            activities that occur under your account. You agree to (a)
            immediately notify OTPL of any unauthorized use of your password or
            account or any other breach of security and (b) ensure that you exit
            from your account at the end of each session. It may be noted that
            this Website & Mobile Application once opened will be active in the
            background and will only stop upon log out or device switch-off.
            OTPL will not be liable for any loss or damage arising from your
            failure to comply with this clause.
          </p>
          <h4 class="pt-3 mb-2">PRIVACY POLICY</h4>
          <p>
            The User hereby consents, expresses and agrees that he has read and
            fully understands the Privacy Policy of OTPL. The User further
            consents that the terms and contents of such Privacy Policy are
            acceptable to him.
          </p>
          <h4 class="pt-3 mb-2">CHANNEL SUBSCRIPTION</h4>
          <p>
            By providing your data, you agree to be communicated by us via email
            to facilitate your navigation of the Website & Mobile Application.
            We may communicate updates and uploads periodically and as and when
            required.
          </p>
          <h4 class="pt-3 mb-2">USER CONDUCT AND RULES</h4>
          <p>
            You will not, nor will allow third parties acting on your behalf to
            (i) make and distribute copies of the Website & Mobile Application
            (ii) attempt to copy, reproduce, alter, modify, reverse engineer,
            disassemble, decompile, transfer, exchange or translate the Website
            & Mobile Application; or (iii) create derivative works of the
            Website & Mobile Application of any kind whatsoever.
          </p>
          <p>
            You acknowledge and agree that the terms of agreement with your
            respective mobile network provider/Internet Service Provider will
            continue to apply when using the Website & Mobile Application. As a
            result, you may be charged by the mobile network provider/ISP for
            access to the network connection services for the duration of the
            connection while accessing the Mobile Application or any such
            third-party charges as may arise. OTPL specifically denies any
            liability for such charges.
          </p>
          <p>
            You agree and undertake to use the Website & Mobile Application only
            for purposes and post and upload data/ information/ material that
            are permitted and are proper. By way of example, and not as a
            limitation, you agree and undertake that when using the Website &
            Mobile Application, you will not:
          </p>
          <ul>
            <li>
              Host, display, upload, modify, publish, transmit, disseminate,
              update, or share any data/ information/ material/ files that-
              <ul>
                <li>
                  belongs to another person and to which you do not have any
                  right;
                </li>
                <li>
                  is grossly harmful, harassing, inappropriate, blasphemous,
                  defamatory, obscene, pornographic, paedophilic, libellous,
                  invasive of another's privacy, hateful, or racially,
                  ethnically objectionable, disparaging, relating or encouraging
                  money laundering or gambling, or otherwise unlawful in any
                  manner whatever;
                </li>
                <li>harms minors in any way;</li>
                <li>
                  infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li>
                  deceives or misleads the addressee about the origin of such
                  messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </li>
                <li>impersonates another person;</li>
                <li>
                  contains software viruses, corrupt files or any other computer
                  code, files or programs designed to interrupt, destroy or
                  limit the operation of the Website & Mobile Application;
                </li>
                <li>
                  threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognisable offence or prevents investigation of any offence or
                  is insulting any other nation.
                </li>
              </ul>
            </li>
            <li>
              defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights of others;
            </li>
            <li>
              conduct or forward polls, contests or photographs posted on the
              Website & Mobile Application;
            </li>
            <li>
              download any file posted by another user that you know, or
              reasonably should know, cannot be legally distributed in such
              manner;
            </li>
            <li>
              falsify or delete any author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or
              source of software or other material contained in a file that is
              uploaded;
            </li>
            <li>
              violate any code of conduct or other guidelines, which is
              applicable to You;
            </li>
            <li>
              violate any applicable laws or regulations for the time being in
              force in or outside India;
            </li>
            <li>
              violate any of the terms and conditions of these Terms or any
              other terms and conditions for the Website & Mobile Application
              contained elsewhere herein; and
            </li>
            <li>
              make any derogatory, defamatory, abusive, inappropriate, profane
              or indecent statement(s) and/or comment(s) about OTPL and/or its
              affiliates/ associates.
            </li>
          </ul>
          <h4 class="pt-3 mb-2">USER WARRANTY AND REPRESENTATION</h4>
          <p>
            You guarantee, warrant, and certify that you are the owner of the
            content which you submit or are otherwise authorized to use the
            content and that the content does not infringe upon the property
            rights, intellectual property rights or other rights of others. You
            further warrant that to your knowledge, no action, suit, proceeding,
            or investigation has been instituted or threatened relating to any
            content, including trademark, trade name, service mark, and
            copyright formerly or currently used by you in connection with the
            Website & Mobile Application.
          </p>
          <h4 class="pt-3 mb-2">MEMBERSHIP ELIGIBILITY</h4>
          <p>
            Use of the Website & Mobile Application is available only to persons
            who can form legally binding contracts under Indian Contract Act,
            1872. Persons who are "incompetent to contract" within the meaning
            of the Indian Contract Act, 1872 including minors, un-discharged
            insolvents etc. are not eligible to use the Website & Mobile
            Application. OTPL reserves the right to terminate your membership
            and / or refuse to provide you with access to the Website & Mobile
            Application if it is brought to OTPL&rsquo;s notice or if it is
            discovered that you are under the age of 18 years.
          </p>
          <h4 class="pt-3 mb-2">INTELLECTUAL PROPERTY RIGHTS</h4>
          <ol>
            <li>
              Unless otherwise indicated or anything contained to the contrary
              or any proprietary material owned by a third party and so
              expressly mentioned, OTPL owns all Intellectual Property Rights to
              and into the Website & Mobile Application, including, without
              limitation, any and all rights, title and interest in and to
              copyright, related rights, patents, utility models, trademarks,
              trade names, service marks, designs, know-how, trade secrets and
              inventions (whether patentable or not), goodwill, source code,
              meta tags, databases, text, content, graphics, icons, and
              hyperlinks. You acknowledge and agree that you shall not use,
              reproduce or distribute any content from the Website & Mobile
              Application belonging to OTPL.
            </li>
            <li>
              Notwithstanding the foregoing, it is expressly clarified that you
              will retain ownership and shall solely be responsible for any
              content that you provide or upload when using the Website & Mobile
              Application, including any text, data, information, images,
              photographs or any other material which you may upload, transmit
              or store when making use of the Website & Mobile Application.
            </li>
          </ol>

          <h4 class="pt-3 mb-2">LINKS TO THIRD PARTY SITES:</h4>
          <p>
            The Website & Mobile Application may contain links to other websites
            ("Linked Sites"). The Linked Sites are not under the control of OTPL
            and OTPL is not responsible for the contents, services or products
            of any Linked Site, including without limitation any link contained
            in a Linked Site, or any changes or updates to a Linked Site. OTPL
            is not responsible for any form of transmission, whatsoever,
            received by you from any Linked Site. The inclusion of any link does
            not imply endorsement by OTPL of the Linked Sites or any association
            with its operators or owners including the legal heirs or assigns
            thereof. OTPL has no specific prior review process or editorial
            control over third party content. The Users are requested to verify
            the accuracy of all information on their own before undertaking any
            reliance on such third-party information appearing on the Linked
            Sites or otherwise.
          </p>
          <h4 class="pt-3 mb-2">MONITORING:</h4>
          <p>
            OTPL reserves the right, but not the obligation, to monitor the
            Website & Mobile Application to determine compliance with the terms
            of this Website & Mobile Application and any rules/ policies
            established by OTPL to satisfy any law or regulation.
          </p>
          <p>
            The Users of the Mobile Application are expected to update the
            Mobile Application on regular basis, so that he/she is duly aware of
            any change(s) in Terms. In the event of failure to update the Mobile
            Application, as and when available, the Users understands and agrees
            that they agree to the updated Terms by virtue of continued use of
            the Mobile Application and that OTPL shall not be liable in any
            manner whatsoever for such failure of the User to update the Mobile
            Application.
          </p>
          <h4 class="pt-3 mb-2">GENERAL TERMS AND CONDITIONS:</h4>
          <ol>
            <li>
              You are responsible for remaining knowledgeable about the Terms as
              updated from time to time.
            </li>
            <li>
              You agree to be communicated by us via email to facilitate your
              navigation of and use of the Website & Mobile Application.
            </li>
            <li>
              We shall not be liable or responsible for correspondence lost or
              delay in the email.
            </li>
            <li>
              You represent and warrant that all information placed by you in
              your profile, including but not limited to name, address, mobile
              number and email addresses are correct.
            </li>
            <li>
              We reserve the right to add, modify, delete or otherwise change
              the Terms without any approval, prior notice or reference to any
              User.
            </li>
            <li>
              In the event of dispute in connection with the interpretation of
              the Terms, our decision shall be final and binding.
            </li>
            <li>
              These Terms shall be read in conjunction with the other policies
              of OTPL included herein by reference or otherwise.
            </li>
          </ol>
          <h4 class="pt-3 mb-2">INDEMNIFICATION:</h4>
          <p>
            You agree to indemnify, defend and hold harmless OTPL and all
            affiliates/ associates from and against any and all losses,
            liabilities, claims, damages, costs and expenses (including legal
            fees and disbursements in connection therewith and interest
            chargeable thereon) asserted against or incurred by OTPL and all
            affiliates/ associates that arise out of, result from, or may be
            payable by virtue of, any breach or non-performance of any
            representation, warranty, covenant or agreement made or obligation
            to be performed by you pursuant to these Terms.
          </p>
          <h4 class="pt-3 mb-2">TERMINATION:</h4>
          <ol>
            <li>
              OTPL may suspend or terminate your use of the Website & Mobile
              Application, in its sole and absolute discretion that you have
              breached any of the Terms.
            </li>
            <li>
              If you or OTPL terminates your use of the Website & Mobile
              Application, OTPL may delete any content or other materials
              relating to your use of the Website & Mobile Application and OTPL
              and all affiliates/ associates will have no liability to you or
              any third party for doing so.
            </li>
          </ol>
          <h4 class="pt-3 mb-2">GOVERNING LAW:</h4>
          <p>
            These terms shall be governed by and constructed in accordance with
            the laws of India without reference to conflict of laws principles
            and disputes arising in relation hereto shall be subject to the
            exclusive jurisdiction of the courts at Navi Mumbai, India.
          </p>
          <h4 class="pt-3 mb-2">HEADINGS:</h4>
          <p>
            The headings and subheadings herein are included for convenience and
            identification only and are not intended to describe, interpret,
            define or limit the scope, extent or intent of the Terms or the
            right to use the Website & Mobile Application by you contained
            herein or any other section or pages of the Website & Mobile
            Application or any Linked Sites in any manner whatsoever.
          </p>
          <h4 class="pt-3 mb-2">INTERPRETATION OF NUMBER AND GENDERS:</h4>
          <p>
            The Terms herein shall apply equally to both the singular and plural
            form of the terms defined. Whenever the context may require, any
            pronoun shall include the corresponding masculine and feminine. The
            words "include", "includes" and "including" shall be deemed to be
            followed by the phrase "without limitation". Unless the context
            otherwise requires, the terms "herein", "hereof", "hereto",
            "hereunder" and words of similar import refer to the Terms as a
            whole.
          </p>
          <h4 class="pt-3 mb-2">SEVERABILITY:</h4>
          <p>
            If any provision of the Terms is determined to be void, unlawful or
            otherwise in whole or in part, such invalidity or unenforceability
            shall attach only to such provision or part of such provision and
            the remaining part of such provision and all other provisions of
            these Terms shall continue to be in full force and effect.
          </p>
          <h4 class="pt-3 mb-2">NO AGENCY OR PARTNERSHIP:</h4>
          <p>
            OTPL and all affiliates/ associates and you are independent
            contractors. Neither party may direct or control the day-to-day
            activities of the other; neither party may create or assume any
            legal obligation on behalf of the other.
          </p>
          <h4 class="pt-3 mb-2">NO WAIVER</h4>
          <p>
            A delay or omission by OTPL to exercise any right or power under
            these Terms will not be construed to be a waiver thereof. A waiver
            by OTPL of any of the covenants to be performed by you or any breach
            thereof will not be construed to be a waiver of any succeeding
            breach thereof or of any other covenant under these Terms.
          </p>
          <h4 class="pt-3 mb-2">NOTICES:</h4>
          <p>We may provide notice to User via official email.</p>
          <h4 class="pt-3 mb-2">NO THIRD PARTY BENEFICIARIES:</h4>
          <p>
            User acknowledges and agrees that these Terms shall in no event be
            construed as a third party beneficiary contract, and that it is not
            intended for the benefit of any person or company whomsoever except
            the parties hereto.
          </p>
          <h4 class="pt-3 mb-2">ASSIGNMENT:</h4>
          <p>
            OTPL and all affiliates/ associates may assign any or all of its
            rights and obligations hereunder to any party without the prior
            consent of or notice to User.
          </p>
          <div class="sec-title">
            <h4 class="pt-3 mb-2">Conditions of Sale&nbsp;</h4>
          </div>
          <p>
            <strong
              >Please read these conditions carefully before placing an order
              for any products. These conditions signify your agreement to be
              bound by these conditions.<br /> </strong
            >In addition, when you use any current or future service will also
            be subject to the terms, guidelines and conditions applicable to
            that service ("Terms"). If these Conditions of Sale are inconsistent
            with such Terms, the Terms will control.
          </p>
          <h4 class="pt-3 mb-2">Our Contract</h4>
          <p>
            Your order is an offer to us to buy the product(s). When you place
            an order to purchase a product from us, you will receive a
            notification confirming receipt of your order and containing the
            details of your order (the "Order Confirmation"). The Order
            Confirmation acknowledgement that we have received your order, and
            does not confirm acceptance of your offer to buy the product(s)
            ordered. We only accept your offer, and conclude the contract of
            sale for a product ordered by you, when the product is dispatched to
            you and a confirmation is sent to you that the product has been
            dispatched to you. If your order is dispatched in more than one
            instance, you may receive a separate Dispatch Confirmation for each
            instance, and each Dispatch Confirmation and corresponding dispatch
            will conclude a separate contract of sale between you and us for the
            product(s) specified in that Dispatch Confirmation.
          </p>
          <p>
            You can cancel your order for a product at no cost any time before
            the payment for the product(s) has been received. In case payment
            has been received and you wish to cancel the order, OTPL, at its
            discretion, can levy a cancellation charge of upto 10% of the total
            order value. The balance will be credited to your TradeBridge wallet
            and can be used for future trades on the platform.&nbsp;
          </p>
          <h4 class="pt-3 mb-2">Pricing and availability</h4>
          <p>
            We list availability information for products sold by us on the
            Website & Mobile Application. Beyond what we say in relation to the
            availability on the Website & Mobile Application, we cannot be more
            specific about availability. As we process your order, you will be
            informed if any products you order turn out to be unavailable.
            Please note that dispatch estimates are just that. They are not
            guaranteed dispatch times and should not be relied upon as such.
          </p>
          <p>
            All prices are exclusive of VAT/CST, service tax, Goods and Services
            Tax ("GST"), duties and cesses as applicable - unless stated
            otherwise.
          </p>
          <p>
            Items in your Shopping Cart will always reflect the most recent
            price displayed on the item's product detail page. All prices are
            listed in Rupees.
          </p>
          <p>
            "Price", as displayed, is exclusive of all taxes at the appropriate
            rate. Items in your Shopping Cart will always reflect the most
            recent price displayed on the item's product detail page. Please
            note that this price may differ from the price shown for the item
            when you first placed it in your cart. Placing an item in your cart
            does not reserve the price shown at that time.
          </p>
          <p>
            If you are a business customer shopping on Website or Mobile
            Application, the Price (mentioned above) may mean the "Business
            Price", if a discounted price is available to you for the product
            which shall be exclusive of all taxes at the appropriate rate. For
            the bulk purchases being made by you, the Price may mean the
            "Quantity Price" which is exclusive of any discounts on the products
            purchased by you.
          </p>
          <p>
            OTPL does not offer price matching for any items sold by any seller.
          </p>
          <h4 class="pt-3 mb-2">Taxes</h4>
          <p>
            You shall be responsible for payment of all fees/costs/charges
            associated with the purchase of products from us and you agree to
            bear any and all applicable taxes including but not limited to
            VAT/CST, service tax, GST, duties and cesses etc.
          </p>
          <h4 class="pt-3 mb-2">Returns and Replacement</h4>
          <p>
            Products cannot be returned and only be replaced within the
            applicable window if you&rsquo;ve received them in a condition that
            is physically damaged and is verified by a TradeBridge
            representative.
          </p>
          <h4 class="pt-3 mb-2">Replacement will be processed only if:</h4>
          <ol>
            <li>
              It is determined that the product was not damaged while in your
              possession.
            </li>
            <li>The product is not different from what was shipped to you</li>
            <li>
              The product is returned in original quantity, condition and with
              original packaging.
            </li>
          </ol>
          <h4 class="pt-3 mb-2">Losses</h4>
          <p>
            We will not be responsible for any business loss (including loss of
            profits, revenue, contracts, anticipated savings, data, goodwill or
            wasted expenditure) or any other indirect or consequential loss that
            is not reasonably foreseeable to both you and us when a contract for
            the sale of goods by us to you was formed.
          </p>
          <h4 class="pt-3 mb-2">Alteration or Amendments to the Conditions</h4>
          <p>
            We reserve the right to make changes to our policies, and these
            Conditions of Sale at any time. You will be subject to the policies
            and Conditions of Sale in force at the time you order goods from us,
            unless any change to those policies or these conditions is required
            to be made by law or government authority (in which case it will
            apply to orders previously placed by you). If any of these
            conditions is deemed invalid, void, or for any reason unenforceable,
            that condition will be deemed severable and will not affect the
            validity and enforceability of any remaining condition.
          </p>
          <h4 class="pt-3 mb-2">Events beyond our reasonable control</h4>
          <p>
            We will not be held responsible for any delay or failure to comply
            with our obligations under these conditions if the delay or failure
            arises from any cause which is beyond our reasonable control. This
            condition does not affect your statutory rights.
          </p>
          <h4 class="pt-3 mb-2">Waiver</h4>
          <p>
            If you breach these conditions and we take no action, we will still
            be entitled to use our rights and remedies in any other situation
            where you breach these conditions.
          </p>
          <h4 class="pt-3 mb-2">Governing Law and Jurisdiction</h4>
          <p>
            These conditions are governed by and construed in accordance with
            the laws of India, and the application of the United Nations
            Convention on Contracts for the International Sale of Goods is
            expressly excluded. You agree, as we do, to submit to the exclusive
            jurisdiction of the courts at New Mumbai.
          </p>
          <h4 class="pt-3 mb-2">QUESTIONS AND GRIEVANCES</h4>
          <p>
            If you have any questions or grievances about our privacy practices,
            please feel free to contact us at:- <br /><br />
            Name: Shriwat Kadam <br />
            Address: Arihant Aura, Floor No. 18, Plot No 13/1, TTC Industrial
            Area, Thane Belapur Road, Navi Mumbai – 400706, Maharashtra,
            India<br />
            Email Id: info@tradebridge.co.in <br />
            Phone No: 8657538567
          </p>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tnc: null,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div class="emb-privacy-policy-wrap">
    <emb-page-title
      heading="Privacy Policy"
      subHeading="Your Information is fully secure with us."
      backgroundicon="https://ashapura.s3.ap-south-1.amazonaws.com/api/banner/066d29a1-f338-4e0b-83cd-db92f52912e3.jpeg"
    >
    </emb-page-title>
    <div class="section-gap bg-white text-justify">
      <v-container>
        <div class="sec-title">
          <h2>PRIVACY POLICY</h2>
        </div>
        <p>
          Capitalized terms used under this Privacy Policy and not defined
          herein shall have the same meaning as ascribed thereto under the Terms
          and Conditions of Use. Orchestrade Technologies Pvt Ltd, through its
          Web and Mobile Application (hereinafter referred to as “Application”),
          TradeBridge, is extremely proud of its commitment to protect your
          privacy. We value your trust in us. We have adopted the following
          Privacy Policy, which is applicable to all Personal Information that
          you provide to Orchestrade Technologies Pvt Ltd. through the
          Application. You acknowledge that you are disclosing Personal
          Information voluntarily. Prior to the completion of any login process
          on the Application, if you wish not to disclose any Personal
          Information you may refrain from doing so; however if you don't
          provide the Personal Information that is requested it is possible that
          the login process would be incomplete and/or you would not be able to
          access the Application. This Privacy Policy does not apply to any
          Personal Information that you may provide to third parties, such as
          through other Linked Sites. Please be aware that if you choose to
          visit such third party sites, you should contact such third parties
          directly to determine their respective privacy policies because that
          third party's privacy policy, and not this Privacy Policy, will govern
          your activities and any personally identifiable information you
          disclose while visiting the third party site. Orchestrade Technologies
          Pvt Ltd. expressly reserves at its sole discretion the right to change
          this Privacy Policy periodically in the future without prior notice to
          the Users. User shall review our Privacy Policy available at the Web
          and Mobile Application regularly to be sure you are fully updated with
          our practices and procedures in this area. Your continued use of the
          Application will be subject to the then-current Privacy Policy. The
          following discloses our information gathering and dissemination
          practices for the Application:
        </p>
        <h4>OVERVIEW</h4>
        <p>
          We respect and are committed towards protecting your privacy. As part
          of the normal operation of the Application, we collect and, in some
          cases, may disclose Personal Information about you. This Privacy
          Policy describes the Personal Information we collect about you and
          what may happen to that Personal Information. By accepting this
          Privacy Policy and our Terms, you expressly consent to our use and
          disclosure of your Personal Information in the manner prescribed in
          this Privacy Policy. This Privacy Policy is incorporated into the
          Terms and subject to the terms of the Terms, governs all use of the
          Application. While logging into the Application and use thereof from
          time to time, we may collect Personal Information through the
          Application. Your Personal Information will not be posted in areas of
          the Application viewable by the public or other users of the
          Application.
        </p>
        <h4>OUR DISCLOSURE OF YOUR PERSONAL INFORMATION</h4>
        <p>
          The Personal Information sought to be collected and the reasons for
          collecting the same are mentioned below:
        </p>
        <ul>
          <li>
            Official Email ID and password submitted by User during log-in are
            for User authentication purposes and will not be stored on App or
            Server.
          </li>
          <li>
            Mobile Device information like Device Id, operating system,
            location, IP Address language, time zone will be collected for
            authentication, security, and reporting purposes.
          </li>
          <li>
            Location data of the User may be collected at the option of the User
            for using certain services provided by the Application
          </li>
        </ul>
        <h4>OUR DISCLOSURE OF YOUR PERSONAL INFORMATION</h4>
        <p>
          Due to the existing regulatory environment, we cannot ensure that all
          your Personal Information will never be disclosed in ways not
          otherwise described in this Privacy Policy. By way of example (without
          limiting the foregoing), we may be required to disclose your Personal
          Information to the government, law enforcement agencies, or third
          parties under certain circumstances; third parties may unlawfully
          intercept or access transmissions or private communications; or users
          or authorized users may independently abuse or misuse your information
          that they collect from the Application in violation of our
          Application’s Privacy Policy and Terms of Use. Therefore, although we
          use industry standard information security practices to protect your
          privacy, we do not guarantee, and you should not expect, that your
          Personal Information will always remain private. As a matter of
          policy, we do not sell or rent any Personally Information about you to
          any third party.
        </p>
        <h4>DATA RETENTION POLICY</h4>
        <p>
          We will retain data for as long as you use the Application and it
          would continue for a reasonable time thereafter. Please note that some
          or all the provided data may be required for the Application to
          function properly. If you wish to stop us from collecting any personal
          Information or other data, you may uninstall the Application.
        </p>
        <h4>TRANSFER OF PERSONALLY IDENTIFIABLE INFORMATION</h4>
        <p>
          The Personal Information that we collect from you may be transferred
          to and stored at locations that are outside the territory of India. It
          may also be processed by staff operating outside the territory of
          India, who work for or are engaged by Orchestrade Technologies Pvt
          Ltd. Since the transmission of any information across the internet is
          not completely secure, we have implemented strong security measures
          and will do our best to protect your Personal Information. We cannot
          ensure 100% security and any transmission is at your own risk.
        </p>
        <h4>PROTECTION OF PERSONALLY IDENTIFIABLE INFORMATION</h4>
        <p>
          To protect your privacy and security, we will take reasonable steps to
          verify your identity before granting access. We will, however, retain
          in our files and databases Personal Information you have requested to
          remove in order to use it in some circumstances, such as to resolve
          disputes, troubleshoot problems, and enforce our Terms and Conditions
          of Use. Further, such prior Personal Information is never completely
          removed from our databases due to technical and legal constraints,
          including stored "back up" systems. Therefore, you should not expect
          that all of your Personal Information will be completely removed from
          our databases in response to your requests.
        </p>
        <h4>YOUR CONSENT</h4>
        <p>
          By using the Application and by providing your information, you
          consent to the collection and use of the information in accordance
          with this Policy, including your consent to share the information.
        </p>
        <h4>SECURITY</h4>
        <p>
          Orchestrade Technologies Pvt Ltd. uses reasonable security measures to
          safeguard the confidentiality of your Personal Information, such as
          firewalls, Transport Layer Security (TLS), and other physical and
          electronic security measures. However, you agree and acknowledge that
          "perfect security" does not exist on the Internet.
        </p>
        <h4>QUESTIONS AND GRIEVANCES</h4>
        <p>
          If you have any questions or grievances about our privacy practices,
          please feel free to contact us at<br />
          <strong>Orchestrade Technologies Pvt Ltd.</strong><br />
          Arihant Aura, Floor No. 18, Plot No 13/1,<br />
          TTC Industrial Area, Thane Belapur,<br />
          Road, Navi Mumbai – 400706<br />
          Maharashtra, India.<br />
          Email - marketing@tradebridge.co.in
        </p>
        <h4>GRIEVANCE OFFICER</h4>
        <p>
          In accordance with Information Technology (Reasonable Security
          Practices and Procedures and sensitive personal data or information)
          Rules, 2011 the name and contact details of Grievance Officer are
          provided below:<br />
          Name: Shriwat Kadam <br />
          Address: Navi Mumbai <br />
          Email Id: marketing@tradebridge.co.in <br />
          Phone No: 8657538567
        </p>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<template>
  <div class="d-inline-flex social-follow-icons">
    <v-btn
      plain
      x-small
      icon
      class="transparent accent--text"
      href="https://www.facebook.com/TradeBridgeB2B"
      target="_blank"
    >
      <img src="/static/images/social/f.png" class="social-links" />
    </v-btn>
    <v-btn
      plain
      x-small
      icon
      class="transparent accent--text"
      href="https://twitter.com/Tradebridge2"
      target="_blank"
    >
      <img src="/static/images/social/t.png" class="social-links" />
    </v-btn>
    <v-btn
      plain
      x-small
      icon
      class="transparent accent--text"
      href="https://www.youtube.com/channel/UCrBs01ultY5EojVNRRKNzzg"
      target="_blank"
    >
      <img src="/static/images/social/y.png" class="social-links" />
    </v-btn>
    <v-btn
      plain
      x-small
      icon
      class="transparent accent--text"
      href="https://www.instagram.com/tradebridge_b2b"
      target="_blank"
    >
      <img src="/static/images/social/i.png" class="social-links" />
    </v-btn>

    <v-btn
      plain
      x-small
      icon
      class="transparent accent--text"
      href="https://www.linkedin.com/company/tradebridgeb2b/"
      target="_blank"
    >
      <img src="/static/images/social/l.png" class="social-links" />
    </v-btn>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style scoped>
.social-follow-icons .v-btn {
  display: inline-block;
  width: 40px;
  box-shadow: none !important;
}

.v-btn__content {
  opacity: 1 !important;
}

.social-follow-icons .v-btn .social-links {
  width: auto;
  min-height: initial;
}
</style>
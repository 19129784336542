var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"emb-payment-wrap"},[_c('div',{staticClass:"emb-card mb-12 pa-3 white"},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","ma-0":""}},[_c('v-flex',{staticClass:"d-flex justify-space-between align-center",attrs:{"lg12":""}},[_c('h5',{staticClass:"m-0"},[_vm._v("Bid Details")])]),_c('v-flex',{attrs:{"md6":"","sm12":""}},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Bid Rate")]),_c('td',[_c('emb-currency-sign'),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.bidData.bidPrice))+" ")],1)]),_c('tr',[_c('th',[_vm._v("Bid Quantity")]),_c('td',[_vm._v(" "+_vm._s(_vm.bidData.bidQuantity)+" ")])]),_c('tr',[_c('th',[_vm._v("Bid Total Amount")]),_c('td',[_c('emb-currency-sign'),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.bidData.totalBidPrice))+" ")],1)]),(this.bidData.createdTimestamp)?_c('tr',[_c('th',[_vm._v("Bid Placed on")]),_c('td',[_vm._v(_vm._s(_vm.bidData.createdTimestamp))])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Bid Status")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.statusColorMap[_vm.bidData.status],"small":""}},[_vm._v(_vm._s(this.bidData.status))])],1)])])])],1),_c('v-flex',{attrs:{"md6":"","sm12":""}},[_c('v-simple-table',[_c('tbody',[(+_vm.bidData.quotedPrice)?_c('tr',[_c('th',[_vm._v("TradeBridge Proposed")]),_c('td',[_c('emb-currency-sign'),_vm._v(" "+_vm._s(_vm.bidData.quotedPrice)+" ")],1)]):_vm._e(),(+_vm.bidData.quotedTotalAmount)?_c('tr',[_c('th',[_vm._v("Proposed Total Amount")]),_c('td',[_c('emb-currency-sign'),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.bidData.quotedTotalAmount))+" ")],1)]):_vm._e(),(this.bidData.quotedTimestamp)?_c('tr',[_c('th',[_vm._v("Quoted on")]),_c('td',[_vm._v(_vm._s(_vm.bidData.quotedTimestamp))])]):_vm._e(),(
                this.bidData.statusChangedTimestamp &&
                ['Pending', 'Quoted'].indexOf(_vm.bidData.status) === -1
              )?_c('tr',[_c('th',[_vm._v("Status Changed on")]),_c('td',[_vm._v(_vm._s(_vm.bidData.statusChangedTimestamp))])]):_vm._e(),(this.bidData.orderId)?_c('tr',[_c('th',[_vm._v("Order")]),_c('td',[_c('router-link',{attrs:{"to":{
                    path: 'order-detail',
                    query: { id: _vm.bidData.orderId },
                  }}},[_vm._v("view order ")])],1)]):(_vm.bidData.status === 'Agreed')?_c('tr',[_c('th'),_c('td',[_c('router-link',{attrs:{"to":"/cart"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"}},[_vm._v("Go to Cart")])],1)],1)]):_vm._e(),(this.bidData.status === 'Quoted')?_c('tr',[_c('th',[_vm._v("Action")]),_c('td',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.updateStatus('Rejected')}}},[_vm._v(" Reject ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.updateStatus('Agreed')}}},[_vm._v(" Agree ")])],1)]):_vm._e()])])],1)],1)],1),_c('div',{staticClass:"emb-card mb-12 pa-3 white"},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","ma-0":""}},[_c('v-flex',{attrs:{"lg6":""}},[_c('v-card',[_c('v-layout',{attrs:{"row":"","align-center":"","ma-0":"","mb-3":""}},[_c('v-flex',{attrs:{"lg4":""}},[_c('router-link',{attrs:{"to":'/product/product-detail/' +
                  _vm.bidData.product.id +
                  '/' +
                  _vm.slugify(_vm.bidData.product.name)}},[(_vm.bidData && _vm.bidData.product)?_c('img',{attrs:{"src":'https://api.ashapuracommodities.com/products-images/img/' +
                    _vm.bidData.product.id +
                    '/md',"width":"80","height":"77"}}):_vm._e()])],1),_c('v-flex',{attrs:{"lg8":"","cart-product-name":"","pa-0":""}},[_c('h6',{staticClass:"mb-1 word-wrap-break"},[_c('router-link',{attrs:{"to":'/product/product-detail/' +
                    _vm.bidData.product.id +
                    '/' +
                    _vm.slugify(_vm.bidData.product.name)}},[_vm._v(" "+_vm._s(_vm.bidData.product.name)+" ")])],1),_c('p',{staticClass:"mb-0 text-small text-italic"},[_vm._v(" "+_vm._s(_vm.bidData.productsVariation.name)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Current Price:   "),_c('emb-currency-sign'),_vm._v(" "+_vm._s(+_vm.bidData.productsVariation.salePrice || _vm.bidData.productsVariation.unitPrice)+" ")],1)])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
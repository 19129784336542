<template>
  <div class="emb-contact-wrap">
    <emb-page-title
      heading="Contact Us"
      subHeading="Feel free to reach us!"
      backgroundicon="/static/images/revised/contact-us.jpg"
    >
    </emb-page-title>
    <div class="emb-contact-content">
      <div class="contact-info-wrapper">
        <v-container grid-list-xl py-0>
          <div class="section-gap">
            <v-layout row wrap>
              <v-flex sm12 md12 lg4 xl5>
                <div class="sec-title">
                  <h3 class="v-layout justify-start align-left">
                    Corporate Office
                  </h3>
                  <h6>
                    Arihant Aura, Floor No. 18, Plot No 13/1,<br />
                    TTC Industrial Area, Thane Belapur,<br />
                    Road, Navi Mumbai – 400706,<br />
                    Maharashtra, India
                  </h6>
                </div>
              </v-flex>

              <!-- <v-flex sm12 md12 lg3 xl5>
                <div class="sec-title">
                  <h3 class="v-layout justify-start align-left">
                    Sales Office
                  </h3>
                  <h6>
                    F-6, APMC Market I,<br />
                    Phase II, Sector 19, Vashi,<br />
                    Navi Mumbai – 400 705,<br />
                    Maharashtra, India
                  </h6>
                </div>
              </v-flex>

              <v-flex sm12 md12 lg3 xl5>
                <div class="sec-title">
                  <h3 class="v-layout justify-start align-left">
                    Factory Unit I
                  </h3>
                  <h6>
                    Plot A-655,<br />
                    TTC Industrial Area,<br />
                    Khairane MIDC Ramnath Solar,<br />
                    Navi Mumbai – 400710<br />
                    Maharashtra, India
                  </h6>
                </div>
              </v-flex>

              <v-flex sm12 md12 lg3 xl5>
                <div class="sec-title">
                  <h3 class="v-layout justify-start align-left">
                    Factory Unit II
                  </h3>
                  <h6>
                    Gate No./Survey No. 53/3/B/1 & 53/3/B/2,<br />
                    Revenue Village,<br />
                    Kumbhivali, Taluka Khalapur, <br />
                    Dist. Raigad – 410202<br />
                    Maharashtra, India
                  </h6>
                </div>
              </v-flex> -->
              <!-- </v-layout> -->

              <!-- <v-layout row wrap> -->
              <v-flex sm12 md12 lg4 xl5>
                <div class="sec-title">
                  <h3 class="v-layout justify-start align-left">
                    For any enquiries :
                  </h3>
                  <h6>
                    <i class="icon-mail"></i>
                    <a href="mailto:info@tradebridge.co.in"
                      >info@tradebridge.co.in</a
                    >
                  </h6>
                  <h6>
                    English/Hindi/Marathi:
                    <i class="icon-phone"></i>
                    <a href="tel:8879025374">8879025374</a>
                  </h6>
                  <h6>
                    Malayalam:
                    <i class="icon-phone"></i>
                    <a href="tel:8657935382">8657935382</a>
                  </h6>
                  <h6>
                    Tamil:
                    <i class="icon-phone"></i>
                    <a href="tel:8657928729">8657928729</a>
                  </h6>
                  <h6>
                    Telugu:
                    <i class="icon-phone"></i>
                    <a href="tel:8657921389">8657921389</a>
                  </h6>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      contactInfo: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
    };
  },
  mounted() {},
  methods: {
    saveDetails() {
      this.$refs.form.validate();
    },
  },
};
</script>

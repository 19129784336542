<template>
  <div class="emb-blog-wrap2 section-gap title-gap" style="background: #f8f8f8">
    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 sm12 md6 lg6 xl6 v-for="blog in data" :key="blog.id">
          <div class="emb-card">
            <div class="thumb-wrap">
              <img
                alt="latest from blog"
                :src="blog.thumb"
                width="100%"
                height="auto"
              />
            </div>
            <div class="emb-card-content pa-3 grey lighten-3">
              <h5 class="font-weight-medium mb-2 pt-0">{{ blog.subtitle }}</h5>

              <div class="emb-meta-info">
                <div class="d-flex justify-space-between">
                  <div class="meta-tags">
                    <v-icon>mdi-account-circle </v-icon>{{ blog.authorName }}
                  </div>
                  <div class="meta-tags">
                    <v-icon>calendar_today</v-icon> {{ blog.post_date }}
                  </div>
                </div>
              </div>
              <div class="py-2">
                <p>{{ blog.desc }}</p>
                <v-btn class="accent" :href="blog.blogurl" target="_blank"
                  >Read More</v-btn
                >
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["data", "secTitle"],
};
</script>

<template>
  <div class="">
    <v-card class="pa-10">
      <v-form ref="raiseTicket" v-model="valid">
        <v-row>
          <v-col cols="7">
            <v-textarea
              outlined
              v-model="comment"
              :rules="commentRule"
              label="Comment"
              hide-details="auto"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-file-input
              label="Attachments"
              flat
              counter
              multiple
              accept="image/*,video/*,.pdf"
              v-model="files"
              type="file"
              :show-size="1000"
              :rules="uploadlimit"
              prepend-icon="mdi-paperclip"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-btn color="primary" @click="raiseCompaint('raiseTicket')"
          >Submit Complaint</v-btn
        >
      </v-form>
    </v-card>
  </div>
</template>
<script>
import api from "Api";
import FormData from "form-data";
import * as orderService from "../../services/orders.service";
import initiateService from "../../services/initiate.service";

export default {
  data() {
    return {
      orderId: "",
      valid: false,
      files: [],
      comment: "",
      commentRule: [(v) => !!v || "Your comment is required"],
      uploadlimit: [(v) => v.length < 3 || "Maximum 3 attchament"],
    };
  },
  mounted() {
    this.orderId = this.$route.params.orderId;
  },
  methods: {
    async check() {
      try {
        if (this.files[0]) {
          var file = new FormData();

          file.append("multipartFile", this.files[0]);
          file.set("multipartFile", this.files[0]);
          file.set("userId", initiateService.loggedin.userid);

          const response = await api.post(
            process.env.VUE_APP_BASE_API + "/orders-tickets/upload",
            file,
            {
              headers: {
                Authorization: "Bearer " + initiateService.loggedin.accessToken,
                "content-type": this.files[0].type,
              },
            }
          );
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    async raiseCompaint(formname) {
      try {
        this.$refs[formname].validate();
        if (!this.valid) {
          return false;
        }
        let ticketData = {
          orderId: this.orderId,
          comment: this.comment,
          attachment1:"",
          attachment2:"",
          attachment3: "",
        };
        const response = await orderService.default.raiseTicket(ticketData);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<template>
  <div class="sidebar-panel">
    <v-layout justify-end mt-0>
      <v-btn
        class="sidebar-toggle mx-4"
        color="accent"
        dark
        @click.stop="drawer = !drawer"
      >
        Show Order Detail :<emb-currency-sign></emb-currency-sign
        >{{ getTotalPrice }}
      </v-btn>
    </v-layout>
    <v-navigation-drawer fixed width="340" right v-model="drawer" temporary>
      <div class="side-cart-head accent pa-8 text-center">
        <v-icon class="white--text mb-4"> shopping_cart </v-icon>
        <h5 class="white--text">
          You have {{ cart.length }} items in your cart
        </h5>
      </div>
      <div class="cart-content">
        <div v-if="cart == ''" class="text-xl-center">
          <v-icon size="26" class="px-2"> shopping_cart </v-icon>
          <h3>No Item Found</h3>
          <v-btn class="accent" to="/">Go Shopping</v-btn>
        </div>
        <div v-else class="cart-shop-list">
          <div
            class="cart-item p-sm-4 p-3"
            v-for="(cart, index) in cart"
            :key="index"
          >
            <v-layout row wrap align-center ma-0>
              <v-flex xs3 sm3 md3 lg3 xl3 pa-0>
                <img :src="cart.proimage" width="80" height="77" />
              </v-flex>
              <v-flex xs7 sm7 md7 lg7 xl7 cart-product-name pa-0>
                <h6 class="mb-1 word-wrap-break">{{ cart.proname }}</h6>
                <p class="mb-0">{{ cart.name }}</p>
                <p class="mb-0">
                  <emb-currency-sign></emb-currency-sign> {{ cart.price }} per
                  {{ cart.measurementUnit }} x {{ cart.quantity }}
                </p>
                <p class="mb-0 font-weight-bold">
                  <emb-currency-sign></emb-currency-sign>{{ cart.subTotal }}
                </p>
              </v-flex>
            </v-layout>
          </div>
          <div class="pt-6 px-4">
            <div class="layout align-center justify-space-between ma-0">
              <p>Subtotal</p>
              <span><emb-currency-sign></emb-currency-sign>{{ total }}</span>
            </div>
            <div class="layout align-center justify-space-between ma-0">
              <p>Tax(GST)</p>
              <span><emb-currency-sign></emb-currency-sign>{{ tax }}</span>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="layout align-center justify-space-between ma-0">
              <h4>Total</h4>
              <h4>
                <emb-currency-sign></emb-currency-sign>{{ getTotalPrice }}
              </h4>
            </div>
            <v-divider class="my-4"></v-divider>
          </div>
          <emb-delete-confirmation
            ref="deleteConfirmationDialog"
            message="Are you sure you want to delete this product?"
            @onConfirm="onDeleteProductFromCart"
          >
          </emb-delete-confirmation>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import initiateService from "../../services/initiate.service";
import userAuthService from "../../services/auth/user.service";
import { bus } from "../../main";


export default {
  data() {
    return {
      selectDeletedProduct: null,
      total: null,
      login: false,
      LoginSuccess: false,
      result1: false,
      result2: false,
      drawer: null,
      headers: [
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "image",
        },
        { text: "Product Name", value: "name" },
        { text: "Product Quantity", value: "quantity" },
        { text: "Product Total", value: "total" },
        { text: "Remove", value: "remove" },
      ],
      cart: [],
      tax: 0,
      shipping: 0,
      subTotal: 0,
    };
  },
  created() {
    bus.$on("isLoggedin", () => {
      this.LoginSuccess = userAuthService.loginsuccess;
      this.getcart();
    });
  },
  mounted() {
    this.LoginSuccess =
      initiateService.isloggedin || userAuthService.loginsuccess;

    this.getcart();
  },
  computed: {
    ...mapGetters([]),

    /**
     * method for calculatig total of  item
     */

    itemTotal() {
      let productTotal = null;
      if (this.cart.length > 0) {
        for (const item of this.cart) {
          productTotal += item.total * item.quantity;
        }
        return this.toFixed(productTotal);
      } else {
        return this.toFixed(productTotal);
      }
    },
    /**
     * method for calculatig subtotal of  item
     */
    getTotalPrice() {
      let totalPrice = +this.tax + +this.subTotal;
      return this.toFixed(totalPrice);
    },
  },
  methods: {
    toFixed(amount) {
      if (amount) {
        if (typeof amount === "string") {
          amount = +amount;
        }
        return amount.toFixed(2);
      }
      return amount;
    },
    getcart() {
      initiateService.syncmycart(
        initiateService.loggedin.userid || userAuthService.loggedin.userid
      );
      this.items = initiateService.cartitem;
      this.cart = [];

      let temp = [];
      let subTotal = 0;
      for (let item of initiateService.cartitem) {
        let unitPrice = 0;
        let measurementUnit = "";
        let productsVariationName = "";
        if (item.salePrice == "0.00") {
          unitPrice = item.unitPrice;
        } else {
          unitPrice = item.salePrice;
        }
        if (
          item.productsVariation != null &&
          item.productsVariation.name != null
        ) {
          productsVariationName = item.productsVariation.name;
          measurementUnit = item.product.measurementUnit.code;
        }

        temp = {
          proimage:
            process.env.VUE_APP_BASE_API +
            "/products-images/img/" +
            item.product.id +
            "/md",
          cartId: item.id,
          proId: item.product.id,
          proname: item.product.name,
          quantity: item.quantity * (item.product.multiplier || 1),
          multiplier: item.product.multiplier || 1,
          price: unitPrice,
          productsVariationId: item.productsVariationId,
          taxedAmount: item.taxedAmount,
          subTotal: item.subTotal,
          name: productsVariationName,
          measurementUnit: measurementUnit,
        };

        this.cart.push(temp);
        subTotal += +item.subTotal;
      }

      this.total = this.toFixed(subTotal);
      this.tax = initiateService.taxedAmount;
      this.shipping = initiateService.shippingCharges;
      this.calculate();
    },
    calculate() {
      this.tax = 0;
      this.subTotal = 0;
      for (let key in this.cart) {
        this.tax += +this.cart[key].taxedAmount;
        this.subTotal += +this.cart[key].subTotal;
      }
      let totalPrice = +this.tax + +this.subTotal;
      return this.toFixed(totalPrice);
    },

    /**
     * method for deleting product from cart
     */
    deleteProductFromCart(product) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.selectDeletedProduct = product;
    },
    /**
     * method to set cart and show notification after deletion of item
     */
    onDeleteProductFromCart() {
      this.$refs.deleteConfirmationDialog.close();
      this.$snotify.error("Product Removing from cart", {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
      });
      this.$store.dispatch(
        "onDeleteProductFromCart",
        this.selectDeletedProduct
      );
    },
  },
};
</script>
<style scoped>
.quantity-toggle {
  display: flex;
}
.quantity-toggle input {
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  width: 4.5rem;
  text-align: center;
  padding: 0 0.5rem;
}
.quantity-toggle button {
  border: 2px solid #ddd;
  padding: 0.5rem;
  background: #f5f5f5;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
}
.quantity-toggle button:disabled {
  background: #e5e5e5;
}
</style>

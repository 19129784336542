<template>
  <v-btn
    light
    class="accent lighten-2"
    large
    :href="
      'https://api.whatsapp.com/send?phone=91' +
      whatsAppOrderMobileNumber +
      '&text=Hi%2C%0AI%27m%20looking%20for%20' +
      productName +
      '%0A' +
      shareUrl
    "
    target="_blank"
    data-action="share/whatsapp/share"
    >Enquire on <v-icon light class="ml-3">fa-whatsapp</v-icon>
  </v-btn>
</template>
<script>
import AppConfig from "Constants/AppConfig";
export default {
  props: ["productName", "href"],
  data() {
    return {
      whatsAppOrderMobileNumber: AppConfig.whatsAppOrderMobileNumber,
      shareUrl: "",
    };
  },
  mounted() {
    this.shareUrl = location.href;
    this.productName = this.$props.productName;
  },
};
</script>
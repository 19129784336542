export default [
  {
    name: "Mohan Mishra ",
    position: "Businessman",
    content:
      "I have a small business of packing and selling dry fruits. I always had to move around through different bazaars just to find quality goods but this hampered my business somehow. Then I came across Trade Bridge.  I am happy with the deals I got from this platform. It provided me with fair pricing on bulk orders for my business. Good quality products and I didn’t have to go anywhere. It saved me a lot of time and profited my business in many ways. ",
  },
  {
    name: "Ramesh Periyar ",
    position: "Farmer",
    content:
      "I am a local farmer and I toil hard to make my ends meet. It was always difficult to find buyers who could pay me a good price for all my hard work. Then my friend asked me to try Trade Bridge. It was very easy for me to understand how it works. I found good deals for bulk orders here.  I don’t have to worry anymore about waiting for payments. It also provides me with doorstep pickup and delivery and this has expanded my business to a national level. ",
  },
  {
    name: "Manorama ",
    position: "Businesswoman",
    content:
      "I am the head of a small business SHG. We have a small store of agricultural products. Due to various limitations we always had to depend on the local market only, to buy these products. It never guaranteed us good quality with uneven pricing. Then I got to know about Trade Bridge. It was very easy to sign up here. Now I easily select from a wide variety of deals and products which are not only local but also from around the country. There is always fair pricing on the products. Also, the credit facility provided by this platform has helped us a lot in establishing a stable business flow. We get doorstep delivery as well.",
  },
];

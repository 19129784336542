<template>
  <div class="emb-forgetpassword-wrap section-gap">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl8>
          <v-layout align-center mx-sm-0 mx-3 justify-center row wrap>
            <v-flex sm6 md6 lg6 xl6 hidden-sm-and-down>
              <div class="forgot-password-image form-img"></div>
            </v-flex>
            <v-flex sm10 md6 lg6 xl6 class="form-margin pa-4 d-inline-block">
              <v-stepper v-model="el">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <h3 class="accent--text">Forgot Password ?</h3>
                    <h4>No Problem</h4>
                    <v-form ref="forgetPass" v-model="valid">
                      <v-text-field
                        type="text"
                        :rules="mobilenumber"
                        v-model="mob"
                        validate-on-blur
                        placeholder="Enter your registered mobile number*"
                      >
                      </v-text-field>
                      <v-btn
                        class="accent mb-4 ma-0"
                        large
                        @click.stop.prevent="saveDetails('forgetPass')"
                      >
                        Submit
                      </v-btn>
                      <p>
                        If have an account then
                        <router-link to="signin" class="accent--text"
                          >Sign In
                        </router-link>
                      </p>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <h3 class="accent--text">Forgot Password ?</h3>
                    <h4>No Problem</h4>
                    <v-form ref="otpcheck" v-model="valid">
                      <v-text-field
                        type="text"
                        v-model="otp"
                        :rule="otprule"
                        placeholder="Enter OTP recieved on Mobile*"
                      >
                      </v-text-field>
                      <p>
                        <a @click.stop.prevent="saveDetails('forgetPass')"
                          >Resend</a
                        >
                      </p>
                      <v-btn
                        class="accent mb-4 ma-0"
                        large
                        @click.stop.prevent="saveDetails('otpcheck')"
                      >
                        Submit
                      </v-btn>
                      <p>
                        If have an account then
                        <router-link to="signin" class="accent--text"
                          >Sign In
                        </router-link>
                      </p>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <h3 class="accent--text">Forgot Password ?</h3>
                    <h4>No Problem</h4>
                    <v-form ref="resetPass" v-model="valid">
                      <v-text-field
                        type="password"
                        :rules="password"
                        v-model="Password"
                        label="Password"
                        placeholder="Passowrd*"
                      >
                      </v-text-field>

                      <v-text-field
                        type="password"
                        :rules="confirmpassword"
                        v-model="cnpassword"
                        label="Confirm Password"
                        placeholder="Confirm Passowrd*"
                      >
                      </v-text-field>
                      <v-btn
                        class="accent mb-4 ma-0"
                        large
                        @click.stop.prevent="saveDetails('resetPass')"
                      >
                        Submit
                      </v-btn>
                      <p>
                        If have an account then
                        <router-link to="signin" class="accent--text"
                          >Sign In
                        </router-link>
                      </p>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import * as userAuthService from "../../services/auth/user.service";
export default {
  data() {
    return {
      valid: false,
      el: 1,
      mob: "",
      hash: "",
      otp: "",
      Password: "",
      cnpassword: "",
      mobilenumber: [
        (v) => !!v || "Mobile number is mandatory",
        (v) =>
          v.length == 10 ||
          "Please enter valid mobile number without country code",
      ],
      otprule: [(v) => !!v || "OTP is mandatory"],
      password: [
        (v) => !!v || "Password cannot be Empty",
        (v) => (v || "").length > 7 || "Minimum password length is 8",
      ],
      confirmpassword: [
        (v) => !!v || "Confirm password cannot be Empty",
        (v) => (v || "").length > 7 || "Minimum password length is 8",
        (v) => v == this.Password || "Password not Matched",
      ],
    };
  },
  methods: {
    async saveDetails(formname) {
      try {
        this.$refs[formname].validate();
        if (this.valid == true) {
          // check if mobile number is exist
          if (formname == "forgetPass") {
            const response = await userAuthService.default.forgetPassword(
              this.mob
            );
            if (response.optSent) {
              this.hash = response.hash;

              this.$snotify.success(response.message, {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 1000,
              });

              this.el += 1;
            }
          } else if (formname == "otpcheck") {
            const response = await userAuthService.default.verifyForgotPasswordOtp(
              this.otp,
              this.hash
            );
            this.$snotify.success(response.message, {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 1000,
            });
            this.el += 1;
          } else {
            const response = await userAuthService.default.resetPassword(
              this.Password,
              this.hash
            );
            this.$snotify.success(response.message, {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 1000,
            });
            this.$router.push("/signin");
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<template>
  <v-app>
    <router-view></router-view>

    <!-- force update -->
    <v-dialog
      v-model="forceUpdate"
      persistent
      content-class="force-update-dialog"
    >
      <v-card>
        <v-card-title class="headline"> New Update Available</v-card-title>
        <v-card-text
          >Please refresh the browser to get latest updates. Your current
          version is {{ currentVersion }} whereas the latest available version
          is {{ availableVersion }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="refresh"> Update Now </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loginRequest"
      content-class="register-dialog"
      elevation="24"
    >
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="6">
            <v-img
              height="300"
              width="300%"
              src="/static/images/login-popup.jpg"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="text-center">
            <h5 class="mt-6">SIGN UP FOR FREE</h5>
            <h7>Get Rs.200 reward</h7>

            <h6 class="mt-6">Use referral code <strong>'WELCOME'</strong></h6>
            <v-row gutter="1" class="mt-3 ps-2">
              <v-col cols="6">
                <v-btn class="ma-2" color="success" block @click="goToRegister">
                  Join Now
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  class="ma-2"
                  color="grey"
                  block
                  @click="loginRequest = false"
                >
                  Maybe later
                </v-btn>
              </v-col>
            </v-row>
            <p class="font-weight-light">
              (attractive discount on first purchase)
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- force update -->
    <v-dialog v-model="update" content-class="force-update-dialog">
      <v-card>
        <v-card-title class="headline"> New Update Available</v-card-title>
        <v-card-text>
          Please refresh the browser to get latest updates. Your current version
          is
          {{ currentVersion }} whereas the latest available version is
          {{ availableVersion }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="closeUpdateModel"> Cancel </v-btn>
          <v-btn color="green" @click="refresh"> Update Now </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- avail credit dialog -->
    <v-dialog v-model="creditPopup" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 text-center">
          Get instant Credit
        </v-card-title>
        <v-card-text>
          <v-img
            height="250"
            src="/static/images/credit-avail-popup.png"
          ></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-3 white--text"
            @click="creditPopup = false"
          >
            Later
          </v-btn>
          <v-btn color="green darken-1 white--text" @click="availCreditNow">
            Avail now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AppConfig from "Constants/AppConfig";
import initiateService from "./services/initiate.service";
export default {
  computed: {
    ...mapGetters(["rtlLayout"]),
    mobileMenu: {
      get() {
        return this.$store.getters.sidebarOpen;
      },
      set(val) {
        this.$store.dispatch("toggleSidebar", val);
      },
    },
  },
  data() {
    return {
      forceUpdate: false,
      update: false,
      loginRequest: false,
      availableVersion: null,
      currentVersion: null,
      selfCheckTimer: null,
      creditPopup: false,
    };
  },
  /**
   * Method To set the Rtl While page is opened
   */
  mounted() {
    this.checkuserloggedin();
  },
  methods: {
    async checkuserloggedin() {
      initiateService.init();
      const settings = await initiateService.fetchSetting();
      if (!initiateService.isloggedin) {
        // this.loginRequest = true; // pop up display
      } else {
        // check if pop up needs to be shown
        if (!settings[0].showEpayPopup) {
          // check if user is not availed it
          if (+initiateService.loggedin.creditAvailableAmount === 0) {
            this.creditPopup = true;
          } else {
            this.creditPopup = false;
          }
        }
      }
      this.availableVersion = settings[0].webVersion;
      this.currentVersion = AppConfig.webVersion;
      if (AppConfig.webVersion != settings[0].webVersion) {
        if (settings[0].webForceUpdate) {
          this.forceUpdate = true;
        } else {
          this.update = true;
        }
      }
      // clear existing timer if any
      if (this.selfCheckTimer) {
        clearTimeout(this.selfCheckTimer);
        this.selfCheckTimer = null;
      }
      this.selfCheckTimer = setTimeout(() => {
        this.checkuserloggedin();
      }, 1000 * 60 * 5); // 5 mins
    },
    closeUpdateModel() {
      this.update = false;
    },
    refresh() {
      window.location.reload();
    },
    goToRegister() {
      this.loginRequest = false;
      this.$router.push("/signup?referralCode=WELCOME");
    },
    availCreditNow() {
      this.creditPopup = false;
      this.$router.push("/account/credit");
    },
  },
};
</script>
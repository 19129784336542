<template>
  <div class="emb-payment-wrap">
    <!-- Contact Details -->
    <div class="emb-card mb-12 pa-3 white">
      <v-layout row wrap align-center ma-0>
        <v-flex lg12 class="d-flex justify-space-between align-center">
          <h5 class="m-0">Bid Details</h5>
        </v-flex>
        <v-flex md6 sm12>
          <v-simple-table>
            <tbody>
              <tr>
                <th>Bid Rate</th>
                <td>
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(bidData.bidPrice) }}
                </td>
              </tr>
              <tr>
                <th>Bid Quantity</th>
                <td>
                  {{ bidData.bidQuantity }}
                </td>
              </tr>
              <tr>
                <th>Bid Total Amount</th>
                <td>
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(bidData.totalBidPrice) }}
                </td>
              </tr>
              <tr v-if="this.bidData.createdTimestamp">
                <th>Bid Placed on</th>
                <td>{{ bidData.createdTimestamp }}</td>
              </tr>
              <tr>
                <th>Bid Status</th>
                <td>
                  <v-chip :color="statusColorMap[bidData.status]" small>{{
                    this.bidData.status
                  }}</v-chip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex>

        <v-flex md6 sm12>
          <v-simple-table>
            <tbody>
              <tr v-if="+bidData.quotedPrice">
                <th>TradeBridge Proposed</th>
                <td>
                  <emb-currency-sign></emb-currency-sign>
                  {{ bidData.quotedPrice }}
                </td>
              </tr>
              <tr v-if="+bidData.quotedTotalAmount">
                <th>Proposed Total Amount</th>
                <td>
                  <emb-currency-sign></emb-currency-sign>
                  {{ formatNumber(bidData.quotedTotalAmount) }}
                </td>
              </tr>

              <tr v-if="this.bidData.quotedTimestamp">
                <th>Quoted on</th>
                <td>{{ bidData.quotedTimestamp }}</td>
              </tr>

              <tr
                v-if="
                  this.bidData.statusChangedTimestamp &&
                  ['Pending', 'Quoted'].indexOf(bidData.status) === -1
                "
              >
                <th>Status Changed on</th>
                <td>{{ bidData.statusChangedTimestamp }}</td>
              </tr>

              <tr v-if="this.bidData.orderId">
                <th>Order</th>
                <td>
                  <router-link
                    :to="{
                      path: 'order-detail',
                      query: { id: bidData.orderId },
                    }"
                    >view order
                  </router-link>
                </td>
              </tr>
              <tr v-else-if="bidData.status === 'Agreed'">
                <th></th>
                <td>
                  <router-link to="/cart">
                    <v-btn class="ma-2" color="success">Go to Cart</v-btn>
                  </router-link>
                </td>
              </tr>

              <tr v-if="this.bidData.status === 'Quoted'">
                <th>Action</th>
                <td>
                  <v-btn
                    class="ma-2"
                    color="error"
                    @click="updateStatus('Rejected')"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Reject
                  </v-btn>

                  <v-btn
                    class="ma-2"
                    color="success"
                    @click="updateStatus('Agreed')"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Agree
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </div>

    <!-- cart Details -->
    <div class="emb-card mb-12 pa-3 white">
      <v-layout row wrap align-center ma-0>
        <v-flex lg6>
          <v-card>
            <v-layout row align-center ma-0 mb-3>
              <v-flex lg4>
                <router-link
                  :to="
                    '/product/product-detail/' +
                    bidData.product.id +
                    '/' +
                    slugify(bidData.product.name)
                  "
                >
                  <img
                    v-if="bidData && bidData.product"
                    :src="
                      'https://api.ashapuracommodities.com/products-images/img/' +
                      bidData.product.id +
                      '/md'
                    "
                    width="80"
                    height="77"
                  />
                </router-link>
              </v-flex>
              <v-flex lg8 cart-product-name pa-0>
                <h6 class="mb-1 word-wrap-break">
                  <router-link
                    :to="
                      '/product/product-detail/' +
                      bidData.product.id +
                      '/' +
                      slugify(bidData.product.name)
                    "
                  >
                    {{ bidData.product.name }}
                  </router-link>
                </h6>
                <p class="mb-0 text-small text-italic">
                  {{ bidData.productsVariation.name }}
                </p>
                <p class="mb-0">
                  Current Price: &nbsp;
                  <emb-currency-sign></emb-currency-sign>
                  {{
                    +bidData.productsVariation.salePrice ||
                    bidData.productsVariation.unitPrice
                  }}
                </p>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
import api from "Api";

import moment from "moment";

import initiateService from "../../services/initiate.service";
import userAuthService from "../../services/auth/user.service";

export default {
  data() {
    return {
      bidData: {},
      bidId: "",
      loading: false,
      statusColorMap: {
        Pending: "blue-grey",
        Quoted: "amber",
        Rejected: "error",
        Expired: "error",
        Agreed: "light-green",
        Ordered: "success",
      },
    };
  },
  mounted() {
    this.bidId = this.$route.query.id;
    this.fetchdetails(this.bidId);
  },
  methods: {
    async fetchdetails(bidId) {
      try {
        if (bidId) {
          const response = await api.get(
            process.env.VUE_APP_BASE_API + "/bids/" + bidId,
            {}
          );
          // check if bid belongs to user
          if (
            response.data &&
            response.data.userId !==
              (initiateService.loggedin.userid ||
                userAuthService.loggedin.userid)
          ) {
            this.$router.go(-1);
            return false;
          }
          this.bidData = response.data;
          if (this.bidData.createdTimestamp) {
            this.bidData.createdTimestamp = moment(
              this.bidData.createdTimestamp
            )
              .utc(true)
              .local()
              .format("LLL");
          }
          if (this.bidData.updatedTimestamp) {
            this.bidData.updatedTimestamp = moment(
              this.bidData.updatedTimestamp
            )
              .utc(true)
              .local()
              .format("LLL");
          }
          if (this.bidData.statusChangedTimestamp) {
            this.bidData.statusChangedTimestamp = moment(
              this.bidData.statusChangedTimestamp
            )
              .utc(true)
              .local()
              .format("LLL");
          }
          if (this.bidData.quotedTimestamp) {
            this.bidData.quotedTimestamp = moment(
              this.bidData.quotedTimestamp
            ).format("LLL");
          }
          if (+this.bidData.quotedPrice) {
            this.bidData.quotedTotalAmount = (
              +this.bidData.quotedPrice * +this.bidData.bidQuantity
            ).toFixed(2);
          }
        }
      } catch (error) {
        this.cartquntity = 0;
      } finally {
        // bus.$emit('cartQuantityUpdated');
      }
    },
    async updateStatus(statusToBeUpdated) {
      try {
        let rejectReason = "";
        // give confirmation pop
        if (
          !confirm(
            "Are you sure?, you want to " +
              (statusToBeUpdated === "Rejected" ? "reject" : "accept") +
              " this bid?"
          )
        ) {
          return;
        }
        // if reject, then as for reason
        if (statusToBeUpdated === "Rejected") {
          rejectReason = prompt("Enter reason for rejection");
        }
        this.loading = true;
        const response = await api.patch(
          process.env.VUE_APP_BASE_API + "/bids/" + this.bidId,
          {
            status: statusToBeUpdated,
            rejectReason,
          }
        );
        if (response.data) {
          // refetch
          await this.fetchdetails(this.bidId);
          if (this.bidData.status === "Agreed") {
            this.$snotify.success("Product added to the cart", {
              closeOnClick: true,
              pauseOnHover: true,
              timeout: 3000,
              showProgressBar: true,
            });
            // update cart count
            bus.$emit("productadded");
          }
        }
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },

    formatNumber(num) {
      num = num + "" || "";
      // works for integer and floating as well
      var numberAndDecimal = num.split(".");
      var decimals = numberAndDecimal[1] || null;
      numberAndDecimal = numberAndDecimal[0].replace(
        /(\d)(?=(\d\d)+\d$)/g,
        "$1,"
      );
      num = decimals ? numberAndDecimal + "." + decimals : numberAndDecimal;

      return num;
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-btn
      class="search-icon-right-border d-inline-block"
      fab
      small
      dark
      v-if="isHidden"
      elevation="0"
      color="white"
      @click="isHidden = false"
    >
      <img :src="searchIcon" class="img-fluid" style="width: 20px" />
    </v-btn>
    <div class="search-form " v-if="!isHidden">
      <v-form @submit="searchStore()">
        <div class="form pa-3">
          <v-text-field
            type="text"
            placeholder="Search Here"
            validate-on-blur
            v-model="searchterm"
            outlined
            hide-details="true"
            error="false"
          >
          </v-text-field>
          <v-btn class="close-btn white" @click="isHidden = !isHidden">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
export default {
  data() {
    return {
      searchIcon: AppConfig.searchIcon,
      searchterm: null,
      isHidden: true,
    };
  },
  mounted() {
    this.isHidden = true;
  },
  methods: {
    async searchStore() {
      if (this.searchterm) {
        this.isHidden = !this.isHidden;
        this.$router.push({
          name: "SearchResultPage",
          params: { keyword: this.searchterm },
        });
      }
    },
  },
};
</script>

/**
 * App Entry File
 * Copyright 2018. All Rights Reserved
 */
/* eslint-disable */
import 'babel-polyfill'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import Nprogress from 'nprogress'
import VueI18n from 'vue-i18n'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import VueMoment from 'vue-moment'
import App from './App.vue'
import { store } from './store/store'
import AppConfig from 'Constants/AppConfig'

// localisation messages
import messages from './lang'

// global components
import GlobalComponents from './globalComponents'

//router 
import router from './router'

// all css files included
import './lib/EmbryoCss'
// Google Analytics
import VueGtag from "vue-gtag";
import VueRouter from 'vue-router'
import VueLazyload from 'vue-lazyload'

// Alliging Position for the toaster
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    }
}

//plugins
Vue.use(VueMoment)
Vue.use(VueI18n)
Vue.use(Snotify, options)
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDbmIxt0zQYOWIyLFaEf78xFT2qthPx6GA",
        libraries: "places"
    }
});
Vue.use(VueLazyload)
Vue.use(VueGtag, {
    config: {
        id: "G-Y4N3E7F65D",
        params: {
            send_page_view: false
        }
    }
});
Vue.use(GlobalComponents)

export const bus = new Vue();

// router navigation guards
router.beforeEach((to, from, next) => {
    Nprogress.start();
    bus.$emit('checkcart');
    bus.$emit('isLoggedin');
    next();


})

router.afterEach(() => {

    if (router.currentRoute.meta.title) {
        document.title = router.currentRoute.meta.title + ' - ' + AppConfig.pageTitle;
    } else {
        document.title = AppConfig.pageTitle;
    }


    Nprogress.done();
    setTimeout(() => {
        const contentWrapper = document.querySelector("html");
        if (contentWrapper !== null) {
            contentWrapper.scrollTop = 0;
        }
    }, 200);
})

// creating a instance of vue localisation module
const i18n = new VueI18n({
    locale: store.getters.selectedLocale.locale,
    messages,
})

var filter = function(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

Vue.config.productionTip = false

new Vue({
    store,
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')
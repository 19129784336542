<template>
  <div class="emb-accesories-wrap">
    <div>
      <emb-page-title
        :heading="'Search result for - '+ keyWord"
        class="category-page-title"
      ></emb-page-title>
      <v-container class="py-5">
          <v-row justify="center" align="center">
              <v-col cols="4" >
                  <v-text-field
                        label="Search here"
                        type="text"
                        placeholder="Search Here"
                        validate-on-blur
                        v-model="keyWord"
                        :rule="basictextRules"
                        @keypress="getSearchResults(keyWord)"
                        class="border">
                    </v-text-field>                                   
              </v-col>
              <v-col cols="2">
                <v-btn class="search-btn " @click="getSearchResults(this.keyWord)" >
                    <img :src="searchIcon" class="img-fluid" style="width: 20px" />
                </v-btn>
              </v-col>
          </v-row>
        <v-row>
            <v-col cols="12" 
            v-show="!resultsAvailable">
                <div class="sec-title">
                    <h2>No Products Found</h2>
                </div>
            </v-col>
          <v-col
            cols="12"
            v-show="resultsAvailable"
            sm="6"
            md="3"
            v-for="(product, key) in productData2"
            :key="key"
          >
            <product-item :data="product"></product-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ProductItem from "Components/Ecommerce/ProductItem";
import api from "Api";

import AppConfig from "Constants/AppConfig";

import initiateService from "../services/initiate.service";
import userService from "../services/auth/user.service";

export default {
  components: {
    ProductItem,
  },
  data() {
    return {
      listview: false,
      keyWord:this.$route.params.keyword || '',
      categoriesData: {},
      productData2: [],
      allcategories: {},
      categories: true,
      resultsAvailable:false,
      searchIcon:AppConfig.searchIcon,
      basictextRules: [(v) => !!v || "This field should not be empty"],
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  methods: {
    async getSearchResults(keyWord) {
      try {
        if(keyWord){
        const response = await api.post(process.env.VUE_APP_BASE_API + "/products/list",{keyword: keyWord});
         if(response.data.total){
             this.resultsAvailable=!this.resultsAvailable
            this.productData2 = response.data.product;
            }else{
                this.resultsAvailable=!this.resultsAvailable
            }

        return true;
        }
      } catch (error) {
        return error;
      }
    },
    viewchange(val) {
      this.listview = val;
    },
  },
  getMasked(price = null) {
    if (!(initiateService.isloggedin || userService.loginsuccess)) {
      // check if loggedin
      // Dhiraj
      // split
      const priceSplit = price.split(".");
      // get first digit
      const firstDigit = priceSplit[0].charAt(0);
      let maskedPrice = firstDigit;
      // remove first digit
      priceSplit[0] = priceSplit[0].slice(1);
      if (priceSplit[0].length) {
        maskedPrice += "X".repeat(priceSplit[0].length);
      }
      if (priceSplit[1].length) {
        maskedPrice += "." + "X".repeat(priceSplit[1].length);
      }
      return maskedPrice;
    }
    return price;
  },
  created: function () {
      this.getSearchResults(this.$route.params.keyword);
  },
};
</script>
import UserPanel from "Container/UserPanel";

const HomeV1 = () => import("Views/HomeV1");
const SearchResults = () => import("Views/SearchResults");
const Category = () => import("Views/Category");
const Impact = () => import("Views/Impact");
const Payment = () => import("Views/Payment/Payment");
const PaymentSummary = () => import("Views/Payment/PaymentSummary");
const Cart = () => import("Views/Cart");
const Checkout = () => import("Views/Checkout");
const FinalReceipt = () => import("Views/FinalReceipt");
const ProductDetail = () => import("Views/ProductDetail");
const AboutUs = () => import("Views/AboutUs");
const BecomeSupplier = () => import("Views/BecomeSupplier");
const Blogs = () => import("Views/Blogs");
const TermCondiition = () => import("Views/TermsAndCondition");
const Faq = () => import("Views/Faq");
const Account = () => import("Views/UserAccount/Account.vue");
const OrderHistory = () => import("Views/UserAccount/OrderHistory.vue");

const OrderDetail = () => import("Views/UserAccount/OrderDetail.vue");

const BidHistory = () => import("Views/UserAccount/BidHistory.vue");
const Credit = () => import("Views/UserAccount/Credit.vue");
const Wishlist = () => import("Views/UserAccount/Wishlist.vue");

const BidDetail = () => import("Views/UserAccount/BidDetail.vue");

const TicketRaised = () => import("Views/UserAccount/TicketRaised.vue");

const ViewTicketRaised = () => import("Views/UserAccount/ViewTicketRaised.vue");

const Profile = () => import("Views/UserAccount/Profile.vue");
const Address = () => import("Views/UserAccount/Address.vue");
const Cards = () => import("Views/UserAccount/Cards.vue");
const EditProfile = () => import("Views/UserAccount/EditProfile.vue");
const EditAddress = () => import("Views/UserAccount/EditAddress.vue");
const EditProfileInfo = () => import("Views/UserAccount/EditProfileInfo.vue");
const BlogDetail = () => import("Views/Blogs/BlogDetails");
const ContactUs = () => import("Views/Contact");
const PrivacyPolicy = () => import("Views/PrivacyPolicy");
const Register = () => import("Views/Sessions/Register");
const ForgotPassword = () => import("Views/Sessions/ForgotPassword");
const ThankYou = () => import("Views/Sessions/ThankYou");
const SignIn = () => import("Views/Sessions/SignIn");

export default {
 path: "/",
 component: UserPanel,
 redirect: "/",
 children: [
  {
   path: "/",
   component: HomeV1,
   meta: {
    header: 1,
   },
  },
  {
   path: "/search",
   name: "SearchResultPage",
   component: SearchResults,
   meta: {
    title: "Search Results",
   },
  },
  {
   path: "/category/",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/webcategories";
   },
   component: Category,
   name: "Category",
  },
  {
   path: "/category/:id/:name?",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/buyer-categories/list";
   },
   component: Category,
   name: "Category",
  },
  {
   path: "/checkout/payment",
   component: Payment,
   meta: {
    title: "Checkout",
   },
  },
  {
   path: "/checkout/payment/summary",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: PaymentSummary,
   meta: {
    title: "Checkout Summary",
   },
  },
  {
   path: "/cart",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: Cart,
   meta: {
    title: "Cart",
   },
  },
  {
   path: "/checkout",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: Checkout,
   meta: {
    title: "Checkout",
   },
  },
  {
   path: "/product/product-detail/:id/*",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: ProductDetail,
   name: "productDetail",
  },
  {
   path: "/checkout/final-receipt",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: FinalReceipt,
   name: "FinalReceipt",
  },
  {
   path: "/about",
   component: AboutUs,
   name: "about",
   meta: {
    title: "About Us",
   },
  },
  {
   path: "/impact",
   component: Impact,
   name: "Impact",
   meta: {
    title: "Impact",
   },
  },
  {
   path: "/become-supplier",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: BecomeSupplier,
   name: "Become a Supplier",
   meta: {
    title: "Become a Supplier",
   },
  },
  {
   path: "/term-condition",
   component: TermCondiition,
   name: "term-condition",
   meta: {
    title: "Terms and Conditions",
   },
  },
  {
   path: "/faq",
   component: Faq,
   name: "Faq",
   meta: {
    title: "Frequently Asked Questions",
   },
  },
  {
   path: "/account",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/";
   },
   component: Account,
   name: "Account",
   meta: {
    title: "Account",
   },
   children: [
    {
     path: "/account/order-history",
     name: "OrderHistory",
     component: OrderHistory,
     meta: {
      title: "My Order History",
     },
    },
    {
     path: "/account/order-detail",
     name: "OrderDetail",
     component: OrderDetail,
     meta: {
      title: "My Order Detail",
     },
    },
    {
     path: "/account/bid-history",
     name: "BidHistory",
     component: BidHistory,
     meta: {
      title: "My Bid Details",
     },
    },
    {
     path: "/account/credit",
     name: "credit",
     component: Credit,
     meta: {
      title: "Credit",
     },
    },
    {
     path: "/account/wishlist",
     name: "Wishlist",
     component: Wishlist,
     meta: {
      title: "My Wishlist",
     },
    },
    {
     path: "/account/bid-detail",
     name: "BidDetail",
     component: BidDetail,
     meta: {
      title: "My Bid Details",
     },
    },
    {
     path: "/account/raise-ticket/:orderId",
     name: "TicketRaised",
     component: TicketRaised,
     meta: {
      title: "My Support Ticket",
     },
    },

    {
     path: "/account/view-ticket/:orderId",
     name: "ViewTicketRaised",
     component: ViewTicketRaised,
     meta: {
      title: "My Support Ticket Details",
     },
    },

    {
     path: "/account/profile",
     name: "Profile",
     component: Profile,
     meta: {
      title: "My Profile",
     },
    },
    {
     path: "/account/address",
     name: "Address",
     component: Address,
     meta: {
      title: "My Address",
     },
    },
    {
     path: "/account/cards",
     name: "Cards",
     component: Cards,
    },
    {
     path: "/account/profile/edit",
     name: "EditProfile",
     component: EditProfile,
     meta: {
      title: "Edit My Profile",
     },
    },
    {
     path: "/account/profile/edit",
     name: "EditAddress",
     component: EditAddress,
     meta: {
      title: "Edit My Address",
     },
    },
    {
     path: "/account/profile/edit",
     name: "EditProfileInfo",
     component: EditProfileInfo,
     meta: {
      title: "Edit My Profile",
     },
    },
   ],
  },
  {
   path: "/blog-detail/:id",
   component: BlogDetail,
   name: "BlogDetail",
  },
  {
   path: "/contact",
   component: ContactUs,
   name: "ContactUs",
   meta: {
    title: "Contact Us",
   },
  },

  {
   path: "/privacy-policy",
   component: PrivacyPolicy,
   name: "PrivacyPolicy",
   meta: {
    title: "Privacy Policy",
   },
  },
  {
   path: "/signup",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/create-account";
   },
   component: Register,
   name: "Register",
   meta: {
    title: "Register",
   },
  },
  {
   path: "/forgot-password",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/recover-password";
   },
   component: ForgotPassword,
   name: "ForgotPassword",
   meta: {
    title: "Forget Password",
   },
  },
  {
   path: "/thank-you",
   component: ThankYou,
   name: "ThankYou",
   meta: {
    title: "Thank You",
   },
  },
  {
   path: "/signin",
   beforeEnter() {
    location.href = "https://shop.tradebridge.co.in/login";
   },
   component: SignIn,
   name: "SignIn",
   meta: {
    title: "Login",
   },
  },
  {
   path: "/blog",
   component: Blogs,
   name: "Blog",
   meta: {
    title: "Blog",
   },
  },
 ],
};

<template>
  <div class="sidebar-filter-wrap">
    <div class="cateogary-block emb-card">
      <h5 class="accent white--text pa-2 d-flex justify-space-between"  >Explore
          <v-icon color="white" slot="end" v-show="mobileVersion" v-on:click="toggleFilters">mdi-filter</v-icon>
      </h5>
      <div class="white mb-6 pa-6" v-show="!mobileVersion">
        <a
          :href="'/category/' + cat.id+'/'+slugify(cat.name)"
          v-for="(cat, key) in this.allcategories"
          :key="key"
        >
          <h5 class="mb-2">{{ cat.name }}</h5>
        </a>
      </div>

      <!-- <ais-refinement-list attribute="category" :limit="5"></ais-refinement-list> -->
    </div>

    <div class="blogs-block emb-card" v-if="false">
      <h5 class="accent white--text pa-2">Recent Blogs</h5>
      <div class="white mb-6 pa-6">s</div>
      <!-- <ais-refinement-list attribute="category" :limit="5"></ais-refinement-list> -->
    </div>
  </div>
</template>
<script>
import api from "Api";

export default {
  props: ["data"],
  data() {
    return {
      allcategories: {},
      mobileVersion:false,
    };
  },
  mounted() {
    this.listcategories();
     if(window.innerWidth<=768){
          this.mobileVersion = !this.mobileVersion

      }
  },
  methods: {
    async listcategories() {
      try {
        const response = await api.get(process.env.VUE_APP_BASE_API + "/categories/");
        this.allcategories = response.data.data;
        return true;
      } catch (error) {
        return error;
      }
    },
    toggleFilters(){
        this.mobileVersion = !this.mobileVersion
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    }
  },
};
</script>
<template>
  <div class="d-inline-block social-icons">
    <v-btn
      light
      icon
      class="primary"
      :href="'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl"
      target="_blank"
    >
      <v-icon light>fa-facebook</v-icon>
    </v-btn>
    <v-btn
      light
      icon
      class="primary"
      :href="'http://twitter.com/share?url=' + shareUrl"
      target="_blank"
    >
      <v-icon light>fa-twitter</v-icon>
    </v-btn>
    <v-btn
      light
      icon
      class="primary"
      :href="'https://www.linkedin.com/shareArticle?url=' + shareUrl"
      target="_blank"
    >
      <v-icon light>fa-linkedin</v-icon>
    </v-btn>
    <v-btn
      light
      icon
      class="primary"
      :href="'mailto:abc@example.com?subject=Tradebridge&body=' + shareUrl"
      target="_blank"
    >
      <v-icon light> fa-envelope</v-icon>
    </v-btn>
    <v-btn
      light
      icon
      class="primary"
      :href="
        'https://api.whatsapp.com/send?text=Hi%2C%0AI%27m%20looking%20for%20' +
        productName +
        '%0A' +
        shareUrl
      "
      target="_blank"
      data-action="share/whatsapp/share"
    >
      <v-icon light>fa-whatsapp</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ["productName", "href"],
  data() {
    return {
      shareUrl: "",
    };
  },
  mounted() {
    this.shareUrl = location.href;
    this.productName = this.$props.productName;
  },
};
</script>